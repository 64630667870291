import { authenticationConstants } from '../constants';
import { authenticationService } from '../services';
import { alertActions } from '.';

export const authenticationActions = {
  login,
  logout,
  getAll,
};

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }));

    return authenticationService.login(username, password)
      .then(
        (user) => {
          dispatch(success(user));
          return user;
        },
      )
      .catch((error) => {
        dispatch(failure(error.data));
      });
  };

  function request(user) {
    return { type: authenticationConstants.LOGIN_REQUEST, user };
  }

  function success(user) {
    return { type: authenticationConstants.LOGIN_SUCCESS, user };
  }

  function failure(error) {
    return { type: authenticationConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  authenticationService.logout();
  return { type: authenticationConstants.LOGOUT };
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    authenticationService.getAll()
      .then(
        (users) => dispatch(success(users)),
        (error) => dispatch(failure(error)),
      );
  };

  function request() {
    return { type: authenticationConstants.GETALL_REQUEST };
  }

  function success(users) {
    return { type: authenticationConstants.GETALL_SUCCESS, users };
  }

  function failure(error) {
    return { type: authenticationConstants.GETALL_FAILURE, error };
  }
}
