/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Form,
  Icon,
  Layout,
  Row,
  Spin,
  Upload,
  Avatar,
  Switch,
  Card,
  notification,
  Typography,
  Radio,
  Input,
} from 'antd';
import { institutionService } from '../../services/institution.service';
import Api from '../../helpers/api';

const FormItem = Form.Item;
const { Content } = Layout;
const { TextArea } = Input;

const styles = {
  spaceBetweenRadioOptions: {
    marginTop: 8,
    marginLeft: 11,
    fontWeight: 400,

  },
};

class Institution extends Component {
    // eslint-disable-next-line react/state-in-constructor
    state = {
      logo64: null,
      institution: {},
      loading: false,
      institution_logo: [],
      value: 0,
      pdfHistoric: false,
      loadingPdfSettings: true,
      pdfGroupHistoric: false,
      loadingGroupPdfSettings: true,
      activatePre: true,
      focusedTextArea: false,
      blockAgendaCreation: false,
      isSettingExisting: false,
    };

    componentDidMount() {
      const selectedInstitution = JSON.parse(localStorage.getItem('selected-institution'));
      this.setState({ institution: selectedInstitution });

      Api.get(`/institution/${selectedInstitution.id}/settings/pdf`)
        .then((data) => {
          this.setState({
            value: data.drugsPrintSettings,
            pdfHistoric: data.pdfPrintSettings,
            loadingPdfSettings: false,
            pdfGroupHistoric: data.anaesthesiaGroupPrintSettings,
            loadingGroupPdfSettings: false,
            activatePre: data.activatePre,
          });
        })
        .catch(() => {
          notification.error({
            message: 'Erro ao carregar dados, verifique sua conexão com a internet',
          });
        });
        
        Api.get(`/institutions/settings/38`)
        .then((response) => {
          if (response && typeof response.value !== 'undefined') {
            const settingActive = response.value === '1'; 
            this.setState({
              blockAgendaCreation: settingActive,
              isSettingExisting: true  
            });
          } else {
            console.error("Formato inesperado da resposta da API:", response);
          }
        })
        .catch((error) => {
          if (error.status === 404) {
            this.setState({ isSettingExisting: false });
          } else {
            console.error("Erro na requisição ou no processamento dos dados:", error);
            notification.error({
              message: 'Erro ao carregar dados de configuração de agendamentos',
            });
          }
        });
    }
    
        
    onChange = (e) => {
      this.setState({
        value: e.target.value,
      });
    };

    toBase64 = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

    viewLogo = async (file) => {
      const result = await this.toBase64(file).catch((e) => Error(e));
      if (result instanceof Error) {
        console.error('Error: ', result.message);
        return;
      }

      this.setState({ logo64: result });
    }

    sendLogoToAPI = (uuid, file) => {
      if (this.state.institution_logo.length === 0) { // não houve alteração de imagem
        window.location.reload(false);
        this.setState({ loading: false });
        notification.success({
          message: 'Dados da instituição alterados com sucesso',
        });
        return;
      }

      institutionService.saveInstitutionLogo(uuid, file)
        .then(() => {
          this.props.history.push('/');
          this.setState({ loading: false });
          notification.success({
            message: 'Dados da instituição alterados com sucesso',
          });
        }).catch(() => {
          notification.error({
            message: 'Erro ao atualizar o logotipo da instituição',
          });
          this.setState({ loading: false });
        });
    }

    putPdfSettings = () => Api.post(`/institution/${this.state.institution.id}/settings/pdf`, {
      pdfPrintSettings: this.state.pdfHistoric,
      drugsPrintSettings: this.state.value,
      anaesthesiaGroupPrintSettings: this.state.pdfGroupHistoric,
      activatePre: this.state.activatePre,
      platform: 'maneger',
    })

    checkAndSaveSettings = async () => {
      if (this.state.isSettingExisting) {
        await this.updateScheduleSettings();
      } else {
        await this.createScheduleSettings();
      }
    };
    
    
    createScheduleSettings = () => Api.post(`/institutions/settings`, {
        institution_id: this.state.institution.id,
        institution_setting_id: 38,
        value: this.state.blockAgendaCreation ? "1" : "0",
        platform: 'maneger',
      })
    
    updateScheduleSettings = () =>  Api.put(`/institutions/settings/38`, {
        institution_id: this.state.institution.id,
        value: this.state.blockAgendaCreation ? "1" : "0", 
        platform: 'maneger',
      })
    
    
    handleSwitchChange = (checked) => {
      this.setState({ blockAgendaCreation: checked });
    };
    
    
    handleSubmit = async (uuid, file) => {
      this.setState({ loading: true });
      try {
        await this.putPdfSettings();
        await this.checkAndSaveSettings();
        this.sendLogoToAPI(uuid, file);
      } catch (error) {
        notification.error({
          message: 'Erro ao atualizar configurações',
        });
        this.setState({ loading: false });
      }
    };
    

    handleChange(e) {
      e.preventDefault();
    }

    checkValidateFormat = (values) => {
      if (values) {
        const { size, type } = values;
        const typePermissions = ['png', 'jpg', 'jpeg'];
        const sizePermission = 120000; // 120kb
        if (size > sizePermission || !typePermissions.includes(type.split('/')[1])) {
          notification.error({
            message: 'O arquivo selecionado excede o tamanho máximo ou formato permitido.',
          });
          this.setState({
            institution_logo: [],
          });
          return false;
        }
      }
      return true;
    }

    render() {
      const {
        value, pdfHistoric, loadingPdfSettings, pdfGroupHistoric, loadingGroupPdfSettings, activatePre,
        blockAgendaCreation, 
      } = this.state;
      if (this.state.loading) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin spinning={this.loading} />
          </div>
        );
      }
      return (
        <Content>
          <Form>
            <Row>
              <Col xs={24} sm={0}>
                <Button
                  type="primary"
                  className="add-procedure"
                  shape="circle"
                  size="large"
                  htmlType="submit"
                >
                  <Icon type="check" fill="#ffffff" />
                </Button>
              </Col>
              <Col xs={24} sm={20}>
                <h1 className="title">
                  Configurações da instituição
                </h1>
              </Col>
              <Col xs={0} sm={4}>
                <Button
                  type="primary"
                  className="add-procedure"
                  htmlType="submit"
                  loading={this.loading}
                  onClick={() => this.handleSubmit(
                    this.state.institution.uuid,
                    this.state.institution_logo[0],
                  )}
                >
                  SALVAR
                </Button>
              </Col>
            </Row>
            <Card
              title="Logomarca da instituição"
              headStyle={{ backgroundColor: '#f2f2f2', paddingLeft: 16 }}
              size="default"
              bodyStyle={{ paddingLeft: 30, paddingBottom: 0 }}
            >
              <Row gutter={28}>

                <Form.Item name="logo" style={{ marginBottom: 0 }}>
                  <FormItem label="Logo">
                    <Avatar
                      shape="square"
                      alt="logo"
                      src={this.state.logo64
                          || this.state.institution.logo_url
                          || null}
                      className="institution-logo-intranet"
                    >
                      Sem logo
                    </Avatar>
                    <div style={{ margin: 8 }} />
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Upload
                          onRemove={() => this.setState({
                            institution_logo: [],
                            logo64: null,
                          })}
                          beforeUpload={(file) => {
                            this.setState({
                              institution_logo: [file],
                            });
                            this.viewLogo(file);
                            this.checkValidateFormat(file);
                            return false;
                          }}
                          fileList={this.state.institution_logo}
                        >
                          <Button>
                            <Icon type="upload" />
                            {' '}
                            Selecionar arquivo
                          </Button>
                          <div className="ant-upload-list-item-card-actions" />
                        </Upload>
                      </Col>
                    </Row>
                    <span>
                      O tamanho máximo permitido para o arquivo é de 120KB e apenas nos formatos PNG, JPG e JPEG
                    </span>

                  </FormItem>

                </Form.Item>
              </Row>
            </Card>
            <div style={{ margin: 20 }} />
            <Card
              title="Impressão da ficha anestésica"
              headStyle={{ backgroundColor: '#f2f2f2', paddingLeft: 16 }}
              size="default"
              bodyStyle={{ paddingTop: 5 }}
            >
              <Row gutter={28}>

                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                  <div style={{ margin: 5 }} />
                  {loadingPdfSettings || loadingGroupPdfSettings ? (

                    <Spin />

                  )
                    : (
                      <>

                        <Typography.Text
                          style={{

                            fontWeight: 400,
                          }}
                        >
                          Histórico de alterações
                          {' '}
                        </Typography.Text>

                        <Row>
                          <Typography.Text
                            style={{
                              fontWeight: 400,
                              marginLeft: 11,

                            }}
                          >
                            Ocultar histórico de alterações na geração/impressão da ficha ?
                            {' '}
                            <Switch
                        // eslint-disable-next-line camelcase
                              checked={pdfHistoric}
                              onChange={(checked) => {
                                this.setState({ pdfHistoric: checked });
                              }}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Typography.Text>
                        </Row>

                        <Typography.Text
                          style={{

                            fontWeight: 400,
                          }}
                        >
                          PDF
                          {' '}
                        </Typography.Text>

                        <Row>
                          <Typography.Text
                            style={{
                              fontWeight: 400,
                              marginLeft: 11,

                            }}
                          >
                            Mostrar no PDF o grupo de anestesia ?
                            {' '}
                            <Switch
                        // eslint-disable-next-line camelcase
                              checked={pdfGroupHistoric}
                              onChange={(checked) => {
                                this.setState({ pdfGroupHistoric: checked });
                              }}
                              checkedChildren="Sim"
                              unCheckedChildren="Não"
                            />
                          </Typography.Text>
                        </Row>

                        <Typography.Text style={{
                          marginTop: 15,
                          fontWeight: 400,

                        }}
                        >
                          Forma de impressão dos fármacos
                        </Typography.Text>
                        <Radio.Group onChange={this.onChange} value={value}>
                          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                            <Radio
                              style={styles.spaceBetweenRadioOptions}
                              value={0}
                            >
                              Nome comercial e princípio ativo

                            </Radio>
                            <Radio
                              style={styles.spaceBetweenRadioOptions}
                              value={1}
                            >
                              Somente princípio ativo

                            </Radio>
                            <Radio
                              style={styles.spaceBetweenRadioOptions}
                              value={2}
                            >
                              Somente nome comercial

                            </Radio>
                          </div>
                        </Radio.Group>
                      </>
                    )}
                </div>
                <Card title="Configuração de Agendamentos" headStyle={{ backgroundColor: '#f2f2f2', paddingLeft: 16 }} size="default" bodyStyle={{ paddingTop: 5 }}>
  <Row gutter={28}>
    <div style={{ display: 'flex', alignItems: 'center' }}> {}
      <Typography.Text style={{ fontWeight: 400, marginRight: 10 }}> {}
        Bloqueia a criação de agendamentos através do AxReg
      </Typography.Text>
      <Switch
        checked={blockAgendaCreation}
        onChange={this.handleSwitchChange}
        checkedChildren="Sim"
        unCheckedChildren="Não"
      />
    </div>
  </Row>
</Card>

              </Row>
            </Card>
          </Form>
        </Content>
      );
    }
}

function mapStateToProps(state) { return state; }

const connected = withRouter(connect(mapStateToProps)(Form.create()(Institution)));
export default connected;
