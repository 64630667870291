import Api from '../helpers/api';

export const eventsService = {
  getTransEvents,
  getTransEvent,
  putTransEvent,
  postTransEvent,
  getTransEventPresets,
  postTransEventPreset,
  getTransEventPresetsByName,
};

async function getTransEvents(filter = undefined, page, limit, cancelToken) {
  let result = null;
  page = page === undefined ? 1 : page;
  limit = limit === undefined ? 10 : limit;
  result = await Api.get('/institution-trans-events/count', {
    cancelToken,
    params: {
      ...filter,
    },
  });
  result.events = await Api.get('/institution-trans-events', {
    cancelToken,
    params: {
      page,
      limit,
      ...filter,
    },
  });

  return result;
}

function getTransEvent(id, filter, cancelToken) {
  return Api.get(`institution-trans-events/${id}`, filter, { cancelToken });
}

function putTransEvent(event) {
  return Api.put(`/institution-trans-event/${event.id}`, event);
}

function postTransEvent(event) {
  return Api.post('/institution-trans-event', event);
}

async function getTransEventPresets(filter = undefined, page, limit, cancelToken) {
  page = page === undefined ? 1 : page;
  limit = limit === undefined ? 10 : limit;
  return Api.get('/preset-trans-events', {
    cancelToken,
    params: {
      page,
      limit,
      ...filter,
    },
  });
}

async function getTransEventPresetsByName(name = undefined, cancelToken) {
  return Api.get(`/preset-trans-events/${name}`, {
    cancelToken,
  });
}

function postTransEventPreset(events) {
  return Api.post('/institution-trans-event/import', events);
}
