let configs = {};

switch (process.env.REACT_APP_NODE_ENV) {
  default:
  case 'localhost':
    configs = {
      urlApi: 'http://localhost',
      preset: 'http://localhost:3000/',
    };
    break;
  case 'development':
    configs = {
      urlApi: 'https://axreg-server.dev.cloud.axreg.app',
      preset: 'https://preset.dev.cloud.axreg.app/',
      urlExams: 'https://app-exams.dev.cloud.axreg.app',
      urlAnalytics: 'https://novo-dashboard.dev.cloud.axreg.app',
    };
    break;
  case 'homolog':
    configs = {
      urlApi: 'https://axreg-server.hom.cloud.axreg.app',
      preset: 'https://preset.hom.cloud.axreg.app/',
      urlExams: 'https://app-exams.hom.cloud.axreg.app',
      urlAnalytics: 'https://novo-dashboard.hom.cloud.axreg.app',
    };
    break;
  case 'production':
    configs = {
      urlApi: 'https://axreg-server.prod.cloud.axreg.app',
      preset: 'https://preset.prod.cloud.axreg.app/',
      urlExams: 'https://app-exams.prod.cloud.axreg.app',
      urlAnalytics: 'https://analytics-beta.axreg.app',
    };
    break;
}

export default configs;
