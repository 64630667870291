import { proceduresConstants } from '../constants';
import { proceduresService } from '../services';

function getProcedures(filter, cancelToken) {
  return (dispatch) => {
    dispatch(request());

    return proceduresService.getProcedures(filter, cancelToken)
      .then((procedures) => {
        dispatch(success(procedures));
        return procedures;
      }, (error) => dispatch(failure(error)));
  };

  function request() {
    return { type: proceduresConstants.GETPROCEDRURES_REQUEST };
  }

  function success(data) {
    return { type: proceduresConstants.GETPROCEDRURES_SUCCESS, data };
  }

  function failure(error) {
    return { type: proceduresConstants.GETPROCEDRURES_FAILURE, error };
  }
}

function getProceduresForAttendance(filter, cancelToken) {
  function request() {
    return { type: proceduresConstants.GETPROCEDRURES_REQUEST };
  }

  function success(data) {
    return { type: proceduresConstants.GETPROCEDRURES_SUCCESS, data };
  }

  function failure(error) {
    return { type: proceduresConstants.GETPROCEDRURES_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());

    return proceduresService.getProceduresForAttendance(filter, cancelToken)
      .then((data) => {
        dispatch(success(data));
        return data;
      }, (error) => dispatch(failure(error)));
  };
}

export const proceduresActions = {
  getProcedures,
  getProceduresForAttendance,
};
