import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Layout,
  Row,
  Tooltip,
  message,
  notification,
  Spin,
} from "antd";
import { connect } from "react-redux";
import { alertActions } from "../../actions";
import { usersService } from "../../services";
import iconHeader from "../../assets/imgs/axreg-logo.png";
import iconColor from "../../assets/imgs/icon_color.png";
import imageConfirmEmail from "../../assets/imgs/confirm-email.png";


const { Content, Footer } = Layout;

class ValidateEmail extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.load();
  }

  load = () => {
    let promiseSave;
    const { match } = this.props;
    const token = match.params.token;

    this.setState({ loading: true });

    promiseSave = usersService.getValidateEmail(token).then(result => {
      this.setState({
        loading: false
      });
    });

    promiseSave.catch(() => {
      notification.error({
        message:
          "Falha ao localizar usuário, tente novamente mais tarde ou informe ao suporte técnico!",
        duration: null
      });
    });
  };

  redirectPage = () => {
    window.open(
      "https://axreg.app/redirect-to-app",
      "_blank"
    );
  };

  render() {

    return (
      <div>
        <Layout
          className="login-page"
          style={{ minHeight: "100vh", padding: 0, background: "#1abfbb" }}
        >
          <Spin spinning={this.state.loading}>
            <Content style={{ padding: "0 10px" }}>
               <Row type="flex" justify="center" align="middle">
                  <Col xs={0} sm={2} md={3} lg={6} xl={7} xxl={8} />
                  <Col
                    xs={24}
                    sm={20}
                    md={18}
                    lg={12}
                    xl={10}
                    xxl={8}
                    style={{ paddingTop: "50px" }}
                  >
                    <div style={{ padding: "30px" }} className="login-form">
                      <Card
                        title={
                          <div style={{ textAlign: "center" }} className="logo">
                            <img
                              src={iconHeader}
                              alt="Logo AxReg"
                              className="logo-axreg-invite"
                            />
                          </div>
                        }
                        bordered={false}
                        style={{ width: "100%" }}
                      >
                        {!this.state.loading && (
                          <div style={{textAlign: "center"}}>
                             <img
                                src={imageConfirmEmail}
                                alt="AxReg Email Confirmado"
                                className="axreg-validate-email"
                              />

                            <p style={{ fontSize: 17, marginTop: 10 }}>
                              E-mail validado com sucesso!
                            </p>


                            <Button
                                  block
                                  type="primary"
                                  icon="apple"
                                  size="large"
                                  style={{ marginTop: 10 }}
                                  onClick={() => this.redirectPage()}
                                >
                                  ACESSAR AXREG
                            </Button>
                     

                            <Divider />
                            <p>
                              <small>
                                Em caso de qualquer dúvida, fique à vontade para
                                nos contatar no{" "}
                                <a
                                  href="mailto:suporte@anestech.com.br"
                                  target="_top"
                                >
                                  suporte@anestech.com.br
                                </a>
                              </small>
                            </p>
                          </div>
                        )}
                      </Card>
                    </div>
                  </Col>
                  <Col xs={0} sm={2} md={3} lg={6} xl={7} xxl={8} />
                </Row>
              
            </Content>
          </Spin>
          <Footer
            style={{
              position: "relative",
              bottom: "0",
              textAlign: "center",
              background: "#1abfbb",
              color: "white"
            }}
          >
            Powered by
            <Tooltip title="Anestech">
              {" "}
              <a
                href="http://www.anestech.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={iconColor} alt="Logo" />{" "}
              </a>
            </Tooltip>
          </Footer>
        </Layout>
      </div>
    );
  }

  alerts() {
    const { alert, dispatch } = this.props;
    if (alert.message) {
      message[alert.type](alert.message);
      dispatch(alertActions.clear());
    }
    return null;
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert, institutions } = state;
  return {
    loggingIn,
    alert,
    institutions
  };
}

const connectedLoginPage = connect(mapStateToProps)(Form.create()(ValidateEmail));
export default connectedLoginPage;
