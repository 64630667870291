// eslint-disable-next-line import/no-cycle
import Api from '../helpers/api';

function getAnesthetists() {
  return Api.get('/invite/import/anesthetists');
}

function importAnesthetists(anesthetists) {
  return Api.post('/invite/import/anesthetist', anesthetists);
}

function retryInviteAnesthetists() {
  return Api.post('/invite/import/anesthetist/retry');
}

// eslint-disable-next-line import/prefer-default-export
export const anesthetistsInviteService = {
  getAnesthetists,
  importAnesthetists,
  retryInviteAnesthetists,
};
