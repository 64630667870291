import Api from '../helpers/api';

export const surgicalTeamsService = {
  getSurgicalTeams,
  getSurgicalTeam,
  postSurgicalTeam,
  putSurgicalTeam,
  deleteSurgicalTeam
};

function getSurgicalTeams(filter, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/preset/surgical-teams`, {cancelToken})
}

function getSurgicalTeam(id, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/preset/surgical-teams/${id}`, {cancelToken})
}

function postSurgicalTeam(surgicalTeam){
  return Api.post(`/preset/surgical-teams`, surgicalTeam)
}

function putSurgicalTeam(surgicalTeam){
  return Api.put(`/preset/surgical-teams/${surgicalTeam.id}`, surgicalTeam)
}

function deleteSurgicalTeam(id){
  return Api.delete(`/preset/surgical-teams/${id}`)
}