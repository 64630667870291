/* eslint-disable camelcase */
// eslint-disable-next-line import/no-cycle
import Api from '../helpers/api';

// eslint-disable-next-line max-len
function saveUserLog(user_id, institution_id, event, pdf_name, recipient_email, updated_fields = [], deleted_invitation_id, deleted_user_id) {
  let formattedUpdatedFields = [];

  // Verifique se updated_fields é um array
  if (Array.isArray(updated_fields)) {
    formattedUpdatedFields = updated_fields.map((field) => ({
      field: field.field,
      values: {
        old_value: field.values.old_value !== undefined ? field.values.old_value : null,
        new_value: field.values.new_value !== undefined ? field.values.new_value : null,
      },
    }));
  }

  // Enviar solicitação POST com os dados formatados
  // eslint-disable-next-line consistent-return
  return Api.post('/app/user_log', {
    user_id,
    institution_id,
    event,
    pdf_name,
    recipient_email,
    updated_fields: formattedUpdatedFields,
    deleted_invitation_id,
    deleted_user_id,
  });
}

function getUserLog() {
  return Api.get('/app/user_log');
}

export const logService = { saveUserLog, getUserLog };
