import moment from 'moment';

export const dates = {
  age
};

function age(birthDate) {
  let today = moment().startOf('day');
  let birth = moment(birthDate, 'DD/MM/YYYY').startOf('day');

  let yearsAge = today.diff(birth, 'years');

  if (yearsAge > 0) {
    return yearsAge + ' ano' + (yearsAge > 1? 's':'');
  }
  let months = today.diff(birth, 'months');

  if (months > 0) {
    return months + ' mes' + (months > 1? 'es':'');
  }

  let days = today.diff(birth, 'days');

  if (days === 0){
    return 'Hoje';
  }
  if(days < 0 || !birthDate) {
    return '-';
  }

  return days + ' dia' + (days > 1? 's':'');
}