import Api from '../helpers/api';

export const materialsService = {
  getMaterials,
  getMaterial,
  postMaterial,
  putMaterial,
  deleteMaterial
};

function getMaterials(filter, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/preset/materials`, filter, {cancelToken})
}

function getMaterial(id, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/preset/materials/${id}`, {cancelToken})
}

function postMaterial(medicalPlan){
  return Api.post(`/preset/materials`, medicalPlan)
}

function putMaterial(medicalPlan){
  return Api.put(`/preset/materials/${medicalPlan.id}`, medicalPlan)
}

function deleteMaterial(id){
  return Api.delete(`/preset/materials/${id}`)
}