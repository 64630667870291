import React, { Component } from "react";
import {
    Form,
    Icon,
    Input,
    Button,
    message,
    Modal
  } from "antd";
  import api from "../helpers/api";

class PasswordReset extends Component {
  state = {
    modalVisible: false,
    loading: false
  };

  showModal = () => {
    this.setState({
      modalVisible: true,
      loading: false
    });
  };

  handleCancel = e => {
    this.setState({
      modalVisible: false,
      loading: false
    });
  };

  handleSubmitReset = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // request login
        try {

          this.setState({ loading: true });

          api
            .post("/web/users/passwordReset", values)
            .then(res => {
              this.setState({ loading: false, modalVisible: false });
              message.success('A instruções para resetar sua senha foi enviado por email.')
            })
            .catch(error => {
                message.error(
                    "Ops! Não foi possível recuperar a senha, tente novamente mais tarde."
                );
              this.setState({ loading: false, modalVisible: false });
            });

          //this.props.history.push("/app");
        } catch (err) {
            message.error(
                "Ops! Não foi possível recuperar a senha, tente novamente mais tarde."
            );
        }
      }
    });
  };

  render() {
    
    const { getFieldDecorator } = this.props.form;
    
    return (
      <div>
        <Button type="dashed" size="small" onClick={this.showModal} >
          <Icon type="unlock" /> Recuperar senha
        </Button>

        <Modal
          title="Recuperar senha"
          zIndex="565565"
          key="1"
          visible={this.state.modalVisible}
          onOk={this.handleCancel}
          onCancel={this.handleCancel}
          footer={null}
        >
        <p>Para recuperar sua senha, preencha o campo abaixo com seu e-mail cadastrado.</p>
          <Form name="formReset" id="formReset">
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [
                  {
                    required: true,
                    message: "Digite o e-mail!"
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="email"
                  placeholder="Email"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button
                block
                key="submit"
                type="primary"
                loading={this.state.loading}
                htmlType="button"
                form="formReset"
                onClick={this.handleSubmitReset}
              >
                Recuperar senha
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    );
  }
}

const WrappedNormalResetForm = Form.create()(PasswordReset);

export default WrappedNormalResetForm;
