import Api from '../helpers/api';

export const medicalPlansService = {
  getMedicalPlans,
  getMedicalPlan,
  postMedicalPlan,
  putMedicalPlan,
  deleteMedicalPlan,
  getMedicalPlanTableStandard
};

function getMedicalPlans(filter, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/preset/medical-plans/?size=1000&offset=0`, {cancelToken})
}

function getMedicalPlan(id, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/preset/medical-plans/${id}`, {cancelToken})
}

function getMedicalPlanTableStandard() {
  // ID -> 1 setting table standard
  return Api.get(`institutions/settings/1`)
}

function postMedicalPlan(medicalPlan){
  return Api.post(`/preset/medical-plans`, medicalPlan)
}

function putMedicalPlan(medicalPlan){
  return Api.put(`/preset/medical-plans/${medicalPlan.id}`, medicalPlan)
}

function deleteMedicalPlan(uuid){
  return Api.delete(`/preset/medical-plans/${uuid}`)
}