import React, { Component } from 'react';
import { Checkbox, Input } from 'antd';
import { CancelToken } from 'axios';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { List } from 'immutable';
import { selectsActions } from '../actions';
import Modal from './Modal';
import { stringsHelp } from '../helpers';

class SelectSurgeons extends Component {
  cancelSource = CancelToken.source();

  state = {
    modalVisible: false,
    selected: new List(),

    tracks: new List(),
    hasMoreItems: true,
  };

  loadSurgeons = (page) => {
    const value = this.filter ? this.filter.input.value : '';
    const { dispatch } = this.props;

    if (this.searchingSurgeonsValue !== value || this.page !== page) {
      this.page = page;

      if (this.searchingSurgeons && this.searchingSurgeonsValue !== value) {
        this.cancelSource.cancel();
        this.cancelSource = CancelToken.source();
      }

      this.searchingSurgeons = true;
      this.searchingSurgeonsValue = value;

      dispatch(selectsActions.getSurgeons(page, value, this.cancelSource.token))
        .then((surgeons) => {
          this.searchingSurgeons = false;

          if (surgeons.error) {
            return null;
          }
          let tracks;
          if (surgeons.page > 1) {
            tracks = this.state.tracks.concat(surgeons.result);
          } else {
            tracks = new List(surgeons.result);
          }
          return this.setState({
            tracks,
            hasMoreItems: surgeons.page < surgeons.pages,
          });
        });
    }
  };

  loadItems = () => {
    let { page } = this;
    page++;
    this.loadSurgeons(page);
  };

  setModalVisible(modalVisible) {
    this.setState({ modalVisible });
  }

  filterSurgeons = () => {
    this.setState({
      tracks: new List(),
      hasMoreItems: true,
    });

    this.loadSurgeons(1);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value !== prevProps.value) {
      this.changeSelected(new List(this.props.value));
    }
  }

  componentDidMount() {
    if (this.props.value) {
      this.changeSelected(new List(this.props.value));
    }
    this.loadSurgeons(1);
  }

  changeSelected = (selected) => {
    if (this.props.onChange) { this.props.onChange(selected); }
    this.setState({ selected });
  };

  select = (event) => {
    const { value } = event.target;
    value.laterality = 1;
    this.changeSelected(this.state.selected.push(value));
  };

  deselect = (event) => {
    const { value } = event.target;
    const selected = this.state.selected.filter((v) => v.id !== value.id);

    this.changeSelected(selected);
  };

  render() {
    const { surgeons } = this.props;
    return (
      <div className="form-list">
        <Input hidden />
        {
          this.state.selected.size
            ? this.state.selected.map((surgeon) => {
              const name = stringsHelp.firstLetterUpper(surgeon.name, true);
              return (
                <div className="form-list-item" title={name} key={surgeon.id}>
                  <span className="form-list-description">
                    Dr(a).
                    {' '}
                    {name}
                  </span>
                  <span className="form-list-del" onClick={() => this.deselect({ target: { value: surgeon } })}>
                    <i className="icon-close" />
                  </span>
                </div>
              );
            })
            : null
        }
        <div className="form-list-item">
          <button type="button" className="form-add-link" onClick={() => this.setModalVisible(true)}>
            + Adicionar cirugiões
          </button>
        </div>

        <Modal
          title="Cirurgiões"
          visible={this.state.modalVisible}
          onCancel={() => this.setModalVisible(false)}
          onOk={() => this.setModalVisible(false)}
          footer={false}
          buttonOkText="SALVAR"
          buttonOkType="primary"
        >
          <Input placeholder="Buscar" ref={(input) => this.filter = input} onKeyUp={this.filterSurgeons} />

          <div className={`form-list ${this.state.selected.size ? '' : 'hidden'}`} style={{ marginTop: '21px' }}>
            <div className="form-list-title">
              SELECIONADOS (
              {this.state.selected.size}
              )
            </div>
            {
              this.state.selected.size
                ? this.state.selected.map((surgeon) => {
                  const name = stringsHelp.firstLetterUpper(surgeon.name, true);
                  return (
                    <div className="form-list-item slide-in" key={surgeon.id}>
                      <span className="form-list-description" title={`Dr(a). ${name}`}>
                        <Checkbox defaultChecked value={surgeon} onChange={this.deselect}>
                          Dr(a).
                          {' '}
                          {name}
                        </Checkbox>
                      </span>
                    </div>
                  );
                })
                : null
            }
          </div>
          <div className="form-list" style={{ marginTop: '21px', height: '500px', overflow: 'auto' }}>
            <div className="form-list-title">TODOS</div>

            {
              surgeons
                ? (
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={this.loadItems}
                    hasMore={this.state.hasMoreItems}
                    useWindow={false}
                  >
                    {
                    this.state.tracks
                      ? this.state.tracks.map((surgeon) => {
                        const name = stringsHelp.firstLetterUpper(surgeon.name, true);
                        if (this.state.selected.find((a) => a.id === surgeon.id)) {
                          return null;
                        }

                        return (
                          <div className="form-list-item slide-in" key={surgeon.id}>
                            <span className="form-list-description" title={`Dr(a). ${name}`}>
                              <Checkbox value={surgeon} onChange={this.select}>
                                Dr(a).
                                {' '}
                                {name}
                              </Checkbox>
                            </span>
                          </div>
                        );
                      })
                      : null
                  }
                  </InfiniteScroll>
                ) : null
            }
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { selects } = state;
  const { surgeons } = selects;
  return {
    surgeons,
  };
}

const connected = connect(mapStateToProps)(SelectSurgeons);
export default connected;
