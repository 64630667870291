/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';

import {
  Layout,
  Row,
  Col,
  Skeleton,
  Avatar,
  Icon,
  Tabs,
  Badge,
  notification,
  Spin,
  Input,
  Timeline,
  Card,
  Tag,
  Radio,
  Menu,
  Dropdown,
  Button,
  Empty,
  Divider,
  Popover,
  Select,
  Form,
  Tooltip,
  Upload,
  DatePicker,
} from 'antd';
import { generatePath, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { CancelToken } from 'axios';
import Timer from 'react-compound-timer';
import Modal from '../Modal';
import { pathRoutes } from '../../routes';
import {
  schedulingActions, selectsActions, institutionActions, sectorsActions,
} from '../../actions';
import { dates, stringsHelp, arrayHelp } from '../../helpers';
import { schedulingService } from '../../services';
import ImportExcel from '../ImportExcel';
import { IconTabPreSvg, IconTabProcedureSvg } from '../../helpers/icons';
import FilterDate from '../FilterDate';
import config from '../../config';

import ModalCheckInstitution from '../ModalCheckInstitution';
import ButtonFixAnaesthetist from '../ButtonFixAnaesthetist';
import { mixPanelService } from '../../services/mixpanel.service';
import DateTime from '../DateTime';

const { Content } = Layout;
const { TabPane } = Tabs;
const { Search } = Input;
const FormItem = Form.Item;
const { Option, OptGroup } = Select;

class Map extends Component {
  cancelSource = CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      modalFiltroVisible: false,
      surgeryCenters: [],
      selectedSurgeryCenter: undefined,
      roomsFiltered: [],
      surgeryCenterRooms: [],
      selectedSurgeryCenterRoom: undefined,
      filterTemp: {
        selectedSurgeryCenter: null,
        selectedSurgeryCenterRoom: null,
      },

      modalVisible: false,
      modalLoading: true,
      modalProcedure: null,
      modalType: null,
      dataPreAnesthetics: [],
      dataSurgeries: [],
      dataSurgeriesRealTime: [],
      dataLoading: false,
      is_search: false,
      keyTab: '1',
      addDropdownArrow: 'down',
      modalVisibleInstitutions: false,
      checkInstituionRoute: null,
      importConditionTable: 'surgical-map',
      importConditionTableName: 'Procedimentos cirúrgicos',
      newCancellationReason: false,
      loadingCancellation: false,
      popoverCancellationVisible: false,
      doneProcedures: [],
      donePre: [],
      loadingSearch: false,
      pacientExams: [],
      examProcessingModalVisible: false,
      documentsOcr: [],
      rawResultDate: '',
      refreshIntervalId: 0,
      textSearch: null,
      is_group: false,
    };
  }

  openFiltroModal = () => {
    this.setState({
      modalFiltroVisible: true,
      filterTemp: {
        selectedSurgeryCenter: this.state.selectedSurgeryCenter,
        selectedSurgeryCenterRoom: this.state.selectedSurgeryCenterRoom,
      },
    }, () => {
      if (this.state.selectedSurgeryCenter) {
        this.loadRoomsForSurgeryCenter(this.state.selectedSurgeryCenter);
      }
    });
  };

  closeFiltroModal = () => {
    this.setState({ modalFiltroVisible: false });
  };

  componentDidMount() {
    const { dispatch } = this.props;
    this.setState({ loadingSearch: true });
    mixPanelService.tracking(mixPanelService.MENU_MAPA);

    dispatch(institutionActions.getInstitutions());
    dispatch(selectsActions.getCancellationReason());
    this.props.dispatch(sectorsActions.getSectors(undefined, 1, 2000));
    this.loadMap();

    const savedFilters = localStorage.getItem('mapFilters');
    if (savedFilters) {
      const filters = JSON.parse(savedFilters);
      this.setState({ ...filters }, () => {
        if (filters.selectedSurgeryCenter) {
          this.loadRoomsForSurgeryCenter(filters.selectedSurgeryCenter);
          this.setState({ selectedSurgeryCenterRoom: filters.selectedSurgeryCenterRoom });
        }
        this.applyFilters();
      });
    }
    const selectedInstitution = JSON.parse(localStorage.getItem('selected-institution'));
    this.setState({ is_group: selectedInstitution.is_group });
  }

  applyFilters() {
    const { selectedSurgeryCenter, selectedSurgeryCenterRoom } = this.state;

    const filterParams = {};
    if (selectedSurgeryCenter) {
      filterParams.surgeryCenterId = selectedSurgeryCenter;
    }
    if (selectedSurgeryCenterRoom) {
      filterParams.surgeryCenterRoomId = selectedSurgeryCenterRoom;
    }
    this.loadMap(this.state.textSearch, filterParams);
  }

  componentDidUpdate(prevProps, prevState) {
    const { scheduling, sectors } = this.props;

    // Verifica se os setores foram atualizados
    if (JSON.stringify(prevProps.sectors) !== JSON.stringify(sectors)) {
      this.loadRoomsForSurgeryCenter(this.state.selectedSurgeryCenter);
    }

    // Verifica se a lista de salas foi atualizada
    if (prevState.surgeryCenterRooms !== this.state.surgeryCenterRooms) {
      const savedFilters = localStorage.getItem('mapFilters');
      if (savedFilters) {
        const filters = JSON.parse(savedFilters);
        if (filters.selectedSurgeryCenterRoom) {
          this.setState({ selectedSurgeryCenterRoom: filters.selectedSurgeryCenterRoom });
        }
      }
    }

    if (prevProps.scheduling.date !== scheduling.date) {
      this.loadMap();
    }
  }

  componentWillUnmount() {
    clearInterval(this.countDuration);
    clearInterval(this.state.refreshIntervalId);
  }

  loadDocumentsOcrStatus = async (procedureId, calledBySetInterval = false) => {
    const token = localStorage.getItem('auth-token');
    const url = `${config.urlApi}/v1/procedure/${procedureId}/ocrs`;
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: token,
      },
    });

    if (response.status === 200) {
      this.setState({ documentsOcr: await response.json() });
      if (!calledBySetInterval) {
        this.setState({ pacientExams: [], rawResultDate: '' });
      }
    }
  }

  handleUploadExams = async (ResultDate, files) => {
    if (this.state.rawResultDate === '') {
      return notification.error({
        message:
          'Selecione a data!',
      });
    }

    if (this.state.pacientExams.length === 0) {
      return notification.error({
        message:
          'Selecione ao menos um arquivo para upload!',
      });
    }

    const data = new FormData();
    files.forEach(async (file) => {
      data.append('files', file);
    });
    data.append('procedureId', this.state.modalProcedure.id);
    data.append('resultDate', this.state.examsResultDate);
    const url = `${config.urlApi}/v1/ocr-queue`;
    console.log(url);
    const token = localStorage.getItem('auth-token');
    this.setState({ examProcessingModalVisible: true });
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: token,
      },
      body: data,
    });
    this.setState({ examProcessingModalVisible: false });
    if (response.status === 200) {
      await this.loadDocumentsOcrStatus(this.state.modalProcedure.id);
      notification.success({
        message:
          'Arquivo enviado à fila de processamento com sucesso.',
      });
    }
    console.log(response);
    if (response.status === 401 || response.status === 500) {
      notification.error({
        message:
          'Falha ao enviar o arquivo, notifique o suporte técnico!',
      });
    } else if (response.status === 400) {
      notification.error({
        message:
          'Falha ao enviar o arquivo. Verifique se o arquivo foi anexado corretamente',
      });
    }
    this.state.pacientExams = [];
    this.state.examsResultDate = '';
  }

  loadMap = (textSearch = null, filterParams = {}) => {
    console.log('Estado Atual:', this.state.selectedSurgeryCenter, this.state.selectedSurgeryCenterRoom); const { dispatch, scheduling } = this.props;
    const selectedInstitution = JSON.parse(localStorage.getItem('selected-institution'));

    this.date = scheduling.date;
    let objParams = '';

    this.setState({ dataLoading: true });

    this.cancelSource.cancel();
    this.cancelSource = CancelToken.source();

    const { selectedSurgeryCenter, selectedSurgeryCenterRoom } = this.state;

    if (textSearch || scheduling.date || selectedSurgeryCenter || selectedSurgeryCenterRoom) {
      if (textSearch) {
        objParams += `search=${textSearch}&type=all&limit=50&page=1`;
      }

      if (selectedSurgeryCenter) {
        if (objParams.length > 0) {
          objParams += '&';
        }
        objParams += `surgeryCenterId=${selectedSurgeryCenter}`;
      }
      if (selectedSurgeryCenterRoom) {
        if (objParams.length > 0) {
          objParams += '&';
        }
        objParams += `surgeryCenterRoomId=${selectedSurgeryCenterRoom}`;
      }
      if (objParams.length === 0) {
        objParams = `probable_date=${scheduling.date.format(
          'YYYY-MM-DDTHH:mm:ssZ',
        )}`;
      } else if (!textSearch) {
        objParams += `&probable_date=${scheduling.date.format(
          'YYYY-MM-DDTHH:mm:ssZ',
        )}`;
      }

      dispatch(
        schedulingActions.getScheduling(
          objParams,
          this.cancelSource.token,
          selectedInstitution,
        ),
      )
        .then((data) => {
          if (data.hasOwnProperty('error')) return false;

          let new_dataPreAnesthetics = [];
          let new_dataSurgeries = [];
          let new_dataSurgeriesRealTime = [];

          if (data.hasOwnProperty('pre')) {
            new_dataPreAnesthetics = data.pre.data
              .filter((x) => x.data.filter((xx) => {
                const valid = true;
                /*
                if (x.clinic_id && !selectedInstitution.is_group) {
                  valid = xx.clinic_id === selectedInstitution.id;
                }
*/
                return xx.active && valid;
              }))
              .sort(arrayHelp.sortArray('date'));
          } else {
            new_dataPreAnesthetics.push({
              date: scheduling.date.format('YYYY-MM-DD'),
              data: !data
                ? []
                : data
                  .filter((x) => {
                    const valid = true;
                    /*
                    if (x.clinic_id && !selectedInstitution.is_group) {
                      valid = x.clinic_id === selectedInstitution.id;
                    }
*/
                    return (
                      x.pre_probable_date
                        && moment(x.pre_probable_date).format('YYYY-MM-DD')
                          === scheduling.date.format('YYYY-MM-DD')
                        && x.active && valid
                    );
                  })
                  .sort(arrayHelp.sortArray('pre_probable_date')),
            });
          }

          if (data.hasOwnProperty('surgeries')) {
            new_dataSurgeries = data.surgeries.data
              .filter((x) => x.data.filter((xx) => xx.active))
              .sort(arrayHelp.sortArray('date'));
          } else {
            new_dataSurgeries.push({
              date: scheduling.date.format('YYYY-MM-DD'),
              data: !data
                ? []
                : data
                  .filter((x) => (
                    x.surgery_probable_date
                        && moment(x.surgery_probable_date).format('YYYY-MM-DD')
                          === scheduling.date.format('YYYY-MM-DD')
                        && x.active
                  ))
                  .sort(arrayHelp.sortArray('surgery_probable_date')),
            });

            new_dataSurgeriesRealTime = !data
              ? []
              : data
                .filter((x) => (
                  x.surgery_probable_date
                      && (x.anesthesia_start && !x.anesthesia_end)
                ))
                .map((obj) => {
                  const ms = moment(moment(), 'YYYY-MM-DDTHH:mm:ssZ').diff(
                    moment(obj.anesthesia_start, 'YYYY-MM-DDTHH:mm:ssZ'),
                  );
                  return {
                    ...obj,
                    duration: ms,
                  };
                });
          }

          this.setState({
            dataPreAnesthetics: new_dataPreAnesthetics,
            dataSurgeries: new_dataSurgeries,
            dataSurgeriesRealTime: new_dataSurgeriesRealTime,
            dataLoading: false,
            loadingSearch: false,
          });
        })
        .catch((error) => {
          console.log('ERRROR', error);
          this.setState({
            dataPreAnesthetics: [],
            dataSurgeries: [],
            dataSurgeriesRealTime: [],
            dataLoading: false,
          });
        });
    }
  };

  handleSubmitCancellationReason = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.cancellation_date = moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        values.cancellation_reason_description = values.hasOwnProperty(
          'cancellation_reason_description',
        )
          ? values.cancellation_reason_description
          : null;
        values.cancellation_reason_id = values.hasOwnProperty(
          'cancellation_reason_id',
        )
          ? values.cancellation_reason_id
          : null;

        let promiseSave;

        this.setState({ loadingCancellation: true });

        promiseSave = schedulingService
          .putCancellationReason(this.state.modalProcedure.id, values)
          .then((result) => {
            if (result.hasOwnProperty('cancellation_date')) {
              notification.success({
                message: 'Procedimento cancelado com sucesso',
              });

              this.setState({
                loadingCancellation: false,
                modalVisible: false,
                popoverCancellationVisible: false,
              });

              this.loadMap();
            }
          });

        promiseSave.catch((error) => {
          console.log(error);
          this.setState({ loadingCancellation: false });
          notification.error({
            message: error,
          });
        });
      }
    });
  };

  openModal = async (procedure, type) => {
    let promiseSave;
    this.setModalVisible(true);
    await this.loadDocumentsOcrStatus(procedure.id);
    const refreshIntervalId = setInterval(
      () => this.loadDocumentsOcrStatus(procedure.id, true),
      5000,
    ); // executa a cada 5s
    await this.setState({ refreshIntervalId });

    promiseSave = schedulingService.getProcedure(procedure.id).then((result) => {
      if (result.id > 0) {
        this.setState({
          modalLoading: false,
          modalProcedure: result,
          modalType: type,
        });
      }
    });

    promiseSave.catch((error) => {
      console.log(error);
      notification.error({
        message:
          'Falha ao carregar detalhes de agendamento, notifique o suporte técnico!',
      });
    });
  };

  removeFilter = (filterKey) => {
    if (!this.state.selectedSurgeryCenter && !this.state.selectedSurgeryCenterRoom) {
      this.setState({ is_search: false });
    }
    this.setState((prevState) => ({
      selectedSurgeryCenter: filterKey === 'selectedSurgeryCenter' ? null : prevState.selectedSurgeryCenter,
      selectedSurgeryCenterRoom: filterKey === 'selectedSurgeryCenterRoom' ? null : prevState.selectedSurgeryCenterRoom,
    }), () => {
      const { selectedSurgeryCenter, selectedSurgeryCenterRoom } = this.state;
      localStorage.setItem('mapFilters', JSON.stringify({ selectedSurgeryCenter, selectedSurgeryCenterRoom }));
      this.loadMap(this.state.textSearch);
    });
  };

  getFilterName = (filterId, filterType) => {
    let filterName = '';

    if (filterType === 'sectors') {
      const sector = this.props.sectors.data.find((sector) => sector.id === filterId);
      filterName = sector ? sector.name : '';
    } else if (filterType === 'rooms') {
      const room = this.state.surgeryCenterRooms.find((room) => room.id === filterId);
      filterName = room ? room.name : '';
    }

    return filterName;
  };

  onSelectSurgeryCenter = (selectedSectorId) => {
    const selectedSector = this.props.sectors.data.find((sector) => sector.id === selectedSectorId);
    const rooms = selectedSector ? selectedSector.surgery_center_rooms : [];

    this.setState((prevState) => ({
      filterTemp: {
        ...prevState.filterTemp,
        selectedSurgeryCenter: selectedSectorId,
        selectedSurgeryCenterRoom: null,
      },
      surgeryCenterRooms: rooms,
    }));
    this.loadRoomsForSurgeryCenter(selectedSectorId);
  };

  onSelectSurgeryCenterRoom = (selectedRoomId) => {
    this.setState((prevState) => ({
      filterTemp: {
        ...prevState.filterTemp,
        selectedSurgeryCenterRoom: selectedRoomId,
      },
    }));
  };

  handleFilter = () => {
    this.setState((prevState) => ({
      selectedSurgeryCenter: prevState.filterTemp.selectedSurgeryCenter,
      selectedSurgeryCenterRoom: prevState.filterTemp.selectedSurgeryCenterRoom,
      modalFiltroVisible: false,
    }), () => {
      const { selectedSurgeryCenter, selectedSurgeryCenterRoom } = this.state;
      localStorage.setItem('mapFilters', JSON.stringify({ selectedSurgeryCenter, selectedSurgeryCenterRoom }));

      this.applyFilters();
    });
  };

  loadRoomsForSurgeryCenter = (surgeryCenterId) => {
    const selectedSector = this.props.sectors.data.find((sector) => sector.id === surgeryCenterId);

    const rooms = selectedSector ? selectedSector.surgery_center_rooms : [];

    this.setState((prevState) => {
      const roomStillValid = rooms.find((room) => room.id === prevState.selectedSurgeryCenterRoom);
      return {
        surgeryCenterRooms: rooms,
        selectedSurgeryCenterRoom: roomStillValid ? prevState.selectedSurgeryCenterRoom : null,
        filterTemp: {
          ...prevState.filterTemp,
          selectedSurgeryCenterRoom: roomStillValid ? prevState.selectedSurgeryCenterRoom : null,
        },
      };
    });
  }

  setModalVisible = (modalVisible) => {
    this.setState({ modalVisible, modalLoading: true });
  };

  setModalVisibleCheckInstitution = (modalVisibleInstitutions) => {
    this.setState({ modalVisibleInstitutions, addDropdownArrow: 'down' });
  };

  handleOk = (id) => {
    if (this.state.modalType === 'pre') {
      this.props.history.push(generatePath(pathRoutes.editSchedulePre, { id }));
    } else {
      this.props.history.push(generatePath(pathRoutes.editSchedule, { id }));
    }
  };

  handleCancel = () => {
    // this.setModalVisible(false);
    clearInterval(this.state.refreshIntervalId);
    this.setState({
      modalVisible: false,
      modalLoading: false,
      modalProcedure: null,
      modalType: null,
    });
  };

  SearchScheduling = (e) => {
    const text = e.target.value;

    if (text.length) {
      this.setState({ is_search: true, textSearch: text });
      mixPanelService.tracking(mixPanelService.MAPA_BUSCA_PACIENTE);
      setTimeout(() => {
        if (text.length >= 3) {
          this.loadMap(text);
        }
      }, 300);
    } else {
      this.setState({ is_search: false, textSearch: null });
      this.loadMap();
    }
  };

  addArrowChange = (a) => {
    if (a) {
      this.setState({ addDropdownArrow: 'up' });
    } else {
      this.setState({ addDropdownArrow: 'down' });
    }
  };

  checkDay = (date) => {
    const diff = date.diff(moment().startOf('day'), 'day');

    switch (diff) {
      case 0:
        return `Hoje, ${moment(date).format('DD/MM')}`;
      case 1:
        return `Amanhã, ${moment(date).format('DD/MM')}`;
      case -1:
        return `Ontem, ${moment(date).format('DD/MM')}`;
      default:
        return moment(date).format('DD/MM/YYYY');
    }
  };

  isProcedureDone = (type, procedureId) => {
    const { doneProcedures, donePre } = this.state;

    return type === 'procedure' ? doneProcedures.includes(procedureId) : donePre.includes(procedureId);
  }

  renderStatusScheduling = (map, type, obj) => {
    try {
      if (map === 'pre') {
        if (obj.cancellation_date) {
          return type === 'label' ? (
            <span className="s-cancel">
              <Divider type="vertical" />
              {' '}
              Cancelado
            </span>
          ) : (
            <Icon
              type="close-circle"
              theme="filled"
              className="icon-timeline-cancel"
            />
          );
        }
        if (obj.is_team_changed && !obj.create_pre_date) {
          return type === 'label' ? (
            <span />
          ) : (
            <span className="span-timeline-is-team-changed">
              <Icon type="arrow-up" className="icon-timeline-is-team-changed" />
            </span>
          );
        }
        if (obj.is_team_changed && obj.create_pre_date) {
          return type === 'label' ? (
            <span className="s-success">
              <Divider type="vertical" />
              {' '}
              Realizado
            </span>
          ) : (
            <Icon type="arrow-up" className="icon-timeline-is-team-changed" />
          );
        }
        if (obj.create_pre_date) {
          return type === 'label' ? (
            <span className="s-success">
              <Divider type="vertical" />
              {' '}
              Realizado
            </span>
          ) : (
            <Icon
              type="check-circle"
              theme="filled"
              className="icon-timeline-success"
            />
          );
        }
        if (!obj.create_pre_date) {
          return type === 'label' ? (
            <span />
          ) : (
            <Icon
              type="check-circle"
              theme="filled"
              className="icon-timeline-start"
            />
          );
        }
        if (obj.pre_probable_date && obj.owner && !obj.create_pre_date) {
          return (
            <span className="s-running">
              <Divider type="vertical" />
              {' '}
              Em curso
            </span>
          );
        }
      } else {
        if (obj.cancellation_date) {
          return type === 'label' ? (
            <span className="s-cancel">
              <Divider type="vertical" />
              {' '}
              Cancelado
            </span>
          ) : (
            <Icon
              type="close-circle"
              theme="filled"
              className="icon-timeline-cancel"
            />
          );
        }
        if (obj.surgery_probable_date && !obj.anesthesia_start) {
          return type === 'label' ? (
            <span />
          ) : (
            <Icon
              type="check-circle"
              theme="filled"
              className="icon-timeline-start"
            />
          );
        }
        if (obj.is_team_changed && !obj.procedure_closed_date) {
          return type === 'label' ? (
            <span />
          ) : (
            <span className="span-timeline-is-team-changed">
              <Icon type="arrow-up" className="icon-timeline-is-team-changed" />
            </span>
          );
        }
        if (obj.is_team_changed && obj.procedure_closed_date) {
          return type === 'label' ? (
            <span className="s-success">
              <Divider type="vertical" />
              {' '}
              Realizado
            </span>
          ) : (
            <Icon type="arrow-up" className="icon-timeline-is-team-changed" />
          );
        }
        if (obj.anesthesia_start && obj.anesthesia_end) {
          return type === 'label' ? (
            <span className="s-success">
              <Divider type="vertical" />
              {' '}
              Realizado
            </span>
          ) : (
            <Icon
              type="check-circle"
              theme="filled"
              className="icon-timeline-success"
            />
          );
        }
        if (
          obj.surgery_probable_date
          && obj.owner
          && (obj.anesthesia_start && !obj.anesthesia_end)
        ) {
          return type === 'label' ? (
            <span className="s-running">
              <Divider type="vertical" />
              {' '}
              Em curso
            </span>
          ) : (
            <Badge status="processing" className="icon-timeline-processing" />
          );
        }
      }
    } catch (error) {
      return '';
    }
  };

  routeProcedurePre = (route) => {
    if (route === 'pre') {
      mixPanelService.tracking(mixPanelService.MAPA_ADD_PRE);
      if (
        JSON.parse(localStorage.getItem('selected-institution'))
        && JSON.parse(localStorage.getItem('selected-institution')).is_group
      ) {
        this.setState({
          modalVisibleInstitutions: true,
          checkInstituionRoute: 'pre',
        });
      } else {
        this.props.history.push({
          pathname: pathRoutes.newSchedulePre,
        });
      }
    } else {
      mixPanelService.tracking(mixPanelService.MAPA_ADD_TRANS);
      if (
        JSON.parse(localStorage.getItem('selected-institution'))
        && JSON.parse(localStorage.getItem('selected-institution')).is_group
      ) {
        this.setState({
          modalVisibleInstitutions: true,
          checkInstituionRoute: 'schedule',
        });
      } else {
        this.props.history.push({
          pathname: pathRoutes.newSchedule,
        });
      }
    }
  };

  setTableImport = (table) => {
    const tableName = table === 'surgical-map'
      ? 'Procedimentos cirúrgicos'
      : 'Consultas pré-anestésicas';
    this.setState({
      importConditionTable: table,
      importConditionTableName: tableName,
    });
  };

  setMixPanel = (e) => {
    if (e.target.value == 1) {
      mixPanelService.tracking(mixPanelService.MAPA_SELECIONAR_PRE);
    } else {
      mixPanelService.tracking(mixPanelService.MAPA_SELECIONAR_TRANS);
    }
  };

  setMixPanelScheduling = () => {
    mixPanelService.tracking(mixPanelService.MAPA_ABRIR_AGENDAMENTO);
  };

  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    const { getFieldDecorator } = this.props.form;
    const { scheduling } = this.props;

    const { cancellation_reason } = this.props.selects;

    const { modalProcedure } = this.state;

    const totalPreAnesthetics = this.state.dataPreAnesthetics.length
      ? this.state.dataPreAnesthetics.reduce((a, b) => a + b.data.length, 0)
      : 0;
    const totalSurgeries = this.state.dataSurgeries.length
      ? this.state.dataSurgeries.reduce((a, b) => a + b.data.length, 0)
      : 0;

    const menuAdd = (
      <Menu onClick={(e) => this.routeProcedurePre(e.key)}>
        <Menu.Item key="pre">
          <Icon component={IconTabPreSvg} />
          &nbsp;&nbsp;PRÉ-ANESTÉSICO&nbsp;&nbsp;
        </Menu.Item>
        <Menu.Item key="schedule">
          <Icon component={IconTabProcedureSvg} />
          &nbsp;&nbsp;PROCEDIMENTO&nbsp;&nbsp;
        </Menu.Item>
      </Menu>
    );

    return (
      <Content>
        <Row>
          <Col xs={24} lg={24} sm={24} xl={0}>
            <Dropdown overlay={menuAdd} onVisibleChange={this.addArrowChange}>
              <Button
                type="primary"
                size="large"
                shape="circle"
                className="add-procedure"
              >
                <Icon type="plus" fill="#ffffff" />
              </Button>
            </Dropdown>
          </Col>

          <Col xs={24} lg={24} sm={24} xl={16}>
            <h1 className="title">Agendamentos</h1>
          </Col>
          <Col xs={0} lg={0} sm={0} xl={8}>
            <div style={{ float: 'right' }}>
              <ImportExcel
                table={this.state.importConditionTable}
                type="map"
                title={this.state.importConditionTableName}
                setTableImport={this.setTableImport}
                reload={this.loadMap}
              />
              <Dropdown overlay={menuAdd} onVisibleChange={this.addArrowChange}>
                <Button type="primary" shape="round" className="add-procedure">
                  ADICIONAR &nbsp;
                  <Icon type={this.state.addDropdownArrow} />
                </Button>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <Row>
          <Row>
            <Col xs={22} lg={22} sm={22} x={22}>
              <Search
                placeholder="Buscar por paciente ou CPF"
                disabled={this.state.loadingSearch}
                onChange={this.SearchScheduling}
                style={{ width: '100%' }}
              />
            </Col>
            {!this.state.is_group ? (
              <Col xs={2} lg={2} sm={2} x={2}>
                <Button
                  onClick={this.openFiltroModal}
                  style={{ color: '#1BBFBB' }}
                >
                  <Icon type="filter" />
                </Button>

              </Col>
            ) : (<></>)}
          </Row>
          <Modal
            title="Filtros"
            visible={this.state.modalFiltroVisible}
            onCancel={this.closeFiltroModal}
          >

            <Form layout="vertical">
              <Row gutter={16}>
                {' '}
                {/* Espaçamento entre colunas */}
                <Col span={12}>
                  {' '}
                  {/* Metade da largura da linha */}
                  <Form.Item label="Setor">

                    <Select
                      onChange={this.onSelectSurgeryCenter}
                      placeholder="Selecione um Setor"
                      value={this.state.filterTemp.selectedSurgeryCenter}
                    >
                      <Option key="none" value={null}>&nbsp;</Option>
                      {this.props.sectors && this.props.sectors.data && this.props.sectors.data.map((center) => (

                        <Option key={center.id} value={center.id}>{center.name}</Option>
                      ))}

                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {' '}
                  {/* Metade da largura da linha */}
                  <Form.Item label="Sala">
                    <Select
                      disabled={!this.state.filterTemp.selectedSurgeryCenter}
                      placeholder="Selecione uma Sala"
                      value={this.state.filterTemp.selectedSurgeryCenterRoom}
                      onChange={this.onSelectSurgeryCenterRoom}
                    >
                      <Option key="none" value={null}>&nbsp;</Option>
                      {
    this.state.surgeryCenterRooms.map((room) => (
      <Option key={room.id} value={room.id}>{room.name}</Option>
    ))
}
                    </Select>

                  </Form.Item>
                </Col>
              </Row>

            </Form>
            <Row>
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button
                  type="primary"
                  style={{ marginTop: '20px' }}
                  onClick={this.handleFilter}
                >
                  Filtrar resultados
                </Button>
              </Col>
            </Row>
          </Modal>

          <Row style={{ marginTop: 10 }}>
            <Col span={24}>
              {!this.state.is_group ? (<span>Filtros aplicados:</span>) : (<></>)}
              {this.state.selectedSurgeryCenter && (
              <Tag
                closable
                onClose={() => this.removeFilter('selectedSurgeryCenter')}
              >
                {this.getFilterName(this.state.selectedSurgeryCenter, 'sectors')}
              </Tag>
              )}

              {this.state.selectedSurgeryCenterRoom && (
              <Tag
                closable
                onClose={() => this.removeFilter('selectedSurgeryCenterRoom')}
              >
                {this.getFilterName(this.state.selectedSurgeryCenterRoom, 'rooms')}
              </Tag>
              )}
              {(this.state.selectedSurgeryCenter || this.state.selectedSurgeryCenterRoom) && (

              <span
                style={{
                  color: '#1BBFBB',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  this.removeFilter('selectedSurgeryCenter');
                  this.removeFilter('selectedSurgeryCenterRoom');
                }}
              >
                Limpar todos
              </span>
              )}
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <Col xs={24} lg={24} sm={24} xl={0} style={{ paddingRight: 10 }}>
              {!this.state.is_search && <FilterDate />}
            </Col>

            <Col xs={24} lg={24} sm={24} xl={18}>
              <Radio.Group
                defaultValue="1"
                buttonStyle="solid"
                onChange={(e) => {
                  this.setMixPanel(e);
                  this.setState({ keyTab: e.target.value });
                }}
              >
                <Radio.Button className="group-pre" value="1">
                  &nbsp;&nbsp;
                  {' '}
                  <Icon component={IconTabPreSvg} />
                  {' '}
                  PRÉ-ANESTÉSICOS &nbsp;
                  {' '}
                  <Badge
                    count={totalPreAnesthetics}
                    style={{
                      backgroundColor: '#fff',
                      color: '#999',
                      boxShadow: '0 0 0 1px #d9d9d9 inset',
                    }}
                  />
                  {' '}
                  &nbsp;
                  {' '}
                </Radio.Button>
                <Radio.Button className="group-surgery" value="2">
                  &nbsp;&nbsp;
                  {' '}
                  <Icon component={IconTabProcedureSvg} />
                  {' '}
                  PROCEDIMENTOS &nbsp;
                  {' '}
                  <Badge
                    count={totalSurgeries}
                    style={{
                      backgroundColor: '#fff',
                      color: '#999',
                      boxShadow: '0 0 0 1px #d9d9d9 inset',
                    }}
                  />
                  &nbsp;
                </Radio.Button>
              </Radio.Group>
            </Col>
            <Col xs={0} lg={0} sm={0} xl={6} style={{ paddingRight: 10 }}>
              {!this.state.is_search && <FilterDate />}
            </Col>
          </Row>

          <Row>
            <Tabs className="tab-map" activeKey={this.state.keyTab}>
              <TabPane tab="" key="1">
                {this.state.dataLoading ? (
                  <Skeleton active paragraph={{ rows: 8 }} avatar />
                ) : (
                  <div>
                    {this.state.dataPreAnesthetics.length ? (
                      this.state.dataPreAnesthetics.map((item) => (
                        <div key={item.date}>
                          <p className="title-time-line">
                            {this.checkDay(moment(item.date))}
                          </p>

                          {item.data.length ? (
                            <Timeline style={{ padding: 10 }}>
                              {item.data
                                .sort((a, b) => {
                                  if (a.pre_probable_date.substring(0, 16) > b.pre_probable_date.substring(0, 16)) {
                                    console.log('aquii', a.pre_probable_date.substring(0, 16));
                                    return 1;
                                  }
                                  if (b.pre_probable_date.substring(0, 16) > a.pre_probable_date.substring(0, 16)) {
                                    console.log('bquii', b.pre_probable_date.substring(0, 16));
                                    return -1;
                                  }
                                  if (a.patient_name < b.patient_name) {
                                    console.log('aname', a.patient_name);
                                    return -1;
                                  }
                                  if (a.patient_name > b.patient_name) {
                                    console.log('aname', b.patient_name);
                                    return 1;
                                  }
                                  return 0;
                                })
                                .map((procedure) => (
                                  <Timeline.Item
                                    key={procedure.id}
                                    dot={this.renderStatusScheduling(
                                      'pre',
                                      'icon',
                                      procedure,
                                    )}
                                  >
                                    <Card
                                      className="margin-body-time-line card-pre"
                                      onClick={() => {
                                        this.setMixPanelScheduling();
                                        this.openModal(procedure, 'pre');
                                      }}
                                    >
                                      <div className="scheduling-list ant-list-item-time-line">
                                        <div className="ant-list-item-meta">
                                          <div className="ant-list-item-meta-avatar pre">
                                            <span className="hour">
                                              {moment(
                                                procedure.pre_probable_date,
                                              ).format('HH:mm')}
                                            </span>
                                          </div>
                                          <div className="ant-list-item-meta-content">
                                            <h4 className="ant-list-item-meta-title">
                                              {stringsHelp.formatName(
                                                procedure.patient_preferred_name
                                                 || procedure.patient_name,
                                              )}
                                              {procedure.institution || procedure.clinic_name ? (
                                                <span>
                                                      &nbsp;&nbsp;
                                                  <Tag
                                                    className="tag-custom-round"
                                                    color="rgb(221, 246, 246)"
                                                    style={{
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    {' '}
                                                    <Icon type="home" />
                                                        &nbsp;&nbsp;
                                                    <span>
                                                      {' '}
                                                      {stringsHelp.maxLength(
                                                        stringsHelp.formatName(
                                                          procedure.clinic_name
                                                            ? procedure.clinic_name
                                                            : procedure
                                                              .institution
                                                              .name,
                                                        ),
                                                        40,
                                                        true,
                                                      )}
                                                    </span>
                                                  </Tag>
                                                </span>
                                              ) : (
                                                ''
                                              )}
                                            </h4>
                                            <div className="ant-list-item-meta-description">
                                              <div>{procedure.name}</div>
                                            </div>
                                          </div>
                                        </div>

                                        {
                                       procedure.documento_clinico ? (
                                         <div
                                           style={{
                                             position: 'absolute',
                                             right: 65,
                                             top: 6,
                                           }}
                                         >
                                           {procedure.documento_clinico
                                             .error ? this.isProcedureDone('pre', procedure.id) && (
                                               <Tooltip
                                                 placement="left"
                                                 title={(
                                                   <span>
                                                     Falha na integração:
                                                     {' '}
                                                     {
                                                          procedure
                                                            .documento_clinico
                                                            .error.value
                                                        }
                                                     {' '}
                                                   </span>
                                                    )}
                                               >
                                                 <Tag
                                                   color="red"
                                                   style={{
                                                     fontSize: 11,
                                                     border: 'none',
                                                     padding:
                                                          '0 10px 0px 10px',
                                                   }}
                                                 >
                                                   <Icon type="arrows-alt" />
                                                   {' '}
                                                   PDF
                                                   {' '}
                                                 </Tag>
                                               </Tooltip>
                                             ) : this.isProcedureDone('pre', procedure.id) && (
                                               <Tag
                                                 color="green"
                                                 style={{
                                                   fontSize: 11,
                                                   border: 'none',
                                                   padding: '0 10px 0px 10px',
                                                 }}
                                               >
                                                 <Icon type="arrows-alt" />
                                                 {' '}
                                                 PDF
                                                 {' '}
                                               </Tag>
                                             )}
                                         </div>
                                       ) : (
                                         ''
                                       )
}

                                        <div className="details-map">
                                          <div className="status-scheduling">
                                            {this.renderStatusScheduling(
                                              'pre',
                                              'label',
                                              procedure,
                                            )}
                                          </div>
                                          {procedure.owner && (
                                            <Avatar>
                                              {stringsHelp.avatar(
                                                procedure.owner,
                                              )}
                                            </Avatar>
                                          )}
                                        </div>
                                      </div>
                                    </Card>
                                  </Timeline.Item>
                                ))}
                            </Timeline>
                          ) : (
                            <Card>
                              <Empty />
                            </Card>
                          )}
                        </div>
                      ))
                    ) : (
                      <Card>
                        <Empty />
                      </Card>
                    )}
                  </div>
                )}
              </TabPane>
              <TabPane tab="" key="2">
                {this.state.dataLoading ? (
                  <Skeleton active paragraph={{ rows: 8 }} avatar />
                ) : (
                  <div>
                    {this.state.dataSurgeriesRealTime.length
                      ? this.state.dataSurgeriesRealTime.map((procedure) => (
                        <Card
                          className="margin-body-time-line card-surgery-realtime"
                          onClick={() => {
                            this.setMixPanelScheduling();
                            this.openModal(procedure, 'trans');
                          }}
                        >
                          <p className="title-surgery-realtime">
                            <Badge status="processing" />
                            {' '}
                            Procedimento em
                            andamento
                          </p>
                          <div className="scheduling-list ant-list-item-time-line">
                            <div className="ant-list-item-meta">
                              <div className="ant-list-item-meta-avatar realtime">
                                <span className="hour">
                                  <Timer initialTime={procedure.duration}>
                                    {() => (
                                      <>
                                        <Timer.Hours
                                          formatValue={(value) => `${
                                            value < 10
                                              ? `0${value}`
                                              : value
                                          }`}
                                        />
                                        :
                                        <Timer.Minutes
                                          formatValue={(value) => `${
                                            value < 10
                                              ? `0${value}`
                                              : value
                                          }`}
                                        />
                                        :
                                        <Timer.Seconds
                                          formatValue={(value) => `${
                                            value < 10
                                              ? `0${value}`
                                              : value
                                          }`}
                                        />
                                      </>
                                    )}
                                  </Timer>
                                </span>
                              </div>
                              <div className="ant-list-item-meta-content">
                                <h4 className="ant-list-item-meta-title">
                                  {stringsHelp.formatName(
                                    procedure.patient_name,
                                  )}
                                  {procedure.institution ? (
                                    <span>
                                          &nbsp;&nbsp;
                                      <Tag
                                        className="tag-custom-round"
                                        color="rgb(221, 246, 246)"
                                      >
                                        {' '}
                                        <Icon type="home" />
                                            &nbsp;&nbsp;
                                        <span>
                                          {' '}
                                          {stringsHelp.maxLength(
                                            stringsHelp.formatName(
                                              procedure.institution.name,
                                            ),
                                            40,
                                            true,
                                          )}
                                        </span>
                                      </Tag>
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </h4>
                                <div className="ant-list-item-meta-description">
                                  <div>{procedure.name}</div>
                                </div>
                              </div>
                            </div>
                            <div className="details-map">
                              {procedure.owner && (
                              <Avatar>
                                {stringsHelp.avatar(procedure.owner)}
                              </Avatar>
                              )}
                            </div>
                          </div>
                        </Card>
                      ))
                      : ''}

                    {this.state.dataSurgeries.length ? (
                      this.state.dataSurgeries.map((item) => (
                        <div key={item.date}>
                          <p className="title-time-line">
                            {this.checkDay(moment(item.date))}
                          </p>

                          {item.data.length ? (
                            <Timeline style={{ padding: 10 }}>
                              {item.data
                                .sort((a, b) => {
                                  if (a.surgery_probable_date.substring(0, 16) > b.surgery_probable_date.substring(0, 16)) {
                                    return 1;
                                  }
                                  if (b.surgery_probable_date.substring(0, 16) > a.surgery_probable_date.substring(0, 16)) {
                                    return -1;
                                  }
                                  if (a.patient_name < b.patient_name) {
                                    return -1;
                                  }
                                  if (a.patient_name > b.patient_name) {
                                    return 1;
                                  }
                                  return 0;
                                })
                                .map((procedure) => (
                                  <Timeline.Item
                                    key={procedure.id}
                                    dot={this.renderStatusScheduling(
                                      'procedure',
                                      'icon',
                                      procedure,
                                    )}
                                  >
                                    <Card
                                      className="margin-body-time-line card-surgery"
                                      onClick={() => this.openModal(procedure, 'trans')}
                                    >
                                      <div className="scheduling-list ant-list-item-time-line">
                                        <div className="ant-list-item-meta">
                                          <div className="ant-list-item-meta-avatar">
                                            <span className="hour">
                                              {moment(
                                                procedure.surgery_probable_date,
                                              ).format('HH:mm')}
                                            </span>
                                          </div>
                                          <div className="ant-list-item-meta-content">
                                            <h4 className="ant-list-item-meta-title">
                                              {stringsHelp.formatName(
                                                procedure.patient_preferred_name
                                                 || procedure.patient_name,
                                              )}
                                              {procedure.institution ? (
                                                <span>
                                                      &nbsp;&nbsp;
                                                  <Tag
                                                    className="tag-custom-round"
                                                    color="rgb(221, 246, 246)"
                                                    style={{
                                                      marginBottom: 3,
                                                    }}
                                                  >
                                                    {' '}
                                                    <Icon type="home" />
                                                        &nbsp;&nbsp;
                                                    <span>
                                                      {' '}
                                                      {stringsHelp.maxLength(
                                                        stringsHelp.formatName(
                                                          procedure
                                                            .institution
                                                            .name,
                                                        ),
                                                        40,
                                                        true,
                                                      )}
                                                    </span>
                                                  </Tag>
                                                </span>
                                              ) : (
                                                ''
                                              )}
                                            </h4>
                                            <div className="ant-list-item-meta-description">
                                              <div>{procedure.name}</div>
                                            </div>
                                          </div>
                                        </div>

                                        {procedure.documento_clinico ? (
                                          <div
                                            style={{
                                              position: 'absolute',
                                              right: 65,
                                              top: 6,
                                            }}
                                          >
                                            {procedure.documento_clinico
                                              .error ? this.isProcedureDone('procedure', procedure.id) && (
                                                <Tooltip
                                                  placement="left"
                                                  title={(
                                                    <span>
                                                      Falha na integração:
                                                      {' '}
                                                      {
                                                          procedure
                                                            .documento_clinico
                                                            .error.value
                                                        }
                                                      {' '}
                                                    </span>
                                                    )}
                                                >
                                                  <Tag
                                                    color="red"
                                                    style={{
                                                      fontSize: 11,
                                                      border: 'none',
                                                      padding:
                                                          '0 10px 0px 10px',
                                                    }}
                                                  >
                                                    <Icon type="arrows-alt" />
                                                    {' '}
                                                    PDF
                                                    {' '}
                                                  </Tag>
                                                </Tooltip>
                                              ) : this.isProcedureDone('procedure', procedure.id) && (
                                                <Tag
                                                  color="green"
                                                  style={{
                                                    fontSize: 11,
                                                    border: 'none',
                                                    padding: '0 10px 0px 10px',
                                                  }}
                                                >
                                                  <Icon type="arrows-alt" />
                                                  {' '}
                                                  PDF
                                                  {' '}
                                                </Tag>
                                              )}
                                          </div>
                                        ) : (
                                          ''
                                        )}

                                        <div className="details-map">
                                          <div className="status-scheduling">
                                            {this.renderStatusScheduling(
                                              'procedure',
                                              'label',
                                              procedure,
                                            )}
                                          </div>
                                          {procedure.owner && (
                                            <Avatar>
                                              {stringsHelp.avatar(
                                                procedure.owner,
                                              )}
                                            </Avatar>
                                          )}
                                        </div>
                                      </div>
                                    </Card>
                                  </Timeline.Item>
                                ))}
                            </Timeline>
                          ) : (
                            <Card>
                              <Empty />
                            </Card>
                          )}
                        </div>
                      ))
                    ) : (
                      <Card>
                        <Empty />
                      </Card>
                    )}
                  </div>
                )}
              </TabPane>
            </Tabs>
          </Row>
        </Row>

        <ModalCheckInstitution
          visible={this.state.modalVisibleInstitutions}
          route={this.state.checkInstituionRoute}
          setVisible={this.setModalVisibleCheckInstitution}
        />

        <Modal
          title="Agendamento"
          visible={this.state.modalVisible}
          onCancel={this.handleCancel}
          onOk={() => (modalProcedure && modalProcedure.user_id > 1
            ? null
            : this.handleOk(modalProcedure.id))}
          buttonOkText={
            modalProcedure && modalProcedure.user_id > 1 ? '' : 'EDITAR'
          }
          buttonOkType="secondary"
        >
          {modalProcedure ? (
            <Row>
              <Col span={24} className="modal-scheduling-view">
                <Col span={24} className="modal-scheduling-view-title">
                  <span>Paciente</span>
                  {modalProcedure.cancellation_date && (
                    <span style={{ float: 'right' }}>
                      {this.renderStatusScheduling(
                        'pre',
                        'label',
                        modalProcedure,
                      )}
                    </span>
                  )}
                </Col>
                {modalProcedure.patient.preferred_name ? (
                  <Col span={24} className="modal-scheduling-view-item">
                    <span className="modal-scheduling-view-item-title">NOME SOCIAL</span>
                    <span>
                      {stringsHelp.formatName(modalProcedure.patient.preferred_name)}
                    </span>
                  </Col>
                ) : (<></>)}
                <Col span={24} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">NOME CIVIL</span>
                  <span>
                    {stringsHelp.formatName(modalProcedure.patient.name)}
                  </span>
                </Col>
                <Col xs={24} sm={8} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">CPF</span>
                  <span>
                    {modalProcedure.patient.cpf
                      ? modalProcedure.patient.cpf
                      : '-'}
                  </span>
                </Col>
                <Col xs={24} sm={16} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    IDADE
                  </span>
                  <span>
                    {modalProcedure.patient.birth_date
                      ? dates.age(
                        moment(modalProcedure.patient.birth_date).format(
                          'DD/MM/YYYY',
                        ),
                      )
                      : '-'}
                  </span>
                </Col>
                <Col xs={24} sm={16} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    ALERGIAS
                  </span>
                  <span>
                    {modalProcedure.patient.allergies
                      ? modalProcedure.patient.allergies
                      : '-'}
                  </span>
                </Col>
              </Col>
              <Col span={24} className="modal-scheduling-view">
                <Col span={24} className="modal-scheduling-view-title">
                  <span>Local</span>
                </Col>
                <Col span={24} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    INSTITUIÇÃO
                  </span>
                  <span>
                    {stringsHelp.formatName(
                      this.state.modalType === 'pre'
                        ? (modalProcedure.clinic ? modalProcedure.clinic.name : modalProcedure.institution.name)
                        : (modalProcedure.institution ? modalProcedure.institution.name : '-'),
                      true,
                    )}
                  </span>
                </Col>

                {
                      this.state.modalType === 'trans' && (
                        <>
                          <Col xs={24} sm={8} className="modal-scheduling-view-item">
                            <span className="modal-scheduling-view-item-title">
                              CENTRO CIRÚRGICO
                            </span>
                            <span>
                              {modalProcedure.surgery_center
                                ? modalProcedure.surgery_center.name
                                : '-'}
                            </span>
                          </Col>
                          <Col xs={24} sm={8} className="modal-scheduling-view-item">
                            <span className="modal-scheduling-view-item-title">SALA</span>
                            <span>
                              {modalProcedure.surgery_center_room
                                ? modalProcedure.surgery_center_room.name
                                : '-'}
                            </span>
                          </Col>
                          <Col xs={24} sm={8} className="modal-scheduling-view-item">
                            <span className="modal-scheduling-view-item-title">
                              ACOMODAÇÃO
                            </span>
                            <span>
                              {modalProcedure.room
                                ? modalProcedure.room.room_i18n[0].name
                                : '-'}
                            </span>
                          </Col>
                          <Col span={24} className="modal-scheduling-view">
                            <Col span={24} className="modal-scheduling-view-title">
                              <span>Atendimento</span>
                            </Col>
                            <Col xs={24} sm={8} className="modal-scheduling-view-item">
                              <span className="modal-scheduling-view-item-title">
                                Nº DE PRONTUÁRIO
                              </span>
                              <span>
                                {modalProcedure.medical_record_number
                                  ? modalProcedure.medical_record_number
                                  : '-'}
                              </span>
                            </Col>
                            <Col xs={24} sm={16} className="modal-scheduling-view-item">
                              <span className="modal-scheduling-view-item-title">
                                Nº DE ATENDIMENTO
                              </span>
                              <span>
                                {modalProcedure.register_number
                                  ? modalProcedure.register_number
                                  : '-'}
                              </span>
                            </Col>
                            <Col xs={24} sm={8} className="modal-scheduling-view-item">
                              <span className="modal-scheduling-view-item-title">
                                CONVÊNIO(S)
                              </span>
                              <span>
                                {modalProcedure.procedure_medical_plans
                                  && modalProcedure.procedure_medical_plans.length > 0
                                  ? modalProcedure.procedure_medical_plans.map((i) => (i.medical_plan_id !== null ? i.medical_plan.name : '-')).join(', ')
                                  : (modalProcedure.medical_plan
                                    && modalProcedure.medical_plan.length > 0 ? modalProcedure.medical_plan.name : '-')}
                              </span>
                            </Col>
                            <Col xs={24} sm={8} className="modal-scheduling-view-item">
                              <span className="modal-scheduling-view-item-title">
                                MATRÍCULA(S) CONVÊNIO(S)
                              </span>
                              <span>
                                {modalProcedure.procedure_medical_plans
                                  && modalProcedure.procedure_medical_plans.length > 0
                                  && modalProcedure.procedure_medical_plans
                                    .filter((i) => i.patient_medical_plan_code).length > 0
                                  ? modalProcedure.procedure_medical_plans.map((i) => i.patient_medical_plan_code).join(', ')
                                  : modalProcedure.patient_medical_plan_code ? modalProcedure.patient_medical_plan_code : '-'}
                              </span>
                            </Col>

                            <Col xs={24} sm={8} className="modal-scheduling-view-item">
                              <span className="modal-scheduling-view-item-title">
                                SENHA AUTORIZAÇÃO
                              </span>
                              <span>
                                {modalProcedure.procedure_medical_plans
                                  && modalProcedure.procedure_medical_plans.length > 0
                                  && modalProcedure.procedure_medical_plans
                                    .filter((i) => i.password).length > 0
                                  ? modalProcedure.procedure_medical_plans.map((i) => i.password).join(', ')
                                  : modalProcedure.patient_medical_plan_password ? modalProcedure.patient_medical_plan_password : '-'}
                              </span>
                            </Col>
                          </Col>

                          <Col span={24} className="modal-scheduling-view">
                            <Col span={24} className="modal-scheduling-view-title">
                              <span>Cirurgias</span>
                            </Col>
                          </Col>
                          <Col span={24} className="modal-scheduling-view-item">
                            <span className="modal-scheduling-view-item-title">
                              TIPO DE CIRURGIA
                            </span>
                            <span>
                              {modalProcedure.surgery_type
                                ? modalProcedure.surgery_type.surgery_type_i18n[0].name
                                : '-'}
                            </span>
                          </Col>
                          <Col span={24} className="modal-scheduling-view-item">
                            <span className="modal-scheduling-view-item-title">
                              CIRURGIAS
                            </span>
                            {modalProcedure.laterality_procedures.length ? (
                              modalProcedure.laterality_procedures.map((laterality) => {
                                const procedureName = stringsHelp.firstLetterUpper(
                                  laterality.surgical_procedure.name,
                                );
                                return (
                                  <span key={laterality.id}>
                                    {laterality.surgical_procedure.code}
                                    {' '}
                                    -
                                    {procedureName}
                                    {' '}
                                    -
                                    {' '}
                                    {laterality.laterality}
                                  </span>
                                );
                              })
                            ) : (
                              <span key={modalProcedure.id}>{modalProcedure.name}</span>
                            )}
                          </Col>
                        </>
                      )
                    }
              </Col>
              <Col span={24} className="modal-scheduling-view">
                <Col span={24} className="modal-scheduling-view-title">
                  <span>Equipe</span>
                </Col>
                {this.state.modalType === 'trans' && (
                  <Col span={24} className="modal-scheduling-view-item">
                    <span className="modal-scheduling-view-item-title">
                      CIRURGIÕES
                    </span>
                    <span>
                      {this.state.modalProcedure.active_surgeons.length
                        ? this.state.modalProcedure.active_surgeons
                          .map((surgeon) => (
                            `Dr(a). ${
                              stringsHelp.formatName(surgeon.surgeon.name, true)}`
                          ))
                          .join(',')
                        : '-'}
                    </span>
                  </Col>
                )}
                <Col span={24} className="modal-scheduling-view-item">
                  <span className="modal-scheduling-view-item-title">
                    ANESTESIOLOGISTAS
                  </span>
                  {this.state.modalProcedure.active_anaesthetists.length ? (
                    this.state.modalProcedure.active_anaesthetists.filter((v) => {
                      if (this.state.modalType === 'trans') {
                        return !!v.is_trans;
                      }

                      return !!v.is_pre;
                    }).map(
                      (anaesthetist) => (
                        <span
                          key={anaesthetist.id}
                          style={{ padding: '5px 0px 5px 0px' }}
                        >
                          <Avatar
                            style={{ width: '30px', marginRight: '9px' }}
                          >
                            {stringsHelp.avatar(
                              anaesthetist.anaesthetist.name,
                            )}
                          </Avatar>
                          {`Dr(a). ${
                            stringsHelp.formatName(
                              anaesthetist.anaesthetist.name,
                              true,
                            )}`}
                          {/* <ButtonFixAnaesthetist
                            procedureId={modalProcedure.id}
                            anestesistaId={anaesthetist.anaesthetist_id}
                          /> */}
                        </span>
                      ),
                    )
                  ) : (
                    <span>-</span>
                  )}
                </Col>
                <Col span={24} className="modal-scheduling-view">
                  <Col span={24} className="modal-scheduling-view-title">
                    <span>Exames</span>
                  </Col>
                  <Col span={24} className="modal-scheduling-view-item">
                    <span className="modal-scheduling-view-item-title">
                      ARQUIVOS DE EXAMES
                    </span>
                  </Col>
                  {/* <FormItem>
                      <Row>

                      </Row>
                    </FormItem> */}
                  <FormItem>
                    <div style={{ margin: 8 }} />
                    <Row>
                      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Upload
                          multiple="true"
                          onRemove={(file) => {
                            this.setState((previousState) => ({
                              pacientExams: previousState.pacientExams
                                .filter((item) => item !== file),
                            }));
                          }}
                          beforeUpload={(file) => {
                            this.setState((previousState) => ({
                              pacientExams: [...previousState.pacientExams, file],
                            }));
                            return false;
                          }}
                          fileList={this.state.pacientExams}
                        >
                          <Tooltip placement="right" title="Apenas formatos PNG, JPG, JPEG e PDF">
                            <button type="button" className="form-add-link-exams">
                              + Adicionar arquivos
                            </button>
                          </Tooltip>
                          <div className="ant-upload-list-item-card-actions" />
                        </Upload>
                        <Col span={24} className="modal-scheduling-view-item">
                          <span className="modal-scheduling-view-item-title">
                            DATA DOS RESULTADOS
                          </span>
                        </Col>
                        <DatePicker
                          style={{ width: '60%' }}
                          value={this.state.rawResultDate}
                          onChange={(a) => {
                            if (a) {
                              this.setState({ rawResultDate: a });
                              const data = moment(a._d).format('YYYY-MM-DD HH:mm:ss');
                              this.setState({ examsResultDate: data });
                            } else {
                              this.state.rawResultDate = '';
                            }
                          }}
                        />

                        {'  '}
                        <Button onClick={() => this.handleUploadExams(this.state.examsResultDate, this.state.pacientExams)}>
                          <Icon type="primary" />

                          Fazer upload
                        </Button>
                      </Col>
                    </Row>

                  </FormItem>
                  <FormItem>
                    {this.state.documentsOcr.length > 0 && (
                      <Row>
                        <Col span={24} className="modal-scheduling-view-title">
                          <span>Fila de processamento de exames</span>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} className="modal-scheduling-view-item">
                          <span className="modal-scheduling-view-item-title">
                            Status dos arquivos submetidos
                          </span>
                          <Row>
                            {this.state.documentsOcr.map((i) => {
                              const isDone = i.status === 3;
                              const formatedUpdatedAt = moment(i.updated_at).format('DD/MM/YYYY [às] HH:mm:ss');
                              const formatedCreatedAt = moment(i.created_at).format('DD/MM/YYYY [às] HH:mm:ss');
                              const tooltipTitle = isDone ? `Processamento finalizado em ${formatedUpdatedAt}` : `Aguardando processamento. Upload  feito em ${formatedCreatedAt}`;
                              return (
                                <Tooltip placement="right" title={tooltipTitle}>
                                  <Row>
                                    <div>
                                      <span className={`${isDone ? 'modal-scheduling-view-item-queue-done' : 'modal-scheduling-view-item-queue-waiting'}`}>
                                        {`${i.original_filename}`}
                                  &nbsp;
                                        {' '}
                                        {isDone ? <Icon type="check-circle" /> : <Icon type="clock-circle" twoToneColor="#1BBFBB" />}
                                      </span>
                                    </div>
                                  </Row>
                                </Tooltip>
                              );
                            })}
                          </Row>
                        </Col>
                      </Row>

                    )}
                  </FormItem>
                </Col>

                {this.state.modalProcedure.cancellation_date && (
                  <Col span={24} className="modal-scheduling-view">
                    <Col
                      xs={24}
                      sm={12}
                      className="modal-scheduling-view-item"
                      style={{ color: '#df4279' }}
                    >
                      <span className="modal-scheduling-view-item-title">
                        DATA DE CANCELAMENTO
                      </span>
                      <span>
                        {this.state.modalProcedure.cancellation_date
                          ? moment(modalProcedure.cancellation_date).format(
                            'DD/MM/YYYY',
                          )
                          : '-'}
                      </span>
                    </Col>
                    <Col
                      xs={24}
                      sm={12}
                      className="modal-scheduling-view-item"
                      style={{ marginTop: 17, color: '#df4279' }}
                    >
                      <span className="modal-scheduling-view-item-title">
                        MOTIVO DE CANCELAMENTO
                      </span>
                      <span>
                        {this.state.modalProcedure
                          .cancellation_reason_description
                          ? this.state.modalProcedure
                            .cancellation_reason_description
                          : this.state.modalProcedure.cancellation_reason
                            .cancellation_reason_i18n[0].description}
                      </span>
                    </Col>
                  </Col>
                )}

                {this.state.modalType === 'pre'
                  && (!this.state.modalProcedure.cancellation_date
                    && !this.state.modalProcedure.create_pre_date
                    && this.state.modalProcedure.user_id === 1) && (
                    <Col
                      span={24}
                      style={{ textAlign: 'center', marginTop: 5 }}
                    >

                      <Popover
                        visible={this.state.popoverCancellationVisible}
                        onVisibleChange={(e) => this.setState({ popoverCancellationVisible: e })}
                        content={(
                          <div style={{ padding: 5, width: 510 }}>
                            <Form
                              onSubmit={this.handleSubmitCancellationReason}
                            >
                              <Row>
                                <Col span={24}>
                                  {!this.state.newCancellationReason ? (
                                    <FormItem label="MOTIVO">
                                      {getFieldDecorator(
                                        'cancellation_reason_id',
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                'Selecione o motivo do cancelamento',
                                            },
                                          ],
                                        },
                                      )(
                                        <Select
                                          showSearch
                                          filterOption={(input, option) => {
                                            if (
                                              typeof option.props.children
                                              !== 'string'
                                            ) {
                                              return false;
                                            }
                                            return (
                                              option.props.children
                                                .toLowerCase()
                                                .indexOf(
                                                  input.toLowerCase(),
                                                ) >= 0
                                            );
                                          }}
                                          style={{ width: 500 }}
                                          loading={
                                            cancellation_reason
                                              ? cancellation_reason.loading
                                              : true
                                          }
                                        >
                                          {!cancellation_reason
                                            ? null
                                            : cancellation_reason.data.map(
                                              (item) => (
                                                <OptGroup
                                                  key={
                                                        item
                                                          .cancellation_reason_type_i18n[0]
                                                          .id
                                                      }
                                                  label={
                                                        item
                                                          .cancellation_reason_type_i18n[0]
                                                          .description
                                                      }
                                                >
                                                  {item.cancellation_reasons.map(
                                                    (d) => (
                                                      <Option
                                                        key={
                                                              d
                                                                .cancellation_reason_i18n[0]
                                                                .id
                                                            }
                                                        value={
                                                              d
                                                                .cancellation_reason_i18n[0]
                                                                .id
                                                            }
                                                      >
                                                        {
                                                              d
                                                                .cancellation_reason_i18n[0]
                                                                .description
                                                            }
                                                      </Option>
                                                    ),
                                                  )}
                                                </OptGroup>
                                              ),
                                            )}
                                        </Select>,
                                      )}
                                    </FormItem>
                                  ) : (
                                    <FormItem label="ADICIONAR OUTRO MOTIVO">
                                      {getFieldDecorator(
                                        'cancellation_reason_description',
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                'Digite o motivo do cancelamento',
                                            },
                                          ],
                                        },
                                      )(<Input />)}
                                    </FormItem>
                                  )}

                                  {!this.state.newCancellationReason ? (
                                    <Button
                                      style={{
                                        fontSize: 11,
                                        width: '100%',
                                        textAlign: 'left',
                                        fontWeight: 500,
                                        padding: '2px 11px',
                                      }}
                                      type="link"
                                      size="small"
                                      onClick={() => this.setState({
                                        newCancellationReason: true,
                                      })}
                                    >
                                      Adicionar outro motivo
                                    </Button>
                                  ) : (
                                    <Button
                                      style={{
                                        fontSize: 11,
                                        width: '100%',
                                        textAlign: 'left',
                                        fontWeight: 500,
                                        padding: '2px 11px',
                                      }}
                                      type="link"
                                      size="small"
                                      onClick={() => this.setState({
                                        newCancellationReason: false,
                                      })}
                                    >
                                      Selecionar motivo
                                    </Button>
                                  )}
                                </Col>

                                <Col span={24}>
                                  <FormItem label="">
                                    <Button
                                      type="primary"
                                      shape="round"
                                      className="add-procedure"
                                      htmlType={
                                        this.state.loadingCancellation
                                          ? 'button'
                                          : 'submit'
                                      }
                                      loading={this.state.loadingCancellation}
                                    >
                                      Confirmar
                                    </Button>
                                  </FormItem>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                        title=""
                        trigger="click"
                      >

                        <Button className="btn-custom-danger" shape="round">
                          Cancelar este procedimento
                        </Button>
                      </Popover>
                    </Col>
                )}

                {this.state.modalType === 'trans'
                  && !this.state.modalProcedure.cancellation_date
                  && !this.state.modalProcedure.procedure_closed_date
                  && this.state.modalProcedure.user_id === 1 && (
                    <Col
                      span={24}
                      style={{ textAlign: 'center', marginTop: 5 }}
                    >
                      <Popover
                        visible={this.state.popoverCancellationVisible}
                        onVisibleChange={(e) => this.setState({ popoverCancellationVisible: e })}
                        content={(
                          <div style={{ padding: 5, width: 510 }}>
                            <Form
                              onSubmit={this.handleSubmitCancellationReason}
                            >
                              <Row>
                                <Col span={24}>
                                  {!this.state.newCancellationReason ? (
                                    <FormItem label="MOTIVO">
                                      {getFieldDecorator(
                                        'cancellation_reason_id',
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                'Selecione o motivo do cancelamento',
                                            },
                                          ],
                                        },
                                      )(
                                        <Select
                                          showSearch
                                          filterOption={(input, option) => {
                                            if (
                                              typeof option.props.children
                                              !== 'string'
                                            ) {
                                              return false;
                                            }
                                            return (
                                              option.props.children
                                                .toLowerCase()
                                                .indexOf(
                                                  input.toLowerCase(),
                                                ) >= 0
                                            );
                                          }}
                                          style={{ width: 500 }}
                                          loading={
                                            cancellation_reason
                                              ? cancellation_reason.loading
                                              : true
                                          }
                                        >
                                          {!cancellation_reason
                                            ? null
                                            : cancellation_reason.data.map(
                                              (item) => (
                                                <OptGroup
                                                  key={
                                                        item
                                                          .cancellation_reason_type_i18n[0]
                                                          .id
                                                      }
                                                  label={
                                                        item
                                                          .cancellation_reason_type_i18n[0]
                                                          .description
                                                      }
                                                >
                                                  {item.cancellation_reasons.map(
                                                    (d) => (
                                                      <Option
                                                        key={
                                                              d
                                                                .cancellation_reason_i18n[0]
                                                                .id
                                                            }
                                                        value={
                                                              d
                                                                .cancellation_reason_i18n[0]
                                                                .id
                                                            }
                                                      >
                                                        {
                                                              d
                                                                .cancellation_reason_i18n[0]
                                                                .description
                                                            }
                                                      </Option>
                                                    ),
                                                  )}
                                                </OptGroup>
                                              ),
                                            )}
                                        </Select>,
                                      )}
                                    </FormItem>
                                  ) : (
                                    <FormItem label="ADICIONAR OUTRO MOTIVO">
                                      {getFieldDecorator(
                                        'cancellation_reason_description',
                                        {
                                          rules: [
                                            {
                                              required: true,
                                              message:
                                                'Digite o motivo do cancelamento',
                                            },
                                          ],
                                        },
                                      )(<Input />)}
                                    </FormItem>
                                  )}

                                  {!this.state.newCancellationReason ? (
                                    <Button
                                      style={{
                                        fontSize: 11,
                                        width: '100%',
                                        textAlign: 'left',
                                        fontWeight: 500,
                                        padding: '2px 11px',
                                      }}
                                      type="link"
                                      size="small"
                                      onClick={() => this.setState({
                                        newCancellationReason: true,
                                      })}
                                    >
                                      Adicionar outro motivo
                                    </Button>
                                  ) : (
                                    <Button
                                      style={{
                                        fontSize: 11,
                                        width: '100%',
                                        textAlign: 'left',
                                        fontWeight: 500,
                                        padding: '2px 11px',
                                      }}
                                      type="link"
                                      size="small"
                                      onClick={() => this.setState({
                                        newCancellationReason: false,
                                      })}
                                    >
                                      Selecionar motivo
                                    </Button>
                                  )}
                                </Col>

                                <Col span={24}>
                                  <FormItem label="">
                                    <Button
                                      type="primary"
                                      shape="round"
                                      className="add-procedure"
                                      htmlType={
                                        this.state.loadingCancellation
                                          ? 'button'
                                          : 'submit'
                                      }
                                      loading={this.state.loadingCancellation}
                                    >
                                      Confirmar
                                    </Button>
                                  </FormItem>
                                </Col>
                              </Row>
                            </Form>
                          </div>
                        )}
                        title=""
                        trigger="click"
                      >
                        <Button className="btn-custom-danger" shape="round">
                          Cancelar este procedimento
                        </Button>
                      </Popover>
                    </Col>
                )}
              </Col>
            </Row>
          ) : (
            <Row style={{ textAlign: 'center', padding: 20 }}>
              <Spin />
            </Row>
          )}
        </Modal>
        <Modal title="Enviando..." visible={this.state.examProcessingModalVisible} closable={false}>
          <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%',
          }}
          >
            <Spin />
          </div>
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { scheduling, institutions, selects } = state;
  return {
    scheduling,
    institutions,
    selects,
    sectors: state.sectors,
  };
}

const connected = connect(mapStateToProps)(Form.create()(Map));
export default connected;
