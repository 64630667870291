import {isTablet, isIOS, isMobileSafari} from 'react-device-detect';

export default () => {
  let fallbackLink = 'https://itunes.apple.com/br/app/axreg/id1279049888';
  if (isMobileSafari && isTablet && isIOS) {
    window.location.href = 'anestechAxregAppScheme://';
    window.setTimeout(function (){ window.location.replace(fallbackLink); }, 1);
  } else {
    window.location.href = fallbackLink;
  }
  return null;
}