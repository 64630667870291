import { List } from 'immutable';
import { institutionsConstants } from '../constants';

export function institutions(state = new List(), action) {
  switch (action.type) {
    case institutionsConstants.GETINSTITUTIONS_REQUEST:
      return { ...state, loading: true };

    case institutionsConstants.GETINSTITUTIONS_SUCCESS:
      return { ...state, loading: false, items: List(action.institutions) };

    case institutionsConstants.GETINSTITUTIONS_FAILURE:
      return { ...state, loading: false, error: action.error };

    case institutionsConstants.SELECT_INSTITUTION_REQUEST:
      return { ...state, loading: true };

    case institutionsConstants.SELECT_INSTITUTION_SUCCESS:
      return { ...state, loading: false, selected: action.institution };

    case institutionsConstants.SELECT_INSTITUTION_FAILURE:
      return { ...state, loading: false, error: action.error };

    case institutionsConstants.GETANSWER_QUESTION_REQUEST:
      return { ...state, loading: true };

    case institutionsConstants.GETANSWER_QUESTION_SUCCESS:
      return { ...state, loading: false, items: List(action.institutions) };

    case institutionsConstants.GETANSWER_QUESTION_FAILURE:
      return { ...state, loading: false, error: action.error };

    case institutionsConstants.INSTITUTION_NEW_LOGO:
      let logos = new List();
      if (state.logos) {
        logos = new List(state.logos);
      }
      logos = logos.set(action.institution.id, action.logo);

      return { ...state, loading: false, logos };
    default:
      return state;
  }
}
