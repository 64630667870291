/* eslint-disable camelcase */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { Checkbox, Input, InputNumber } from 'antd';
import { CancelToken } from 'axios';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { List } from 'immutable';
import Modal from './Modal';
import { stringsHelp } from '../helpers';
import { selectsActions } from '../actions';

class SelectSurgery extends Component {
  cancelSource = CancelToken.source();

  state = {
    modalVisible: false,
    selected: new List(),

    tracks: new List(),
    hasMoreItems: true,
  };

  page = 0;

  componentDidMount() {
    if (this.props.value) {
      this.changeSelected(new List(this.props.value));
    }
    this.loadSurgicalProceduresAll();
    this.loadSurgicalProcedures(1);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.medicalPlansFilter && prevProps.medicalPlansFilter) {
      if (this.props.value !== prevProps.value) {
        this.changeSelected(new List(this.props.value));
      }
      const removeSurgicalProcedure = await prevProps.medicalPlansFilter
        .filter((x) => !this.props.medicalPlansFilter.includes(x));

      if (prevProps.medicalPlansFilter.length === '') {
        this.changeSelected(new List());
        this.loadSurgicalProceduresAll();
      }
      if (removeSurgicalProcedure.size > 0) {
        await this.deselectSurgicalProcedureForMedicalPlan(removeSurgicalProcedure._tail.array[0]);
      }
    }
  }

  async setModalVisible(modalVisible) {
    this.setState({ modalVisible });
    await this.loadSurgicalProceduresAll();
  }

  loadSurgicalProceduresAll = async () => {
    const { dispatch } = this.props;
    dispatch(selectsActions.getSurgicalProceduresMostUsed(localStorage.getItem('medical-plans-table-id')));
    this.loadSurgicalProcedures(1);
  };

  loadSurgicalProcedures = async (page) => {
    const value = this.filter ? this.filter.input.value : '';
    const { dispatch } = this.props;

    this.page = page;

    this.searchingProcedureValue = value;
    dispatch(selectsActions.getSurgicalProcedures(localStorage.getItem('medical-plans-table-id'),
      page, value, this.cancelSource.token))
      .then((surgical_procedures) => {
        if (surgical_procedures.error) {
          return null;
        }
        let tracks;
        if (surgical_procedures.page > 1) {
          tracks = this.state.tracks.concat(surgical_procedures.result);
        } else {
          tracks = new List(surgical_procedures.result);
        }
        return this.setState({
          tracks,
          hasMoreItems: surgical_procedures.page < surgical_procedures.pages,
        });
      });
  };

  loadItems = () => {
    let { page } = this;
    page++;
    this.loadSurgicalProcedures(page);
  };

  filterProcedures = () => {
    this.setState({
      tracks: new List(),
      hasMoreItems: true,
    });

    this.loadSurgicalProcedures(1);
  };

  changeSelected = (selected) => {
    if (this.props.onChange) { this.props.onChange(selected); }
    this.setState({ selected });
  };

  selectSurgicalProcedure = (event) => {
    const { value } = event.target;
    value.laterality = 1;
    this.changeSelected(this.state.selected.push(value));
  };

  deselectSurgicalProcedure = (event) => {
    const { value } = event.target;
    const selected = this.state.selected.filter((v) => v.id !== value.id);

    this.changeSelected(selected);
  };

  deselectSurgicalProcedureForMedicalPlan = async (value) => {
    const selected = await this.state.selected
      .filter((v) => v.medical_plan_table_id !== Number(value));
    this.changeSelected(selected);
  };

  changeLaterality = (value, surgical_procedure) => {
    const selectedIndex = this.state.selected.findIndex((v) => v.id === surgical_procedure.id);

    surgical_procedure.laterality = value;

    const selected = this.state.selected.set(selectedIndex, surgical_procedure);

    this.changeSelected(selected);
  };

  render() {
    const { surgical_procedures, surgical_procedures_most_used } = this.props;
    const show_most_used = (this.filter && this.filter.input.value.length)
    || !surgical_procedures_most_used || surgical_procedures_most_used.data.length !== 0;
    return (
      <div className="form-list">
        <Input hidden />
        {
          this.state.selected.size
            ? this.state.selected.map((surgical_procedure) => {
              const name = stringsHelp.firstLetterUpper(surgical_procedure.name);
              return (
                <div className="form-list-item" title={name} key={surgical_procedure.id}>
                  <span className="laterality">
                    {surgical_procedure.laterality}
                    X
                  </span>
                  <span className="form-list-description">
                    {surgical_procedure.code}
                    {' '}
                    -
                    {' '}
                    {name}
                  </span>
                  <span
                    className="form-list-del"
                    onClick={() => this
                      .deselectSurgicalProcedure({ target: { value: surgical_procedure } })}
                  >
                    <i className="icon-close" />
                  </span>
                </div>
              );
            })
            : null
        }
        <div className="form-list-item">
          <button type="button" className="form-add-link" onClick={() => this.setModalVisible(true)}>
            + Adicionar
            cirurgias
          </button>
        </div>

        <Modal
          title="Procedimentos"
          visible={this.state.modalVisible}
          onCancel={() => this.setModalVisible(false)}
          onOk={() => this.setModalVisible(false)}
          footer={false}
          buttonOkText="SALVAR"
          buttonOkType="primary"
        >
          <Input placeholder="Buscar" ref={(input) => this.filter = input} onKeyUp={this.filterProcedures} />

          <div className={`form-list ${this.state.selected.size ? '' : 'hidden'}`} style={{ marginTop: '21px' }}>
            <div className="form-list-title">
              SELECIONADOS (
              {this.state.selected.size}
              )
            </div>
            {
              this.state.selected.size
                ? this.state.selected.map((surgical_procedure) => {
                  const name = stringsHelp.firstLetterUpper(surgical_procedure.name);
                  return (
                    <div className="form-list-item slide-in" key={surgical_procedure.id}>
                      <span className="form-list-description" title={name}>
                        <Checkbox defaultChecked value={surgical_procedure} onChange={this.deselectSurgicalProcedure} style={{ width: 'calc(100% - 63px)' }}>
                          {surgical_procedure.code}
                          {' '}
                          -
                          {' '}
                          {name}
                        </Checkbox>
                        <InputNumber
                          min={1}
                          max={15}
                          defaultValue={1}
                          formatter={(value) => `${value}X`}
                          parser={(value) => value.replace('X', '')}
                          style={{
                            width: '63px',
                            fontSize: '17px',
                            fontWeight: '500',
                          }}
                          onChange={(v) => this.changeLaterality(v, surgical_procedure)}
                        />
                      </span>
                    </div>
                  );
                })
                : null
            }
          </div>
          <div className={`form-list ${(show_most_used) ? '' : 'hidden'}`} style={{ marginTop: '21px' }}>
            <div className="form-list-title">MAIS REALIZADOS</div>
            {
              surgical_procedures_most_used
                ? surgical_procedures_most_used.data.map((surgical_procedure) => {
                  const name = stringsHelp.firstLetterUpper(surgical_procedure.name);
                  if (this.state.selected.find((a) => a.id === surgical_procedure.id)) {
                    return null;
                  }

                  return (
                    <div className="form-list-item slide-in" key={surgical_procedure.id}>
                      <span className="form-list-description" title={name}>
                        <Checkbox value={surgical_procedure} onChange={this.selectSurgicalProcedure}>
                          {surgical_procedure.code}
                          {' '}
                          -
                          {' '}
                          {name}
                        </Checkbox>
                      </span>
                    </div>
                  );
                })
                : null
            }
          </div>
          <div className="form-list" style={{ marginTop: '21px', height: '500px', overflow: 'auto' }}>
            <div className="form-list-title">TODOS</div>

            {
              surgical_procedures
                ? (
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={this.loadItems}
                    hasMore={this.state.hasMoreItems}
                    useWindow={false}
                  >
                    {
                    this.state.tracks
                      ? this.state.tracks.map((surgical_procedure) => {
                        const name = stringsHelp.firstLetterUpper(surgical_procedure.name);
                        if (this.state.selected.find((a) => a.id === surgical_procedure.id)) {
                          return null;
                        }

                        return (
                          <div className="form-list-item slide-in" key={surgical_procedure.id}>
                            <span className="form-list-description" title={name}>
                              <Checkbox value={surgical_procedure} onChange={this.selectSurgicalProcedure}>
                                {surgical_procedure.code}
                                {' '}
                                -
                                {' '}
                                {name}
                              </Checkbox>
                            </span>
                          </div>
                        );
                      })
                      : null
                  }
                  </InfiniteScroll>
                ) : null
            }
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { selects } = state;
  const { surgical_procedures, surgical_procedures_most_used } = selects;
  return {
    surgical_procedures,
    surgical_procedures_most_used,
  };
}

const connected = connect(mapStateToProps)(SelectSurgery);
export default connected;
