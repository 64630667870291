import { authenticationService } from '../services';

export {
  header,
  response,
  getToken,
  setToken,
  getValidSession,
  logout,
};

export const auth = {
  header,
  response,
  getToken,
  setToken,
  getValidSession,
  logout,
};

function getSelectedInstitution() {
  return localStorage.getItem('selected-institution');
}

function getUser() {
  return localStorage.getItem('user');
}

function getUserPermission() {
  return localStorage.getItem('user-permissions');
}

function getToken() {
  return localStorage.getItem('auth-token');
}

function setToken(token) {
  return localStorage.setItem('auth-token', token);
}

function getValidSession() {
  if (getToken() && getSelectedInstitution() && getUser() && getUserPermission()) { return true; }
  return false;
}

function header() {
  // return authorization header with jwt token
  const token = localStorage.getItem('auth-token');

  if (token) {
    return { Authorization: token };
  }
  return {};
}

function logout() {
  authenticationService.logout();
}

function response(response) {
  let promisse;
  const contentTupe = response.headers.get('Content-Type');
  switch (contentTupe) {
    case 'image/jpg':
    case 'image/png':
      promisse = response.arrayBuffer().then((buffer) => {
        const bytes = [].slice.call(new Uint8Array(buffer));

        let binary = '';

        bytes.forEach((b) => binary += String.fromCharCode(b));

        return `data:${contentTupe};base64,${window.btoa(binary)}`;
      });
      break;
    default:
      promisse = response.text();
      break;
  }
  return promisse.then((text) => {
    let data = {};
    try {
      data = JSON.parse(text);
    } catch (e) {
      data.message = text;
    }

    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        if (data.message === 'user.password.invalid') {
          data = 'Usuário não autorizado, verifique suas credenciais.';
        } else if (data.message === 'without.permission') {
          data = 'Usuário com permissões insuficientes.';
        } else {
          logout();
        }
      }

      const error = data.message || data || response.statusText;
      return Promise.reject(error);
    }

    const token = response.headers.get('Authorization');

    localStorage.setItem('auth-token', token);
    return data;
  });
}
