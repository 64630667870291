import React, { Component } from 'react';

import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Layout,
  notification,
  Row,
  Select,
  Spin,
} from 'antd';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import SelectMaterials from '../SelectMaterials';
import { eventsService } from '../../services';
import { stringsHelp } from '../../helpers';
import { selectsActions } from '../../actions';
import { mixPanelService } from '../../services/mixpanel.service';

const FormItem = Form.Item;
const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

class Event extends Component {
  state = {
    event: {},
    loading: false,
    searchInterval: null,
  };

  componentDidMount() {
    const { dispatch, match } = this.props;

    if (match.params.id) {
      this.setState({ loading: true });

      eventsService
        .getTransEvent(match.params.id)
        .then((event) => {
          event = event || {};

          if (!event.id) {
            throw Error({ message: 'event.not.found' });
          }

          event.institution_config.material_ids = [];
          event.institution_config.materials.map((material) => {
            event.institution_config.material_ids.push(material.id);

            return material;
          });

          event.description = event.institution_config ? event.institution_config.description : '';
          event.material_ids = event.institution_config ? event.institution_config.material_ids : [];
          event.materials = event.institution_config ? event.institution_config.materials : [];

          this.setState({ event, loading: false });
        })
        .catch((error) => {
          console.log(error);
          notification.error({
            message: 'Não foi possível recuperar o evento.',
          });
          this.props.history.goBack();
        });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });

        values.material_ids = values.materials ? values.materials
          .map((material) => material.id)
          .toArray() : [];

        const event = Object.assign(this.state.event, values);

        let promiseSave;
        if (event.id > 0) {
          mixPanelService.tracking(mixPanelService.EVENTO_EDITAR);

          promiseSave = eventsService.putTransEvent(event).then((result) => {
            if (result.id > 0) {
              this.setState({ loading: false });

              notification.success({
                message: 'Evento alterado com sucesso',
              });

              this.props.history.goBack();
            }
          });
        } else {
          mixPanelService.tracking(mixPanelService.EVENTO_SALVAR);

          promiseSave = eventsService.postTransEvent(event).then((result) => {
            if (result.id > 0) {
              this.setState({ loading: false });
              notification.success({
                message: 'Evento cadastrado com sucesso',
              });

              this.props.history.goBack();
            }
          });
        }
        promiseSave.catch((error) => {
          console.log(error);
          notification.error({
            message: error,
          });
        });
      }
    });
  };

  validatorEventName = async (rule, value, callback) => {
    if (!value || value === '' || value === undefined) {
      callback();
      return null;
    }

    if (this.state.event.origin === 'PRESET') {
      callback();
      return null;
    }

    const unique = await eventsService
      .getTransEventPresetsByName(value)
      .then((event) => {
        event = event || {};
        return event[0];
      }).catch(() => false);

    if (unique) {
      callback(rule.message);
      return null;
    }
  }

  render() {
    const { match } = this.props;
    const { getFieldDecorator } = this.props.form;

    const { event, loading } = this.state;
    const isEdit = match.params.id;

    event.active = true;
    const isDisabled = event.origin === 'PRESET';

    const isGroup = JSON.parse(localStorage.getItem('selected-institution')).is_group;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
      },
      colon: false,
    };
    return (
      <Content>
        <Spin spinning={loading}>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col xs={24} sm={0}>
                <Button
                  type="primary"
                  className="add-procedure"
                  shape="circle"
                  size="large"
                  htmlType={loading ? 'button' : 'submit'}
                  loading={loading}
                >
                  {loading ? '' : <Icon type="check" fill="#ffffff" />}
                </Button>
              </Col>
              <Col xs={24} sm={20}>
                <h1 className="title">
                  {isEdit ? 'Editar' : 'Novo'}
                  {' '}
                  evento
                </h1>
              </Col>
              <Col xs={0} sm={4}>
                <Button
                  type="primary"
                  className="add-procedure"
                  htmlType="submit"
                  loading={loading}
                >
                  SALVAR
                </Button>
              </Col>
            </Row>
            <Row gutter={28}>
              <Col md={24} lg={24} xl={12}>
                <FormItem {...formItemLayout} label="TÍTULO">
                  {getFieldDecorator('name', {
                    validateTrigger: 'onBlur',
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o título do evento.',
                      },
                      {
                        validator: this.validatorEventName,
                        message: 'Já existe um evento com o mesmo título de origem preset.',
                      },
                    ],
                    initialValue: event.name,
                  })(
                    <Input
                      disabled={isDisabled}
                      autoComplete="off"
                      onChange={(e) => (e.target.value = stringsHelp.firstLetterUpper(
                        e.target.value,
                        true,
                      ))}
                    />,
                  )}
                </FormItem>
              </Col>

              <Col md={24} lg={24} xl={12}>
                <FormItem {...formItemLayout} label="DESCRIÇÃO">
                  {getFieldDecorator('description', {
                    initialValue: event.description,
                  })(
                    <TextArea rows={4} />,
                  )}
                </FormItem>
              </Col>
              {!isGroup
                ? (
                  <Col xl={24}>
                    <FormItem {...formItemLayout} label="MATERIAIS">
                      { getFieldDecorator('materials', {
                        initialValue: event.materials,
                      })(
                        <SelectMaterials />,
                      )}
                    </FormItem>
                  </Col>
                )
                : ''}

            </Row>
          </Form>
        </Spin>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { selects } = state;
  const { event } = selects;
  return {
    event,
  };
}

const connected = withRouter(connect(mapStateToProps)(Form.create()(Event)));
export default connected;
