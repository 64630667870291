import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Spin } from 'antd';
import { Document, Page } from 'react-pdf';

const PdfViewerModal = ({
  pdfSource, termSource, visible, onClose,
}) => {
  const [numPages, setNumPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [loading, setLoading] = useState(true);
  const [showingPdf, setShowingPdf] = useState(true);
  const [buttonText, setButtonText] = useState('Alternar Documento');
  const [modalTitle, setModalTitle] = useState('Visualizar PDF');

  const containerRef = useRef(null);

  const onDocumentLoadSuccess = ({ numPages: loadingPages }) => {
    setNumPages(loadingPages);
    setLoading(false);
  };

  const handleMouseWheel = (event) => {
    if (event.deltaY > 0) {
      setScale((prevScale) => Math.max(0.1, prevScale - 0.1));
    } else {
      setScale((prevScale) => prevScale + 0.1);
    }
  };

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.1);
  };

  const handleZoomOut = () => {
    setScale((prevScale) => Math.max(0.1, prevScale - 0.1));
  };

  const handleToggleDocument = () => {
    setShowingPdf((prev) => !prev);
    setButtonText((prevText) => (prevText ? 'Termo de Consentimento' : 'Alternar Documento'));
    setModalTitle((prevTitle) => (prevTitle ? 'Visualizar PDF' : 'Visualizar Termo de Consentimento'));
  };

  useEffect(() => {
    if (visible) {
      setScale(1);
      setButtonText(showingPdf ? 'Termo de Consentimento' : 'Avaliação Pré-Anestésica');
      setModalTitle(showingPdf ? 'Visualizar PDF' : 'Visualizar Termo de Consentimento');
    }
  }, [visible, showingPdf]);

  return (
    <Modal
      title={modalTitle}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="zoomIn" onClick={handleZoomIn}>
          Zoom In
        </Button>,
        <Button key="zoomOut" onClick={handleZoomOut}>
          Zoom Out
        </Button>,
        <Button key="close" onClick={onClose}>
          Fechar
        </Button>,
        termSource && (
          <Button key="toggleDocument" onClick={handleToggleDocument}>
            {buttonText}
          </Button>
        ),
      ]}
      width="auto"
      bodyStyle={{ padding: 0 }}
      centered
    >
      <Spin spinning={loading} size="large">
        <div
          ref={containerRef}
          style={{
            overflowX: 'auto',
            maxWidth: '100%',
            visibility: loading ? 'hidden' : 'visible',
          }}
          onWheel={handleMouseWheel}
        >
          {showingPdf ? (
            <Document file={pdfSource} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
              ))}
            </Document>
          ) : (
            <Document file={termSource} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`term_page_${index + 1}`} pageNumber={index + 1} scale={scale} />
              ))}
            </Document>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default PdfViewerModal;
