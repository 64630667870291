import { institutionsConstants } from '../constants';
import { institutionService } from '../services';

function getInstitutions() {
  return (dispatch) => {
    dispatch(request());

    return institutionService.getInstitutions()
      .then(async (institutions) => {
        dispatch(success(institutions));
        let selected = null;
        try {
          selected = JSON.parse(localStorage.getItem('selected-institution'));
          selected = institutions.filter((institution) => institution.id === selected.id).pop();
        } catch (e) {
          // Handle error
        }

        if (!selected && institutions.length) {
          const institutionGroup = institutions.filter((i) => i.is_group === 1);

          if (institutionGroup.length) {
            selected = institutionGroup[0];
          } else {
            selected = institutions[0];
          }
        }

        // Ensure `selected` is not null before dispatching
        if (selected) {
          return dispatch(selectInstitution(selected)).then(() => institutions);
        }
        return dispatch(selectInstitution({})); // Dispatch an empty object if no institution is found
      }, (error) => dispatch(failure(error)));
  };

  function request() {
    return { type: institutionsConstants.GETINSTITUTIONS_REQUEST };
  }

  function success(institutions) {
    return { type: institutionsConstants.GETINSTITUTIONS_SUCCESS, institutions };
  }

  function failure(error) {
    return { type: institutionsConstants.GETINSTITUTIONS_FAILURE, error };
  }
}

function selectInstitution(institution) {
  return (dispatch) => {
    dispatch(request());

    if (!institution.id) {
      // Handle case when institution is not found
      dispatch(failure('No institution found'));
      return Promise.resolve();
    }

    return institutionService.selectInstitution(institution.id)
      .then(
        async (user_permissions) => {
          const institutionGroups = await institutionService.getInstitutionGroups(institution.id);
          localStorage.setItem('selected-institution', JSON.stringify(institution));
          localStorage.setItem('user-permissions', JSON.stringify(user_permissions));
          if (institutionGroups.length > 1) {
            localStorage.setItem('institution-groups', JSON.stringify(institutionGroups));
          } else {
            localStorage.removeItem('institution-groups');
          }
          dispatch(success(institution));
          return true;
        },
        (error) => dispatch(failure(error)),
      );
  };

  function request() {
    return { type: institutionsConstants.SELECT_INSTITUTION_REQUEST };
  }

  function success(institution) {
    return { type: institutionsConstants.SELECT_INSTITUTION_SUCCESS, institution };
  }

  function failure(error) {
    return { type: institutionsConstants.SELECT_INSTITUTION_FAILURE, error };
  }
}

function getQuestionAnswers(cancelToken) {
  function request() {
    return { type: institutionsConstants.GETANSWER_QUESTION_REQUEST };
  }

  function success(data) {
    return { type: institutionsConstants.GETANSWER_QUESTION_SUCCESS, data };
  }

  function failure(error) {
    return { type: institutionsConstants.GETANSWER_QUESTION_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    return institutionService.getQuestionAnswers(cancelToken)
      .then((questions) => {
        dispatch(success(questions));
        return questions;
      }, (error) => dispatch(failure(error)));
  };
}

export const institutionActions = {
  getInstitutions,
  selectInstitution,
  getQuestionAnswers,
};
