import { drugsConstants } from '../constants';
import { drugsService } from '../services';

function request() {
  return { type: drugsConstants.GETDRUGS_REQUEST };
}

function success(data) {
  return { type: drugsConstants.GETDRUGS_SUCCESS, data };
}

function failure(error) {
  return { type: drugsConstants.GETDRUGS_FAILURE, error };
}
function getDrugs(cancelToken, institution, filter, page, limit) {
  return (dispatch) => {
    dispatch(request());
    return drugsService.getDrugs(cancelToken, institution, filter, page, limit)
      .then((drugs) => {
        dispatch(success(drugs));
        return drugs;
      }, (error) => dispatch(failure(error)));
  };
}
export const drugsActions = {
  getDrugs,
};
