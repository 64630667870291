import Api from '../helpers/api';

export const drugsService = {
  getDrugs,
  getDrug,
  putDrug,
  putDrugFields,
  getDrugClasses,
};

async function getDrugs(cancelToken, institution, filter = undefined, page, limit) {
  cancelToken = cancelToken === undefined ? null : cancelToken;
  page = page === undefined ? 1 : page;
  limit = limit === undefined ? 10 : limit;
  let result = null;
  result = await Api.get('/web/v2/active-principles/count', {
    cancelToken,
    params: {
      filter,
      institution,
    },
  });
  result.activePrinciples = await Api.get('/web/v2/active-principles', {
    cancelToken,
    params: {
      page,
      limit,
      filter,
      institution,
    },
  });
  return result;
}

function getDrug(id, filter, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/web/v2/active-principles/${id}`, filter, { cancelToken });
}

function putDrug(id, objDrug) {
  return Api.put(`/web/institutions/active-principles/${id}`, objDrug);
}

function putDrugFields(id, objDrug) {
  return Api.put(`/v3/active-principles/${id}`, objDrug);
}

function getDrugClasses(cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;
  return Api.get('/active_principle_classes', [], { cancelToken });
}
