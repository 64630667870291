/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import axios from 'axios';
import Api from '../helpers/api';
import config from '../config';
import { getToken } from '../helpers/auth';
import { logService } from './log.service';
import { mixPanelService } from './mixpanel.service';

function getProcedures(objFilter, cancelToken) {
  // eslint-disable-next-line no-param-reassign
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get('/surgical-map/v2/procedures-all', objFilter, { crossDomain: true, cancelToken });
}

async function getProceduresForAttendance(objFilter, cancelToken) {
  // eslint-disable-next-line no-param-reassign
  cancelToken = cancelToken === undefined ? null : cancelToken;

  const entities = ['pres', 'procedures', 'posts'];

  const { key } = objFilter.params;

  const entitiy = entities[parseInt(key, 10) - 1];

  const isPre = entitiy === 'pres';
  const isPost = entitiy === 'posts';

  objFilter.params.isPre = isPre;
  objFilter.params.isPost = isPost;

  const proceduresPromise = Api.get('/app/v4/procedures-attendances', objFilter, { crossDomain: true, cancelToken });
  const countPromise = Api.get('/app/v4/procedures-attendances/count', objFilter, { crossDomain: true, cancelToken });
  const [procedures, count] = await Promise.all([proceduresPromise, countPromise]);
  return { procedures, count: count.total };
}

function putProcedureGroup(procedure) {
  return Api.put(`/preset/procedures/${procedure.id}/update-group`, procedure);
}

async function getViewPdf(pdf) {
  try {
    const token = getToken();

    const response = await axios({
      url: `${config.urlApi}/surgical-map/v2/procedure-download/${pdf}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: token,
      },
    });

    console.log('RESPONSE:', response.data);

    const url = window.URL.createObjectURL(new Blob([response.data]));

    return { url };
  } catch (error) {
    throw new Error('Erro ao buscar o PDF');
  }
}

// Uses different configs, that's why it's creating a new axios instance
async function getDownload(pdf) {
  const token = getToken();

  const response = await axios({
    url: `${config.urlApi}/surgical-map/v2/procedure-download/${pdf}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: token,
    },
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'paciente-atendimento.pdf');
  document.body.appendChild(link);
  link.click();
}

async function downloadPdfs(pdfs) {
  const token = getToken();
  const response = await axios({
    url: `${config.urlApi}/surgical-map/v3/procedure-download?pdfs=${pdfs.join(',')}`,
    method: 'GET',
    responseType: 'blob',
    headers: {
      Authorization: token,
    },
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'pdfs.zip');
  document.body.appendChild(link);
  link.click();
}

export const proceduresService = {
  getProcedures,
  getDownload,
  putProcedureGroup,
  downloadPdfs,
  getProceduresForAttendance,
  getViewPdf,
};
