import React, { Component } from 'react';
import {
  Avatar, Col, Divider, Dropdown, Menu, Row, Skeleton, Input, Tag,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getValidSession, logout } from '../../../helpers/auth';
import { institutionActions } from '../../../actions';
import { pathRoutes } from '../../../routes';
import { stringsHelp } from '../../../helpers';
import './style.scss';

const { Search } = Input;

class InstitutionsDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      institutionDropdownArrow: 'icon-arrow-down',
      listInstitutions: [],
      totalInstitutions: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    this.props.history.listen(this.onRouteChange.bind());

    dispatch(institutionActions.getInstitutions()).then((data) => {
      this.setState({ listInstitutions: data, totalInstitutions: data });
    });
  }

  onRouteChange() {
    if (!getValidSession()) { logout(); }
  }

  institutionArrowChange = (a) => {
    if (a) {
      this.setState({ institutionDropdownArrow: 'icon-arrow-up' });
    } else {
      this.setState({ institutionDropdownArrow: 'icon-arrow-down' });
    }
  };

  selectInstitution = (institution) => {
    const { dispatch } = this.props;

    dispatch(institutionActions.selectInstitution(institution)).then(() => {
      this.props.history.go(0);
    });
  };

  descUserType = (type) => {
    switch (type) {
      case 'AD':
        return 'Administrador';
      case 'AN':
        return 'Anestesiologista';
      case 'PH':
        return 'Pharma';
      case 'IN':
        return 'Instituição';
      default:
        return 'Usuário';
    }
  };

  searchInst = (e) => {
    const institutions = this.state.totalInstitutions;
    const text = e.target.value;

    setTimeout(() => {
      if (text.length) {
        const newInstitutions = institutions.filter((x) => stringsHelp
          .removeAccent(x.name.toString().toLowerCase())
          .includes(stringsHelp.removeAccent(text.toLowerCase()))
          || String(x.id) === text);

        this.setState({ listInstitutions: newInstitutions });
      } else {
        this.setState({ listInstitutions: institutions });
      }
    }, 300);
  };

  render() {
    const { institutions, user } = this.props;

    const menuUser = (
      <Menu selectedKeys={institutions.selected ? [`${institutions.selected.id}`, '-1', '-2'] : ['-1', '-2']} className="dropdown-institution-menu">
        <Menu.Item disabled key="-1">
          <div className="ant-list-item-meta">
            <div className="ant-list-item-meta-content">

              <h4 className="ant-list-item-meta-title user" style={{ marginBottom: -5 }}>
                {user.email}
              </h4>
              <div className="ant-list-item-meta-description">
                Administrador
                {JSON.parse(localStorage.getItem('selected-institution')).is_group === 1 && (
                <Tag style={{ display: 'table', float: 'right' }}>Grupo de anestesia</Tag>
                )}
              </div>

            </div>
          </div>
          <Divider style={{ margin: '5px 0', left: '-12px', width: 'calc(100% + 24px)' }} />
        </Menu.Item>
        {this.state.totalInstitutions && this.state.totalInstitutions.length > 0 && (
          <Menu.Item disabled key="-3" style={{ textAlign: 'center' }}>
            {JSON.parse(localStorage.getItem('selected-institution')).is_group === 1 && (
            <h3>Instituições</h3>
            )}
            <Divider style={{ margin: '5px 0', left: '-12px', width: 'calc(100% + 24px)' }} />

            <Search
              placeholder="Buscar"
              onChange={this.searchInst}
              style={{ width: '100%' }}
            />

          </Menu.Item>
        )}
        {
          this.state.listInstitutions && this.state.listInstitutions.length > 0 && (
            this.state.listInstitutions.filter((i) => i.id !== JSON.parse(localStorage.getItem('selected-institution')).id).map((institution) => (
              <Menu.Item key={institution.id} onClick={() => this.selectInstitution(institution)}>
                <div className="ant-list-item-meta">
                  <div className="ant-list-item-meta-avatar">
                    <Avatar
                      shape="square"
                      src={institution.logo_url ? institution.logo_url : null}
                      className="institution-logo"
                    >
                      Logo
                    </Avatar>
                  </div>
                  <div className="ant-list-item-meta-content">
                    <h4 className="ant-list-item-meta-title" style={{ marginBottom: -5 }}>
                      {institution.is_group === 1 && (
                      <Tag>Grupo</Tag>
                      )}
                      {stringsHelp.maxLength(stringsHelp.firstLetterUpper(institution.name, true), 50, true)}
                    </h4>
                  </div>
                </div>
              </Menu.Item>
            )))
        }

        <Menu.Item disabled key="-2">
          {this.state.listInstitutions && this.state.listInstitutions.length > 0 ? <Divider style={{ margin: '5px 0', left: '-12px', width: 'calc(100% + 24px)' }} /> : ''}
          <div className="ant-list-item-meta">
            <div className="ant-list-item-meta-content">
              <h4 className="ant-list-item-meta-title logout">
                <a href={pathRoutes.logout}>Encerrar sessão</a>
              </h4>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );
    return (
      <Skeleton
        className="institutions-skeleton"
        active
        loading={institutions.loading}
        paragraph={false}
        avatar={{ shape: 'square', style: { verticalAlign: 'middle' } }}
        title={{ width: 200 }}
      >
        <Dropdown overlay={menuUser} trigger={['click']} onVisibleChange={this.institutionArrowChange}>
          <Row className="institution-selected">
            <Col xs={24} xl={4} style={{ width: '40px' }}>
              <Avatar
                shape="square"
                src={institutions.selected ? institutions.selected.logo_url : null}
                className="institution-logo"
              >
                Logo

              </Avatar>
            </Col>
            <Col xs={0} xl={18} style={{ width: 'calc(100% - 66px)', marginLeft: '5px' }} className="institution-user">
              <div>
                <span className="span-institution">{institutions.selected ? stringsHelp.firstLetterUpper(institutions.selected.name, true) : ''}</span>
                <small className="span-institution">{user.email}</small>
              </div>
            </Col>
            <Col xs={0} xl={2} style={{ width: '21px' }}><i className={this.state.institutionDropdownArrow} style={{ color: '#7c8595' }} /></Col>
          </Row>
        </Dropdown>
      </Skeleton>

    );
  }
}

function mapStateToProps(state) {
  const { institutions, authentication } = state;
  const { user } = authentication;
  return {
    user,
    institutions,
  };
}

const connected = withRouter(connect(mapStateToProps)(InstitutionsDropdown));
export default connected;
