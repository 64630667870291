export const schedulingConstants = {
  GETSCHEDULING_REQUEST: 'GETSCHEDULING_REQUEST',
  GETSCHEDULING_SUCCESS: 'GETSCHEDULING_SUCCESS',
  GETSCHEDULING_FAILURE: 'GETSCHEDULING_FAILURE',
  GETPROCEDURE_REQUEST: 'GETPROCEDURE_REQUEST',
  GETPROCEDURE_SUCCESS: 'GETPROCEDURE_SUCCESS',
  GETPROCEDURE_FAILURE: 'GETPROCEDURE_FAILURE',
  SETDATE: 'SETDATE',
  CHANGE_MEDICAL_PLAN_INFO: 'CHANGE_MEDICAL_PLAN_INFO',
};
