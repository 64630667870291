import Api from '../helpers/api';

export const authenticationService = {
  login,
  logout,
};

function login(email, password) {
  return Api.post('/manager/login', { email, password })
    .then((user) => {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
}

function logout(args) {
  // remove user from local storage to log user out
  localStorage.clear();
  window.location.reload(true);
}
