import {medicalPlansConstants} from '../constants';
import {medicalPlansService} from "../services";

export const medicalPlansActions = {
  getMedicalPlans,
};

function getMedicalPlans(filter, cancelToken) {
  return dispatch => {
    dispatch(request());

    return medicalPlansService.getMedicalPlans(filter, cancelToken)
      .then(medicalPlans => {        
        dispatch(success(medicalPlans));
        return medicalPlans;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: medicalPlansConstants.GETMEDICALPLANS_REQUEST}
  }

  function success(data) {
    return {type: medicalPlansConstants.GETMEDICALPLANS_SUCCESS, data}
  }

  function failure(error) {
    return {type: medicalPlansConstants.GETMEDICALPLANS_FAILURE, error}
  }
}
