import React, { Component } from 'react';

import {
  Form,
  Input,
  Select,
  Row,
  Col,
  Button,
  Layout,
  notification,
  Icon,
  Spin,
  DatePicker,
  TimePicker,
  Avatar,
} from 'antd';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { schedulingActions, selectsActions } from '../../actions';
import AutocompleteSelectPatient from '../AutocompleteSelectPatient';
import SelectSurgery from '../SelectSurgery';
import SelectSurgeons from '../SelectSurgeons';
import SelectAnaesthetists from '../SelectAnaesthetists';
import SelectMedicalPlans from '../SelectMedicalPlans';

import { schedulingService } from '../../services/scheduling.service';
import { stringsHelp } from '../../helpers';
import { pathRoutes } from '../../routes';
import { findInstitutionConfig } from '../../helpers/settings';
import { intitutionSettings } from '../../constants/intitutionSettings.constants';
import DateTime from '../DateTime';

const FormItem = Form.Item;
const { Content } = Layout;
const { Option } = Select;

class Scheduling extends Component {
  state = {
    procedure: {},
    saving: false,
    config: false,
    medicalPlans: false,
    medicalPlansArray: [],
  };

  async componentDidMount() {
    const { dispatch, match, history } = this.props;
    const config = await findInstitutionConfig(
      intitutionSettings.CONFIG_ID_REQUIRED_REGISTER_NUMBER,
    );
    this.setState({ config });

    if (match.params.id) {
      dispatch(schedulingActions.getProcedure(match.params.id))
        .then((procedure) => {
          procedure = procedure || {};

          if (!procedure.id) {
            return null;
          }

          if (procedure.user_id !== 1) {
            notification.warning({
              message: 'Este procedimento não pode ser alterado.',
            });
            history.goBack();
            return null;
          }

          if (
            JSON.parse(localStorage.getItem('selected-institution'))
            && JSON.parse(localStorage.getItem('selected-institution')).is_group
            && procedure.institution.hasOwnProperty('id')
          ) {
            this.props.location.query = { institution: procedure.institution };
          }

          procedure.surgery_probable_date = procedure.surgery_probable_date
            ? moment(procedure.surgery_probable_date).format('DD/MM/YYYY HH:mm')
            : null;

          procedure.surgical_procedures = procedure.laterality_procedures
            ? procedure.laterality_procedures.map((laterality) => {
              const procedure = laterality.surgical_procedure;
              procedure.laterality = parseInt(laterality.laterality);

              return procedure;
            })
            : [];

          procedure.surgeons = procedure.active_surgeons
            ? procedure.active_surgeons.map((active_surgeon) => active_surgeon.surgeon)
            : [];

          if (procedure.active_anaesthetists) {
            procedure.active_anaesthetists_pre = [];
            procedure.active_anaesthetists_trans = [];
            procedure.active_anaesthetists.map((active_anaesthetist) => {
              if (procedure.active_anaesthetists.is_main || procedure.active_anaesthetists.is_main_pre) {
                if (procedure.active_anaesthetists.user.institutions[0].institution_user.is_resident === true) {
                  this.setState({ saving: false });
                  notification.error({
                    message:
                      'Não é possível adicionar um residente como anestesiologista principal',
                  });
                  throw new Error('Não é possível adicionar um residente como anestesiologista principal');
                }
              }
              if (active_anaesthetist.is_pre) {
                procedure.active_anaesthetists_pre.push({
                  ...active_anaesthetist.anaesthetist,
                  is_main: active_anaesthetist.is_main || active_anaesthetist.is_main_pre,
                });
              }

              if (active_anaesthetist.is_trans) {
                procedure.active_anaesthetists_trans.push({
                  ...active_anaesthetist.anaesthetist,
                  is_main: active_anaesthetist.is_main || active_anaesthetist.is_main_pre,
                });
              }
            });
          }

          procedure.medical_plans = procedure.procedure_medical_plans
            ? procedure.procedure_medical_plans.map((procedureMedicalPlans) => procedureMedicalPlans)
            : [];

          this.loadSurgeryCenterRooms(procedure.surgery_center_id);

          this.setState({ procedure });
        })
        .catch((error) => {
          console.log(error);
          notification.error({
            message: 'Não foi possível recuperar o procedimento.',
          });
          history.goBack();
        });
    } else {
      const procedure = {};
      await dispatch(schedulingActions.deleteMedicalPlanInfo());
      // New with patient
      if (
        this.props.location.hasOwnProperty('query')
        && this.props.location.query.patient
      ) {
        procedure.patient = this.props.location.query.patient;
      }

      if (
        this.props.location.hasOwnProperty('query')
        && this.props.location.query.institution
      ) {
        procedure.institution_id = this.props.location.query.institution.id;
      }

      // New only
      procedure.surgery_probable_date = moment();
      procedure.surgery_probable_hour = moment(moment(), 'HH:mm');

      this.setState({ procedure });
    }

    this.props.location.query
      ? dispatch(selectsActions.getSurgeryCenters(this.props.location.query.institution.id))
      : dispatch(selectsActions.getSurgeryCenters());

    dispatch(selectsActions.getRooms());
    dispatch(selectsActions.getSurgeryTypes());
  }

   // eslint-disable-next-line react/sort-comp
   handleSubmit = (e) => {
     e.preventDefault();
     this.props.form.validateFieldsAndScroll((err, values) => {
       if (!err) {
         this.setState({ saving: true });
         values.active_surgeons = values.active_surgeons
           ? values.active_surgeons
             .map((surgeon) => ({ surgeon_id: surgeon.id }))
             .toArray()
           : [];

         values.procedure_medical_plans = values.procedure_medical_plans
           ? values.procedure_medical_plans
             .map((medical_plan) => ({
               medical_plan_id: medical_plan.medical_plan_id ? medical_plan.medical_plan_id : medical_plan.id,
               code: medical_plan.patient_medical_plan_code,
               password: medical_plan.password,
             }))
             .toArray()
           : [];

         const mains = [];

         // Setting active_anaesthetists_trans from API (when edit trans)
         if (!values.active_anaesthetists_pre) {
           values.active_anaesthetists_pre = this.state.procedure.active_anaesthetists_pre;
         } else {
           values.active_anaesthetists_pre = values.active_anaesthetists_pre.toArray();
         }

         const active_anaesthetists_pre = values.active_anaesthetists_pre
           ? values.active_anaesthetists_pre.map((anaesthetist) => {
             if (anaesthetist.is_main || anaesthetist.is_main_pre) {
               if (anaesthetist.user.institutions[0].institution_user.is_resident === true) {
                 this.setState({ saving: false });
                 notification.error({
                   message:
                      'Não é possível adicionar um residente como anestesiologista principal',
                 });
                 throw new Error('Não é possível adicionar um residente como anestesiologista principal');
               }
               mains.push(anaesthetist);
             }
             return {
               anaesthetist_id: anaesthetist.id,
               is_pre: true,
               is_main_pre:
              anaesthetist.is_main,
             };
           })
           : [];

         // Setting active_anaesthetists_trans from API (when edit pré)
         if (!values.active_anaesthetists_trans) {
           values.active_anaesthetists_trans = this.state.procedure.active_anaesthetists_trans;
         } else {
           values.active_anaesthetists_trans = values.active_anaesthetists_trans.toArray();
         }

         const active_anaesthetists_trans = values.active_anaesthetists_trans
           ? values.active_anaesthetists_trans
             .map((anaesthetist) => {
               if (anaesthetist.is_main || anaesthetist.is_main_pre) {
                 if (anaesthetist.user.institutions[0].institution_user.is_resident === true) {
                   this.setState({ saving: false });
                   notification.error({
                     message:
                        'Não é possível adicionar um residente como anestesiologista principal',
                   });
                   throw new Error('Não é possível adicionar um residente como anestesiologista principal');
                 }
                 mains.push(anaesthetist);
               }
               return {
                 anaesthetist_id: anaesthetist.id,
                 is_trans: true,
                 is_main: anaesthetist.is_main,
               };
             })
           : [];

         if (mains.length > 1) {
           const mainPre = mains.find((v) => v.is_pre);

           if (mainPre) {
             values.user_id = mainPre.user_id;
           } else {
             values.user_id = mains[0].user_id;
           }
         } else if (mains.length > 0) {
           values.user_id = mains[0].user_id;
         }

         values.active_anaesthetists = [...active_anaesthetists_pre, ...active_anaesthetists_trans];

         values.laterality_procedures = values.surgical_procedures
           ? values.surgical_procedures
             .map((surgical_procedure) => ({
               laterality: `${surgical_procedure.laterality}x`,
               surgical_procedure_id: surgical_procedure.id,
             }))
             .toArray()
           : [];

         values.surgery_probable_date = values.surgery_probable_date.format(
           'YYYY-MM-DDTHH:mm:ss.SSSZ',
         );

         values.name = `${values.surgical_procedures.get(0).code
         }-${
           stringsHelp.firstLetterUpper(values.surgical_procedures.get(0).name)
         }${values.surgical_procedures.size > 1
           ? values.surgical_procedures.size - 1
           : ''}`;

         delete values.surgical_procedures;

         const procedure = {
           user_id: 1,
           laterality_procedures_performed: [],
           surgical_procedures_performed_ids: [],
           surgical_procedures_planned_ids: [],
           tag_ids: [],
           techniques: [],
           date: moment(),
           ...this.state.procedure,
           ...values,
         };

         if (
           this.props.location.hasOwnProperty('query')
          && this.props.location.query.institution
         ) {
           procedure.institution_id = this.props.location.query.institution.id;
         }

         delete procedure.active_anaesthetists_pre;
         delete procedure.active_anaesthetists_trans;
         delete procedure.patient;

         let promiseSave;
         if (procedure.id > 0) {
           promiseSave = schedulingService
             .putProcedure(procedure)
             .then((result) => {
               if (result.id > 0) {
                 this.setState({ saving: false });

                 notification.success({
                   message: 'Procedimento alterado com sucesso',
                 });

                 this.props.history.goBack();
               }
             });
         } else {
           promiseSave = schedulingService
             .postProcedure(procedure)
             .then((result) => {
               if (result.id > 0) {
                 this.setState({ saving: false });

                 // localStorage.setItem('new-procedure', 'added');

                 notification.success({
                   message: 'Procedimento adicionado com sucesso',
                 });

                 this.props.history.push(pathRoutes.schedules);
               }
             });
         }
         promiseSave.catch((error) => {
           console.log(error);
           notification.error({
             message: error.message,
           });
         });
       }
     });
   };

  loadSurgeryCenterRooms = (surgery_center_id) => {
    const { dispatch } = this.props;

    const procedure = { ...this.state.procedure, surgery_center_room_id: null };

    this.setState({ procedure });

    dispatch(selectsActions.getSurgeryCenterRooms(surgery_center_id));
  };

  async getMedicalPlans(medicalPlans) {
    if (medicalPlans.size) {
      const medicalPlansUpadte = await medicalPlans
        .map((medicalPlansArray) => (medicalPlansArray.medical_plan
          ? medicalPlansArray.medical_plan : medicalPlansArray));
      this.setState({ medicalPlans: true, medicalPlansArray: medicalPlansUpadte });
    } else {
      this.setState({ medicalPlans: false, medicalPlansArray: [] });
    }
  }

  validatorModalSelect = (rule, value, callback) => {
    if (value && value.size > 0) {
      callback();
      return;
    }
    callback(rule.message);
  };

  newPatientRedirect = (value) => {
    const { history } = this.props;
    history.push({
      pathname: pathRoutes.newPatient,
      query: { name: value },
      from: pathRoutes.newSchedule,
    });
  };

  componentWillUnmount() {
    const { form } = this.props;

    const values = form.getFieldsValue();

    values.surgeons = values.active_surgeons
      ? values.active_surgeons.map((active_surgeon) => active_surgeon)
      : [];

    values.anaesthetists = values.active_anaesthetists
      ? values.active_anaesthetists.map((active_anaesthetist) => active_anaesthetist)
      : [];

    /* if (localStorage.getItem('new-procedure') === 'added') {
      localStorage.removeItem('new-procedure')
    } else {
      localStorage.setItem('new-procedure', JSON.stringify(values));
    } */
  }

  selectPatient = (patient) => {
    patient = patient || {};
    this.setState({
      procedure: {
        ...this.state.procedure,
        patient,
        patient_uuid: patient.uuid,
      },
    });

    this.focusField('surgery_center_id');
  };

  // eslint-disable-next-line react/sort-comp
  render() {
    const { getFieldDecorator } = this.props.form;
    const propProcedure = this.props.procedure;
    const { match, institutions } = this.props;
    const {
      surgery_centers,
      surgery_center_rooms,
      rooms,
      surgery_types,
      medical_plans,
    } = this.props.selects;
    const { procedure } = this.state;
    const isEdit = match.params.id;
    let loadingForm = isEdit && propProcedure ? propProcedure.loading : false;
    loadingForm = institutions.selected ? loadingForm : true;
    loadingForm = this.state.saving ? true : loadingForm;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
      },
      colon: false,
    };
    const colLarge = { lg: { span: 24 }, xl: { span: 12 }, xxl: { span: 12 } };
    const colSmall = {
      xs: { span: 24 }, sm: { span: 12 }, md: { span: 12 }, lg: { span: 8 }, xl: { span: 8 }, xxl: { span: 6 },
    };
    return (
      <Content>
        <Spin spinning={loadingForm}>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col xs={24} sm={0}>
                <Button
                  type="primary"
                  className="add-procedure"
                  shape="circle"
                  size="large"
                  htmlType={loadingForm ? 'button' : 'submit'}
                  loading={loadingForm}
                >
                  {loadingForm ? '' : <Icon type="check" fill="#ffffff" />}
                </Button>
              </Col>
              <Col xs={24} sm={20}>
                <h1 className="title">
                  {isEdit ? 'Editar' : 'Novo'}
                  {' '}
                  procedimento cirúrgico
                  {' '}
                </h1>
              </Col>
              <Col xs={0} sm={4}>
                <Button
                  type="primary"
                  className="add-procedure"
                  htmlType={loadingForm ? 'button' : 'submit'}
                  loading={loadingForm}
                >
                  SALVAR
                </Button>
              </Col>
            </Row>
            <Row gutter={28}>
              <Col {...colLarge}>
                <FormItem {...formItemLayout} label="PACIENTE">
                  {getFieldDecorator('patient_id', {
                    rules: [
                      {
                        required: true,
                        message: 'Selecione um paciente',
                      },
                    ],
                  })(
                    <AutocompleteSelectPatient
                      patient={procedure.patient}
                      institution={
                        this.props.location.hasOwnProperty('query')
                        && this.props.location.query.institution
                          ? this.props.location.query.institution
                          : null
                      }
                      route="newSchedule"
                    />,
                  )}
                </FormItem>
              </Col>
              <Col {...colSmall}>
                <FormItem {...formItemLayout} label="DATA PROVÁVEL DA CIRURGIA">
                  {getFieldDecorator('surgery_probable_date', {
                    rules: [
                      {
                        required: true,
                        message: 'Data provável da cirurgia é obrigatória',
                      },
                    ],
                    initialValue: procedure.surgery_probable_date
                      ? moment(
                        procedure.surgery_probable_date,
                        'DD/MM/YYYY HH:mm',
                      )
                      : null,
                  })(
                    <DateTime />,
                  )}
                </FormItem>
              </Col>
              {this.props.location.hasOwnProperty('query')
              && this.props.location.query.institution ? (
                <Col {...colLarge}>
                  <FormItem {...formItemLayout} label="LOCAL DO PROCEDIMENTO">
                    <div
                      className="ant-list-item-meta ant-group"
                      style={{ marginTop: 10 }}
                    >
                      <div className="ant-list-item-meta-avatar">
                        <Avatar
                          shape="square"
                          src={
                            this.props.location.query.institution.logo_url
                              ? this.props.location.query.institution.logo_url
                              : null
                          }
                          className="institution-logo"
                        >
                          Logo
                        </Avatar>
                      </div>
                      <div className="ant-list-item-meta-content">
                        <h4
                          className="ant-list-item-meta-title"
                          style={{ marginBottom: -5 }}
                        >
                          {stringsHelp.firstLetterUpper(
                            this.props.location.query.institution.name,
                            true,
                          )}
                        </h4>
                      </div>
                    </div>
                  </FormItem>
                </Col>
                ) : (
                  ''
                )}
            </Row>

            <Row gutter={28}>
              <Col xs={24} sm={20}>
                <h3 className="title">
                  Instituição
                </h3>
              </Col>
            </Row>
            <Row gutter={28}>
              <Col {...colSmall}>
                <FormItem {...formItemLayout} label="ACOMODAÇÃO">
                  {getFieldDecorator('room_id', {
                    initialValue: procedure.room_id,
                  })(
                    <Select
                      loading={rooms ? rooms.loading : true}
                      showAction={['focus', 'click']}
                      onSelect={() => this.focusField('surgery_probable_date')}
                    >
                      {!rooms
                        ? null
                        : rooms.data.map((room) => (
                          <Option key={room.id} value={room.id}>
                            {room.room_i18n[0].name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col {...colSmall}>
                <FormItem {...formItemLayout} label="CENTRO CIRÚRGICO">
                  {getFieldDecorator('surgery_center_id', {
                    initialValue: procedure.surgery_center_id,
                  })(
                    <Select
                      onChange={this.loadSurgeryCenterRooms}
                      loading={
                        surgery_centers ? surgery_centers.loading : true
                      }
                      showAction={['focus', 'click']}
                      onSelect={() => this.focusField('surgery_center_room_id')}
                    >
                      {!surgery_centers
                        ? null
                        : surgery_centers.data.map((surgery_center) => (
                          <Option
                            key={surgery_center.id}
                            value={surgery_center.id}
                          >
                            {surgery_center.name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col {...colSmall}>
                <FormItem {...formItemLayout} label="SALA">
                  {getFieldDecorator('surgery_center_room_id', {
                    initialValue: procedure.surgery_center_room_id,
                  })(
                    <Select
                      loading={
                        surgery_center_rooms
                          ? surgery_center_rooms.loading
                          : true
                      }
                      notFoundContent="Selecione um Centro cirúrgico"
                      showAction={['focus', 'click']}
                      onSelect={() => this.focusField('room_id')}
                    >
                      {!surgery_center_rooms
                        ? null
                        : surgery_center_rooms.data.map(
                          (surgery_center_room) => (
                            <Option
                              key={surgery_center_room.id}
                              value={surgery_center_room.id}
                            >
                              {surgery_center_room.name}
                            </Option>
                          ),
                        )}
                    </Select>,
                  )}
                </FormItem>
              </Col>
            </Row>

            <Row gutter={28}>
              <Col xs={24} sm={20}>
                <h3 className="title">
                  Atendimento
                </h3>
              </Col>
            </Row>
            <Row gutter={28}>
              <Col {...colSmall}>
                <FormItem {...formItemLayout} label="Nº DE PRONTUÁRIO">
                  {getFieldDecorator('medical_record_number', {
                    initialValue: procedure.medical_record_number,
                  })(<Input />)}
                </FormItem>
              </Col>
              <Col {...colSmall}>
                <FormItem {...formItemLayout} label="Nº DE ATENDIMENTO">
                  {getFieldDecorator('register_number', {
                    rules: [
                      {
                        required: this.state.config === '1',
                        message: 'Preencha o nº de atendimento',
                      },
                    ],
                    initialValue: procedure.register_number,
                  })(<Input />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={28}>
              <Col {...colLarge}>
                <FormItem {...formItemLayout}>
                  {getFieldDecorator('procedure_medical_plans', {
                    rules: [
                      {
                        required: true,
                        message: 'Selecione um convênio',
                      },
                    ],
                    initialValue: procedure.procedure_medical_plans,
                  })(<SelectMedicalPlans onChange={(e) => this.getMedicalPlans(e)} />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={28}>
              <Col xs={24} sm={20}>
                <h3 className="title">
                  Cirurgias
                </h3>
              </Col>
            </Row>
            <Row gutter={28}>
              <Col {...colSmall}>
                <FormItem {...formItemLayout} label="TIPO DE CIRURGIA">
                  {getFieldDecorator('surgery_type_id', {
                    rules: [
                      {
                        required: true,
                        message: 'Selecione um tipo de cirurgia',
                      },
                    ],
                    initialValue: procedure.surgery_type_id,
                  })(
                    <Select
                      loading={surgery_types ? surgery_types.loading : true}
                      showAction={['focus', 'click']}
                    >
                      {!surgery_types
                        ? null
                        : surgery_types.data.map((surgery_type) => (
                          <Option
                            key={surgery_type.id}
                            value={surgery_type.id}
                          >
                            {surgery_type.surgery_type_i18n[0].name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
              </Col>
              <Col {...colSmall}>
                <FormItem {...formItemLayout} label="Nº DE AVISO DE CIRURGIA">
                  {getFieldDecorator('surgery_warning_number', {
                    initialValue: procedure.surgery_warning_number,
                  })(<Input />)}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={28}>
              <Col {...colLarge}>
                <FormItem {...formItemLayout} label="CIRURGIAS PROPOSTAS">
                  {this.state.medicalPlans
                    ? getFieldDecorator('surgical_procedures', {
                      rules: [
                        {
                          required: true,
                          validator: this.validatorModalSelect,
                          message: 'Adicione ao menos uma cirurgia',
                        },
                      ],
                      initialValue: procedure.surgical_procedures,
                    })(
                      <SelectSurgery
                        medical_plan_table_id={localStorage.getItem('medical-plans-table-id')}
                        medicalPlansFilter={this.state.medicalPlansArray
                          .map((medicalPlansTable) => medicalPlansTable.medical_plan_table_id)}
                      />,
                    )
                    : 'Selecione um convênio'}
                </FormItem>
              </Col>
              <Col {...colLarge}>
                <FormItem {...formItemLayout} label="CIRURGIÕES">
                  {getFieldDecorator('active_surgeons', {
                    initialValue: procedure.surgeons,
                  })(<SelectSurgeons />)}
                </FormItem>
              </Col>
              <Col {...colLarge}>
                <FormItem {...formItemLayout} label="ANESTESIOLOGISTAS">
                  {getFieldDecorator('active_anaesthetists_trans', {
                    initialValue: procedure.active_anaesthetists_trans,
                  })(<SelectAnaesthetists />)}
                </FormItem>
              </Col>
            </Row>

          </Form>
        </Spin>
      </Content>
    );
  }

  focusField(name) {
    const field = this.props.form.getFieldInstance(name);

    if (!field) {
      return;
    }

    if (field.picker) {
      field.picker.setState({ open: true });
    } else if (field.timePickerRef) {
      field.timePickerRef.setState({ open: true });
    } else {
      field.focus();
    }
  }
}

function mapStateToProps(state) {
  const { selects, institutions, scheduling } = state;
  const { procedure } = scheduling;
  return {
    selects,
    institutions,
    procedure,
  };
}

const connected = withRouter(
  connect(mapStateToProps)(Form.create()(Scheduling)),
);
export default connected;
