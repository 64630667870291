import Api from '../helpers/api';

export const monitorQrcodesService = {
  getMonitorQrcodes,
  getMonitorQrcode,
  postMonitorQrcode,
  putMonitorQrcode,
  deleteMonitorQrcode
};

function getMonitorQrcodes(filter, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/web/monitors/qrcodes`, {cancelToken})
}

function getMonitorQrcode(id, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/web/monitors/qrcodes/${id}`, {cancelToken})
}

function postMonitorQrcode(medicalPlan){
  return Api.post(`/web/monitors/qrcodes`, medicalPlan)
}

function putMonitorQrcode(medicalPlan){
  return Api.put(`/web/monitors/qrcodes/${medicalPlan.id}`, medicalPlan)
}

function deleteMonitorQrcode(id){
  return Api.delete(`/web/monitors/qrcodes/${id}`)
}