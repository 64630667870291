import React from 'react';

const RestrictArea = () => (
  <div>
    <h1>Página Restrita</h1>
    <p>Esta é uma página restrita e requer privilégios específicos.</p>
  </div>
);

export default RestrictArea;
