import uuid from 'uuid/v4';
import Api from '../helpers/api';

export const schedulingService = {
  getScheduling,
  postProcedure,
  getProcedure,
  putProcedure,
  putCancellationReason,
};

function getScheduling(params, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/app/v4/schedules?${params}`, { cancelToken });
}

function getProcedure(id, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/surgical-map/v2/procedures/${id}`, { cancelToken });
}

function postProcedure(procedure) {
  procedure.uuid = uuid();
  return Api.post('/app/v3/procedures', procedure);
}

function putProcedure(procedure) {
  return Api.put(`/app/v2/procedures/${procedure.id}`, procedure);
}

function putCancellationReason(id, procedure) {
  return Api.put(`/app/procedures/cancellation/${id}`, procedure);
}
