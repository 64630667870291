/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  Layout,
  Row,
  Tooltip,
  message,
} from 'antd';
import { connect } from 'react-redux';
import {
  alertActions,
  authenticationActions,
  institutionActions,
} from '../../actions';
import { pathRoutes } from '../../routes';
import iconHeader from '../../assets/imgs/AxAgendamento.svg';
import iconColor from '../../assets/imgs/icon_color.png';
import PasswordReset from '../PasswordReset';
import { mixPanelService } from '../../services/mixpanel.service';

const { Content, Footer } = Layout;

class Login extends Component {
  state = {
    loading: false,
    loginError: false,
  };

  constructor(props) {
    super(props);
    localStorage.clear();
    if (localStorage.getItem('user')) {
      this.props.history.push(pathRoutes.home);
    }
  }

  componentDidUpdate() {
    this.alerts();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;

    dispatch(alertActions.clear());

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { email } = values;
        const { password } = values;
        this.setState({ loading: true });

        if (email && password) {
          dispatch(authenticationActions.login(email, password)).then(
            (user) => {
              if (user) {
                const { dispatch } = this.props;

                mixPanelService.tracking(mixPanelService.LOGIN);

                dispatch(institutionActions.getInstitutions()).then(() => {
                  this.setState({ loading: false });
                  this.props.history.push(pathRoutes.home);
                });
              } else {
                this.setState({ loading: false, loginError: true });
              }
            },
          );
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Layout
          className="login-page"
          style={{ minHeight: '100vh', padding: 0 }}
        >
          <Content style={{ padding: '0 10px' }}>
            <Row type="flex" justify="center" align="middle">
              <Col xs={0} sm={2} md={3} lg={6} xl={7} xxl={8} />
              <Col
                xs={24}
                sm={20}
                md={18}
                lg={12}
                xl={10}
                xxl={8}
                style={{ paddingTop: '50px' }}
              >
                <div style={{ padding: '30px' }} className="login-form">
                  <Card
                    title={(
                      <div style={{ textAlign: 'center' }} className="logo">
                        <img
                          src={iconHeader}
                          alt="Logo AxReg | Agendamento"
                          className="logo-axreg"
                        />
                        <Divider>Login</Divider>
                      </div>
                    )}
                    bordered={false}
                    style={{ width: '100%' }}
                  >
                    <Form onSubmit={this.handleSubmit} className="login-form">
                      <Form.Item>
                        {getFieldDecorator('email', {
                          rules: [
                            {
                              type: 'email',
                              message: 'E-mail inválido',
                            },
                            {
                              required: true,
                              message: 'Digite o e-mail',
                            },
                          ],
                        })(
                          <Input
                            prefix={(
                              <Icon
                                type="mail"
                                style={{ color: 'rgba(0,0,0,.25)' }}
                              />
                            )}
                            type="email"
                            placeholder="Email"
                          />,
                        )}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator('password', {
                          rules: [
                            {
                              required: true,
                              message: 'Digite a senha',
                            },
                          ],
                        })(
                          <Input.Password
                            prefix={(
                              <Icon
                                type="lock"
                                style={{ color: 'rgba(0,0,0,.25)' }}
                              />
                            )}
                            type="password"
                            placeholder="Senha"
                          />,
                        )}
                        {this.state.loginError ? (
                          <span style={{ color: 'red', marginBottom: -10 }}>E-mail e/ou senha inválidos.</span>
                        ) : (
                          ''
                        )}
                      </Form.Item>
                      <Form.Item>
                        <Button
                          block
                          type="primary"
                          loading={this.state.loading}
                          htmlType="submit"
                          className="btn-custom-primary"
                          style={{ float: 'rigth' }}
                        >
                          Entrar
                        </Button>

                        <div style={{ float: 'right' }}>
                          <PasswordReset />
                        </div>
                      </Form.Item>
                    </Form>
                  </Card>
                </div>
              </Col>
              <Col xs={0} sm={2} md={3} lg={6} xl={7} xxl={8} />
            </Row>
          </Content>
          <Footer
            style={{ position: 'relative', bottom: '0', textAlign: 'center' }}
          >
            Powered by
            <Tooltip title="Anestech">
              {' '}
              <a
                href="http://www.anestech.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={iconColor} alt="Logo" />
              </a>
            </Tooltip>
          </Footer>
        </Layout>
      </div>
    );
  }

  alerts() {
    const { alert, dispatch } = this.props;
    if (alert.message) {
      message[alert.type](alert.message);
      dispatch(alertActions.clear());
    }
    return null;
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert, institutions } = state;
  return {
    loggingIn,
    alert,
    institutions,
  };
}

const connectedLoginPage = connect(mapStateToProps)(Form.create()(Login));
export default connectedLoginPage;
