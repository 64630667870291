/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { CancelToken } from 'axios';
import { connect } from 'react-redux';
import {
  Button,
  Col,
  Form,
  Icon,
  Layout,
  Row,
  Table,
  Spin,
  Card,
  Switch,
  notification,
  Typography,
  Modal,
  Select
} from 'antd';

import Api from '../../helpers/api';
import { pathRoutes } from '../../routes';
import { stringsHelp } from '../../helpers';
import { institutionService } from '../../services/institution.service';
import { institutionActions } from '../../actions';

const { Content } = Layout;

class PreAnesthesia extends Component {
    // eslint-disable-next-line react/state-in-constructor
    state = {
      institution: {},
      loading: false,
      formChanged: {
          defaultOrientationPatientPre: false,
          questionAnswer: false,
          preAnesthesiaAvailability: false
      },
      activatePre: true,
      focusedTextArea: false,
      defaultOrientationPatientPre: '',
      dataQuestionsAswers: [],
      answers: [],
      preAnesthesiaAvailability: '',
      preAnesthesiaSettingExists: false
  };
  
    cancelSource = CancelToken.source();

    componentDidMount() {
      const { dispatch } = this.props;
      const selectedInstitution = JSON.parse(localStorage.getItem('selected-institution'));
      this.setState({ institution: selectedInstitution });
    
      if (this.state.loading) {
        this.cancelSource.cancel();
        this.cancelSource = CancelToken.source();
      }
    
      this.setState({ loading: true });
    
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', this.handlePopstate);
    
      try {
        Api.get(`/institution/${selectedInstitution.id}/settings/pdf`)
          .then((data) => {
            this.setState({
              defaultOrientationPatientPre: data.defaultOrientationPatientPre,
              activatePre: data.activatePre,
            });
    
            dispatch(institutionActions.getQuestionAnswers(this.cancelSource.token)).then(
              (data) => {
                this.createStateDataSource(data);
              },
            );
          })
          .catch(() => {
            notification.error({
              message: 'Erro ao carregar dados, verifique sua conexão com a internet',
            });
          });
    
          Api.get(`/institutions/settings/26`)
          .then((response) => {
            if (response && response.value) {
              this.setState({ 
                preAnesthesiaAvailability: response.value,
                preAnesthesiaSettingExists: true
              });
            } else {
              this.setState({ 
                preAnesthesiaSettingExists: false
              });
            }
          })
          .catch((error) => {
         if (error && error.status === 404) {
              this.setState({ 
                preAnesthesiaSettingExists: false
              });
            } else {
              notification.error({
                message: 'Erro ao carregar configuração de disponibilidade do pré-anestésico.',
              });
            }
          });
        
      
      } catch (err) {
        notification.error({
          message: 'Erro ao carregar dados, verifique sua conexão com a internet',
        });
        this.setState({ loading: false });
      }
    }
    
    componentWillUnmount() {
      window.removeEventListener('popstate', this.handlePopstate);
    }

    handleChange(e) {
      e.preventDefault();
      if (e.target.name === 'defaultOrientationPatientPre') {
        this.state.defaultOrientationPatientPre = e.target.value;
      }
    }

    putPreSettings = (institutionId, data) => institutionService.putPreSettings(institutionId, data)
      .then(async () => {
        notification.success({
          message: 'Dados do pré-anestésico alterados com sucesso!',
        });
        this.state.formChanged.defaultOrientationPatientPre = false;
      }).catch(() => {
        notification.error({
          message: 'Dados do pré-anestésico não puderam ser salvas!',
        });
      });

    putQuestionAnswers = (answers) => institutionService.saveQuestionAnswers(answers)
      .then(async (data) => {
        this.createStateDataSource(data);
        notification.success({
          message: 'Perguntas alteradas com sucesso!',
        });
        this.state.formChanged.questionAnswer = true;
      }).catch(() => {
        notification.error({
          message: 'Perguntas não puderam ser salvas!',
        });
      });
      handleSubmit = async () => {
        try {
            if (this.state.formChanged.defaultOrientationPatientPre
                || this.state.formChanged.questionAnswer
                || this.state.formChanged.preAnesthesiaAvailability) {
    
                this.setState({ loading: true });
    
                if (this.state.formChanged.defaultOrientationPatientPre) {
                    await this.putPreSettings(this.state.institution.id, {
                        activatePre: this.state.activatePre,
                        defaultOrientationPatientPre: this.state.defaultOrientationPatientPre,
                    });
                }
    
                if (this.state.formChanged.questionAnswer) {
                    const transformAnswers = this.state.answers.map((x) => {
                        x.question_answer_id = x.id;
                        delete x.id;
                        delete x.description;
                        return x;
                    });
                    await this.putQuestionAnswers(transformAnswers);
                  
                }
    
                if (this.state.formChanged.preAnesthesiaAvailability) {
                    if (this.state.preAnesthesiaSettingExists) {
                        // Se a configuração existir, use PUT para atualizar
                        await Api.put(`/institutions/settings/26`, {
                            value: this.state.preAnesthesiaAvailability
                        });
                    } else {
                        // Se a configuração não existir, use POST para criar
                        await Api.post(`/institutions/settings`, {
                            institution_setting_id: 26,
                            value: this.state.preAnesthesiaAvailability
                        });
                    }
                    notification.success({
                        message: 'Configuração de disponibilidade do pré-anestésico salva com sucesso!',
                    });
                }
    
                this.setState({ loading: false });
            }
        } catch (error) {
            notification.error({
                message: 'Erro ao salvar configurações do pré-anestésico',
            });
            this.setState({ loading: false });
        }
    }
    
        
    handleChangeRequired = (checked, id) => {
      const sourceQuestionAnswers = this.state.dataQuestionsAswers;

      // Encontra o objeto correto em sourceQuestionAnswers
      const questionAnswer = sourceQuestionAnswers.find(
        (qa) => qa.questions.find((q) => q.id === id),
      );

      // Encontra o objeto correto em questions
      const question = questionAnswer.questions.find((q) => q.id === id);

      // Atualiza o valor de "required" no objeto encontrado
      question.required = checked;
      const answers = sourceQuestionAnswers.map((obj) => obj.questions).reduce(
        (acc, val) => acc.concat(val), [],
      );

      // Atualiza o estado com o novo valor de "dataQuestionsAswers"
      this.setState({ dataQuestionsAswers: sourceQuestionAnswers });
      this.setState({ answers: [...answers] });
      this.state.formChanged.questionAnswer = true;
    };

    handleChangeHide = (checked, id) => {
      const sourceQuestionAnswers = this.state.dataQuestionsAswers;

      // Encontra o objeto correto em sourceQuestionAnswers
      const questionAnswer = sourceQuestionAnswers.find(
        (qa) => qa.questions.find((q) => q.id === id),
      );

      // Encontra o objeto correto em questions
      const question = questionAnswer.questions.find((q) => q.id === id);

      // Atualiza o valor de "required" no objeto encontrado
      question.hide = checked;
      question.required = false;
      const answers = sourceQuestionAnswers.map((obj) => obj.questions).reduce(
        (acc, val) => acc.concat(val), [],
      );

      // Atualiza o estado com o novo valor de "dataQuestionsAswers"
      this.setState({ dataQuestionsAswers: sourceQuestionAnswers });
      this.setState({ answers: [...answers] });
      this.state.formChanged.questionAnswer = true;
    };

    onChange = (e) => {
      this.setState({
        value: e.target.value,
      });
    };

    createStateDataSource(data) {
      this.setState({
        dataQuestionsAswers: data,
        answers: data.map((obj) => obj.questions).reduce(
          (acc, val) => acc.concat(val), [],
        ),
        loading: false,
      });
    }

    handlePopstate = () => {
      if (this.state.formChanged.defaultOrientationPatientPre || this.state.formChanged.questionAnswer) {
        Modal.confirm({
          title: 'Atenção',
          content: 'Ao retornar os dados não serão salvos, deseja continuar?',
          okText: 'Sim',
          cancelText: 'Não',
          onOk: () => {
            this.props.history.push({
              pathname: window.history.back(),
            });
          },
        });
      }
    };
    handlePreAnesthesiaAvailabilityChange = (value) => {
      this.setState({ 
        preAnesthesiaAvailability: value,
        formChanged: {
          ...this.state.formChanged,
          preAnesthesiaAvailability: true
        }
      });
    };
    
    
    render() {
      const {
        defaultOrientationPatientPre,
        activatePre,
        preAnesthesiaAvailability,
      } = this.state;
      const preAnesthesiaOptions = [
        { value: '3', label: '3 meses' },
        { value: '6', label: '6 meses' },
        { value: '9', label: '9 meses' },
        { value: '12', label: '1 ano' },
        { value: '15', label: '1 ano e 3 meses' },
        { value: '18', label: '1 ano e 6 meses' },
        { value: '21', label: '1 ano e 9 meses' },
        { value: '24', label: '2 anos' }
      ];
      
      const institution = JSON.parse(localStorage.getItem('selected-institution'));
      const userInstitutions = JSON.parse(localStorage.getItem('user-permissions')).institutions;
      const findUserInstitution = userInstitutions.find((i) => i.id === institution.id);
      const user = JSON.parse(localStorage.getItem('user'));

      const sourceQuestionAnswers = this.state.dataQuestionsAswers;
      const { answers } = this.state;

      const tableQuestions = sourceQuestionAnswers.map((section) => {
        const columnsQuestionAnswers = [
          {
            title: stringsHelp.firstLetterUpper(section.description),
            dataIndex: 'description',
            key: '0',
            render: (text, record) => {
              const checkCondition = answers.find((q) => q.id === record.id).hide;
              return (
                <span style={{ textDecoration: checkCondition ? 'line-through' : 'none' }}>
                  {stringsHelp.firstLetterUpper(text)}
                </span>
              );
            },
          },
          {
            title: 'Obrigatória',
            dataIndex: 'required',
            key: '1',
            width: '10%',
            render: (required, record) => {
              const checkCondition = answers.find((q) => q.id === record.id).hide === true
                ? false : record.required;
              return (
                <Switch
                  disabled={answers.find((q) => q.id === record.id).hide}
                  defaultChecked={record.required}
                  checked={checkCondition}
                  checkedChildren="Sim"
                  unCheckedChildren="Não"
                  onChange={(checked) => this.handleChangeRequired(checked, record.id)}
                />
              );
            },
          },
          {
            title: 'Oculta',
            dataIndex: 'hide',
            key: '2',
            width: '10%',
            render: (hide, record) => (
              <Switch
                defaultChecked={record.hide}
                checkedChildren="Sim"
                unCheckedChildren="Não"
                onChange={(checked) => this.handleChangeHide(checked, record.id)}
              />
            ),
          },
        ];

        return (
          <Table
            loading={this.state.loading}
            pagination={false}
            columns={columnsQuestionAnswers}
            dataSource={section.questions}
          />
        );
      });

      if (this.state.loading) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Spin spinning={this.loading} />
          </div>
        );
      }
      return (
        <Content>
          <Form>
            <Row>
              <Col xs={24} sm={0}>
                <Button
                  type="primary"
                  className="add-procedure"
                  shape="circle"
                  size="large"
                  htmlType="submit"
                >
                  <Icon type="check" fill="#ffffff" />
                </Button>
              </Col>
              <Col xs={24} sm={20}>
                <h1 className="title">
                  Configurações de pré-anestésico
                </h1>
              </Col>
              <Col xs={0} sm={4}>
              <Button
  disabled={
    !this.state.formChanged.defaultOrientationPatientPre
    && !this.state.formChanged.questionAnswer
    && !this.state.formChanged.preAnesthesiaAvailability 
  }
  type="primary"
  className="add-procedure"
  loading={this.state.loading}
  onClick={this.handleSubmit} 
>
  SALVAR
</Button>


              </Col>
            </Row>
            <div style={{ margin: 20 }} />
            <Card
              title="Pré-anestésico"
              headStyle={{ backgroundColor: '#f2f2f2', paddingLeft: 16 }}
              size="default"
              bodyStyle={{ paddingTop: 5 }}
            >
              <Row gutter={28}>
                {/* TODO: Descomentar quando o app estiver pronto para usar essa config */}
                {/* <Row>
                  <Typography.Text
                    style={{
                      fontWeight: 400,
                      marginLeft: 11,

                    }}
                  >
                    Habilitar uso do pré anestésico no AxReg?
                    {' '}
                    <Switch
                        // eslint-disable-next-line camelcase
                      checked={activatePre}
                      onChange={(checked) => {
                        this.setState({ activatePre: checked });
                      }}
                      checkedChildren="Sim"
                      unCheckedChildren="Não"
                    />
                  </Typography.Text>
                </Row> */}
                <Row style={{ marginTop: '20px' }}>
                  <Typography.Text
                    style={{
                      fontWeight: 400,
                      marginLeft: 11,
                    }}
                  >
                    Recomendações padronizadas ao paciente:
                    {' '}

                  </Typography.Text>
                </Row>
                <Row style={{ marginLeft: 11 }}>
                  <textarea
                    style={{
                      height: '200px',
                      width: '80%',
                      maxWidth: '100%',
                      borderRadius: '20px',
                      border: this.state.focusedTextArea ? '3px solid #1DBFBB' : '3px solid #ccc',
                      outline: 'none',
                    }}
                    name="defaultOrientationPatientPre"
                    onFocus={() => this.setState({ focusedTextArea: true })}
                    onBlur={() => this.setState({ focusedTextArea: false })}
                    onChange={(e) => {
                      this.setState({ defaultOrientationPatientPre: e.target.value });
                      this.state.formChanged.defaultOrientationPatientPre = true;
                    }}
                    value={defaultOrientationPatientPre}
                  />
                </Row>
              </Row>
            </Card>
            <br />
            {
              (institution.is_group || institution.group_id === null || findUserInstitution)
                ? (
                  <Card
                    title="Personalização de documento"
                    headStyle={{ backgroundColor: '#f2f2f2', paddingLeft: 16 }}
                    size="default"
                    bodyStyle={{ paddingTop: 5 }}
                  >
                    <Row gutter={28}>
                      <Row style={{ marginTop: '20px' }}>
                        <Typography.Text
                          style={{
                            fontWeight: 400,
                            marginLeft: 11,
                          }}
                        >
                          Personalização do Termo de Consentimento Livre e Esclarecido (TCLE) |
                          <Button
                            style={{ border: 'none', padding: 7, color: '#1bbfbb' }}
                            onClick={() => this.props.history.push({
                              pathname: pathRoutes.pre_anesthesia_tcle,
                            })}
                          >
                            <Icon type="edit" />

                          </Button>
                          {' '}

                        </Typography.Text>
                      </Row>
                    </Row>
                  </Card>
                ) : null
            }
            <br />
<Card
  title="Disponibilidade do pré-anestésico"
  headStyle={{ backgroundColor: '#f2f2f2', paddingLeft: 16 }}
  size="default"
  bodyStyle={{ paddingTop: 5 }}
>
  <Row gutter={28}>
    <Row style={{ marginTop: '20px' }}>
      <Typography.Text
        style={{
          fontWeight: 400,
          marginLeft: 11,
        }}
      >
        Definição do tempo de disponibilidade do pré-anestésico para vinculo (em meses)
      </Typography.Text>
    </Row>
    <Row style={{ marginTop: '10px', marginLeft: 11 }}>
      <Select
        value={preAnesthesiaAvailability}
        onChange={this.handlePreAnesthesiaAvailabilityChange}
        style={{ width: 200 }}
      >
        {preAnesthesiaOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </Row>
  </Row>
</Card>

<br />
<Card
              title="Configuração das perguntas"
              headStyle={{ backgroundColor: '#f2f2f2', paddingLeft: 16 }}
              size="default"
              bodyStyle={{ paddingTop: 5 }}
            >
              <Row>
                <Row>
                  {tableQuestions}
                </Row>
              </Row>
            </Card>

          </Form>
        </Content>
      );
    }
}

function mapStateToProps(state) { return state; }

const connected = withRouter(connect(mapStateToProps)(Form.create()(PreAnesthesia)));
export default connected;
