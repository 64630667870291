import { List } from 'immutable';
import moment from 'moment';
import { schedulingConstants } from '../constants';

export function scheduling(state = {}, action) {
  switch (action.type) {
    case schedulingConstants.GETSCHEDULING_REQUEST:
      return {
        ...state,
        loading: true,
        data: new List(),
      };
    case schedulingConstants.GETSCHEDULING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case schedulingConstants.GETSCHEDULING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case schedulingConstants.GETPROCEDURE_REQUEST:
      return {
        ...state,
        procedure: {
          loading: true,
          data: {},
        },
      };
    case schedulingConstants.GETPROCEDURE_SUCCESS:
      return {
        ...state,
        procedure: {
          loading: false,
          data: action.data,
        },
      };
    case schedulingConstants.GETPROCEDURE_FAILURE:
      return {
        ...state,
        procedure: {
          loading: false,
          error: action.error,
        },
      };
    case schedulingConstants.CHANGE_MEDICAL_PLAN_INFO:
      return {
        ...state,
        medical_plan_info: action.data,
      };
    case schedulingConstants.SETDATE:
      return { ...state, date: moment(action.date) };
    default:
      return state;
  }
}
