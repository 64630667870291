export const institutionsConstants = {
  GETINSTITUTIONS_REQUEST: 'GETINSTITUTIONS_REQUEST',
  GETINSTITUTIONS_SUCCESS: 'GETINSTITUTIONS_SUCCESS',
  GETINSTITUTIONS_FAILURE: 'GETINSTITUTIONS_FAILURE',
  SELECT_INSTITUTION_REQUEST: 'SELECT_INSTITUTION_REQUEST',
  SELECT_INSTITUTION_SUCCESS: 'SELECT_INSTITUTION_SUCCESS',
  SELECT_INSTITUTION_FAILURE: 'SELECT_INSTITUTION_FAILURE',
  INSTITUTION_NEW_LOGO: 'INSTITUTION_NEW_LOGO',
  GETANSWER_QUESTION_REQUEST: 'GETANSWER_QUESTION_REQUEST',
  GETANSWER_QUESTION_SUCCESS: 'GETANSWER_QUESTION_SUCCESS',
  GETANSWER_QUESTION_FAILURE: 'GETANSWER_QUESTION_FAILURE',
};
