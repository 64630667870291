import {monitorQrcodesConstants} from '../constants';
import {monitorQrcodesService} from "../services";

export const monitorQrcodesActions = {
  getMonitorQrcodes,
};

function getMonitorQrcodes(filter, cancelToken) {
  return dispatch => {
    dispatch(request());

    return monitorQrcodesService.getMonitorQrcodes(filter, cancelToken)
      .then(monitorQrcodes => {        
        dispatch(success(monitorQrcodes));
        return monitorQrcodes;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: monitorQrcodesConstants.GETMONITOR_QRCODES_REQUEST}
  }

  function success(data) {
    return {type: monitorQrcodesConstants.GETMONITOR_QRCODES_SUCCESS, data}
  }

  function failure(error) {
    return {type: monitorQrcodesConstants.GETMONITOR_QRCODES_FAILURE, error}
  }
}
