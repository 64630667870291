/* eslint-disable no-use-before-define */
import { sectorsConstants } from '../constants';
import { sectorsService } from '../services';

export const sectorsActions = {
  getSectors,
};

function getSectors(filter, page, size, cancelToken) {
  return (dispatch) => {
    dispatch(request());

    return sectorsService.getSectors(filter, page, size, cancelToken)
      .then((sectors) => {
        console.log(sectors);

        dispatch(success(sectors));
        return sectors;
      }, (error) => dispatch(failure(error)));
  };

  function request() {
    return { type: sectorsConstants.GETSECTORS_REQUEST };
  }

  function success(data) {
    return { type: sectorsConstants.GETSECTORS_SUCCESS, data };
  }

  function failure(error) {
    return { type: sectorsConstants.GETSECTORS_FAILURE, error }; // Corrigido para sectorsConstants
  }
}
