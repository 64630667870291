import React, { useEffect, Component } from 'react';
import {
  Checkbox, Input, notification, Layout,
} from 'antd';
import { CancelToken } from 'axios';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { List } from 'immutable';
import { eventsActions } from '../actions';
import { eventsService } from '../services';
import Modal from './Modal';
import { stringsHelp } from '../helpers';

const { Content } = Layout;

class ImportTransEventPresets extends Component {
  cancelSource = CancelToken.source();

  state = {
    modalVisible: false,
    selected: new List(),
    tracks: new List(),
    hasMoreItems: true,
    searchInterval: null,
    loadingImport: false,
  };

  componentDidMount() {
    this.loadTransEventPresets(1);
  }

  handleVisible() {
    console.log(this.props.visible);
    console.log('Abriu');
    if (this.props.visible) {
      this.page = null;
      this.loadTransEventPresets(1);
    }
  }

  loadTransEventPresets = (page) => {
    const value = this.filter ? this.filter.input.value : '';
    const { dispatch } = this.props;

    if (this.searchingTransEventPresetsValue !== value || this.page !== page) {
      this.page = page;

      if (this.searchingTransEventPresets && this.searchingTransEventPresetsValue !== value) {
        this.cancelSource.cancel();
        this.cancelSource = CancelToken.source();
      }

      this.searchingTransEventPresets = true;
      this.searchingTransEventPresetsValue = value;

      if (!this.state.searchInterval) { clearTimeout(this.state.searchInterval); }

      const objFilter = {
        search: value,
      };

      this.state.searchInterval = setTimeout(() => {
        dispatch(eventsActions.getTransEventPresets(objFilter, page, 10, this.cancelSource.token))
          .then((presets) => {
            this.searchingTransEventPresets = false;

            if (presets.error) {
              return null;
            }
            let tracks;
            if (presets.page > 1) {
              tracks = this.state.tracks.concat(presets.result);
            } else {
              tracks = new List(presets.result);
            }
            return this.setState({
              tracks,
              hasMoreItems: presets.page < presets.pages,
            });
          });
      }, 1000);
    }
  };

  filterTransEventPresets = () => {
    this.setState({
      tracks: new List(),
      hasMoreItems: true,
    });

    this.loadTransEventPresets(1);
  };

  loadItems = () => {
    let { page } = this;
    page++;
    this.loadTransEventPresets(page);
  };

  changeSelected = (selected) => {
    if (this.props.onChange) { this.props.onChange(selected); }
    this.setState({ selected });
  };

  select = (event) => {
    const { value } = event.target;
    value.laterality = 1;
    this.changeSelected(this.state.selected.push(value));
  };

  deselect = (event) => {
    const { value } = event.target;
    const selected = this.state.selected.filter((v) => v.id !== value.id);

    this.changeSelected(selected);
  };

  handleSubmit = (e) => {
    const body = { preset_trans_events_id: [] };
    this.state.selected.map((v) => body.preset_trans_events_id.push(v.id));
    this.setState({ loadingImport: true });
    if (body.preset_trans_events_id.length > 0) {
      eventsService.postTransEventPreset(body).then((result) => {
        if (result[0].id > 0) {
          this.setState({ loadingImport: false });
          notification.success({
            message: 'Evento(s) importado(s) com sucesso',
          });

          this.handleClose();
        } else {
          throw new Error({ message: 'Não foi possível salvar o evento!' });
        }
      }).catch((error) => {
        console.log(error);
        notification.error({
          message: error,
        });
      });
    } else {
      notification.error({
        message: 'Nenhum evento selecionado!',
      });
    }
  }

  handleClose = (e) => {
    this.state.selected = new List();
    this.page = null;
    this.loadTransEventPresets(1);
    this.props.setVisible(false);
  }

  render() {
    const { presets } = this.props;
    const hasPresets = presets && presets.count > 0;

    return (
      <Modal
        title="PRESET"
        visible={this.props.visible}
        onCancel={() => this.handleClose()}
        onOk={() => this.handleSubmit()}
        onVisible={() => this.handleVisible()}
        footer={false}
        buttonOkText="IMPORTAR"
        buttonOkType="primary"
        loading={this.state.loadingImport}
      >
        <Input placeholder="Buscar" ref={(input) => this.filter = input} onKeyUp={this.filterTransEventPresets} />

        <div className={`form-list ${this.state.selected.size ? '' : 'hidden'}`} style={{ marginTop: '21px' }}>
          <div className="form-list-title">
            SELECIONADOS (
            {this.state.selected.size}
            )
          </div>
          {
              this.state.selected.size
                ? this.state.selected.map((material) => {
                  const name = stringsHelp.firstLetterUpper(material.name, true);
                  return (
                    <div className="form-list-item slide-in" key={material.id}>
                      <span className="form-list-description" title={`${name}`}>
                        <Checkbox defaultChecked value={material} onChange={this.deselect}>
                          {name}
                        </Checkbox>
                      </span>
                    </div>
                  );
                })
                : null
            }
        </div>
        <div className="form-list" style={{ marginTop: '21px', height: '500px', overflow: 'auto' }}>
          <div className="form-list-title">TODOS</div>

          {
              presets
                ? (
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={this.loadItems}
                    hasMore={this.state.hasMoreItems}
                    useWindow={false}
                  >
                    {
                    this.state.tracks
                      ? this.state.tracks.map((preset) => {
                        const name = stringsHelp.firstLetterUpper(preset.name, true);
                        if (this.state.selected.find((a) => a.id === preset.id)) {
                          return null;
                        }

                        return (
                          <div className="form-list-item slide-in" key={preset.id}>
                            <span className="form-list-description" title={`${name}`}>
                              <Checkbox value={preset} onChange={this.select}>
                                {name}
                              </Checkbox>
                            </span>
                          </div>
                        );
                      })
                      : null
                  }
                  </InfiniteScroll>
                ) : null
            }
          {
              !hasPresets
                ? (
                  <Content>
                    <h3 style={{ textAlign: 'center' }}>Nenhum evento para ser cadastrado.</h3>
                  </Content>
                ) : null
            }
        </div>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { events } = state;
  const { presets } = events;
  return {
    presets,
  };
}

const connected = connect(mapStateToProps)(ImportTransEventPresets);
export default connected;
