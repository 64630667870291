export const intitutionSettings = {
  CONFIG_ID_DEFAULT_TABLE_MEDICAL_PLANS: 1,
  CONFIG_ID_ENABLED_FOR_MONITORS: 2,
  CONFIG_ID_ENABLED_FOR_RESIDENTS: 3,
  CONFIG_ID_ENABLED_FOR_MV: 5,
  CONFIG_ID_ENABLED_SURGICAL_NOTICE_MV: 6,
  CONFIG_ID_HEALTHCHESS_LOGIN: 7,
  CONFIG_ID_HEALTHCHESS_PASSWORD: 8,
  CONFIG_ID_HEALTHCHESS_INTEGRATION_MAP: 9,
  CONFIG_ID_TIMEZONE: 10,
  CONFIG_ID_DRUGS_PRINT: 11,
  CONFIG_ID_SECURITY_CHECKLIST: 12,
  CONFIG_ID_NOTIFICATION_MAIL_PRE: 13,
  CONFIG_ID_INPORTATION_PRE: 14,
  CONFIG_ID_CONSUMATION_SEVOFLURANO_FOR_PDF: 14,
  CONFIG_ID_HEALTHCHESS_API: 16,
  CONFIG_ID_REQUIRED_REGISTER_NUMBER: 17,
};
