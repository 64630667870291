import React, { useEffect, useState } from 'react';
import {
  Row,
  Spin,
  Col,
  Input,
  Layout,
  Button,
  Table,
  Tooltip,
  Icon,
  Divider,
  notification,
  Modal,
} from 'antd';
import { Link } from 'react-router-dom';
import JoinInstitutionPopOver from '../JoinInstitutionPopOver';
import { pathRoutes } from '../../routes';
import Api from '../../helpers/api';

const { Search } = Input;
const { Content } = Layout;

export default function Institutions() {
  const [institutions, setInstitutions] = useState([]);
  const [institutionsForTable, setInstitutionsForTable] = useState([]);
  const [page, setPage] = useState(1);
  const [institutionsQuantity, setInstitutionsQuantity] = useState(0);
  const [searchField, setSearchField] = useState('');
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    modalVisible: false,
    confirmVisible: false,
    delete_id: null,
    delete_name: null,
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'CNPJ/CPF',
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: 'Ativo',
      dataIndex: 'active',
      key: 'active',
      filters: [
        {
          text: 'sim',
          value: true,
        },
        {
          text: 'não',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.active === value,
      render: (active) => (active === true ? 'Sim' : 'Não'),
    },
    {
      title: 'Homologado',
      dataIndex: 'homologated',
      key: 'homologated',
      filters: [
        {
          text: 'sim',
          value: true,
        },
        {
          text: 'não',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.homologated === value,
      render: (homologated) => (homologated === true ? 'Sim' : 'Não'),
    },
    {
      title: 'Grupo',
      dataIndex: 'is_group',
      key: 'is_group',
      filters: [
        {
          text: 'sim',
          value: true,
        },
        {
          text: 'não',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.is_group === value,
      render: (isGroup) => (isGroup === true ? 'Sim' : 'Não'),
    },
    {
      title: 'Rede',
      dataIndex: 'is_network',
      key: 'is_network',
      filters: [
        {
          text: 'sim',
          value: true,
        },
        {
          text: 'não',
          value: false,
        },
      ],
      filterMultiple: false,
      onFilter: (value, record) => record.is_network === value,
      render: (isNetwork) => (isNetwork === true ? 'Sim' : 'Não'),
    },
    {
      title: '',
      key: 'actions',
      align: 'right',
      dataIndex: 'id',
      width: 160,
      render: (id, record) => {
        let institutionUser;

        if (record.institution_users.length > 0) {
          institutionUser = record.institution_users[0];
        }

        return (
          <span>
            <JoinInstitutionPopOver
              institutionUser={institutionUser}
              institution={record}
            />
            <Divider type="vertical" />
            <Tooltip title="Editar">
              <Link to={pathRoutes.editInstitution.replace(':id', id)}>
                <Icon type="edit" />
              </Link>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title="Remover">
              <Button
                type="link"
                onClick={() => showConfirm(record.id, record.name)}
              >
                <Icon type="delete" />
              </Button>
            </Tooltip>
          </span>
        );
      },
    },
  ];

  async function loadInstitutionsQuantity() {
    await Api.get('/preset/v2/institutions/all/count', {
      params: {
        search: searchField,
      },
    }).then((response) => setInstitutionsQuantity(response));
  }

  async function loadInstitutions() {
    setLoading(true);
    await Api.get('/preset/v2/institutions/all', {
      params: {
        page,
        limit,
        search: searchField,
        order_by: 'name',
        asc: false,
      },
    })
      .then((resp) => {
        setInstitutions(resp);
        setInstitutionsForTable(resp);
        setLoading(false);
      })
      .catch((err) => {
        // console.error(err);
      });
  }

  function reload() {
    loadInstitutionsQuantity();
    loadInstitutions();
  }

  useEffect(() => {
    reload();
  }, []);

  useEffect(() => {
    reload();
  }, [limit, page]);

  // function filter(e) {
  //   const { value } = e.target;
  //   const auxInst = institutions.filter((i) => {
  //     if (i.name.toLowerCase().search(value.toLowerCase()) !== -1) return i;
  //     if (i.document && i.document.toLowerCase().search(value.toLowerCase()) !== -1) return i;
  //     if (i.id.toString(10).search(value) !== -1) return i;
  //     return false;
  //   });
  //   setInstitutionsForTable(auxInst);
  // }

  function showConfirm(id, name) {
    setState({
      confirmVisible: true,
      delete_id: id,
      delete_name: name,
    });
  }

  function handleDelete() {
    setLoading(true);
    Api
      .delete(`/preset/institutions/${state.delete_id}`)
      .then((result) => {
        reload();
        notification.success({
          message: 'Instituição removida com sucesso',
        });

        setState({
          confirmVisible: false,
          delete_id: null,
          delete_name: null,
        });
      }).catch(() => {
        // console.error(error);
        notification.error({
          message: 'Falha ao remover a instituição, tente novamente mais tarde!',
        });
      });
  }

  function changePage(pageProp) {
    setPage(pageProp);
  }

  return (
    <>
      <Content>
        <Row>
          <Col xs={24} sm={0}>
            <Link
              to={pathRoutes.newInstitution}
              className="ant-btn ant-btn-primary ant-btn-lg ant-btn-circle add-procedure"
            >
              <Icon type="plus" fill="#ffffff" />
            </Link>
          </Col>
          <Col xs={24} lg={24} sm={24} xl={16}>
            <h1 className="title">Instituições</h1>
          </Col>
          <Col xs={0} sm={24} lg={24}>
            <Col xs={24} sm={19} md={20} lg={21}>
              <Search
                placeholder="Buscar por instituição ou CNPJ/CPF ou ID"
                disabled={loading}
                style={{ width: '100%', marginBottom: 40, marginRight: 10 }}
                allowClear
                enterButton={false}
                onChange={(e) => setSearchField(e.target.value)}
                onPressEnter={reload}
                onSearch={reload}
              />
            </Col>
            <Col xs={0} sm={5} md={4} lg={3}>
              <Link to={pathRoutes.newInstitution} className="add-procedure">
                ADICIONAR
              </Link>
            </Col>
          </Col>
        </Row>
        <Row>
          {!loading ? (
            <Table
              size="midle"
              dataSource={institutionsForTable}
              columns={columns}
              pagination={{
                defaultPageSize: limit,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '50', '100'],
                current: page,
                defaultCurrent: 1,
                total: institutionsQuantity,
                onShowSizeChange: async (current, size) => {
                  setLimit(size);
                },
                onChange: (e) => changePage(e),

              }}
            />
          ) : (
            <Row style={{ textAlign: 'center', padding: 20 }}>
              <Spin />
            </Row>
          )}
        </Row>

        <Modal
          title=""
          visible={state.confirmVisible}
          onOk={() => handleDelete()}
          okText="Sim"
          okType="primary"
          cancelText="Não"
          confirmLoading={loading}
          onCancel={() => setState({ confirmVisible: false })}
        >
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            <span style={{ fontSize: 24, color: '#ffcd56', marginRight: 10 }}>
              <Icon type="question-circle" />
            </span>
            Confirma remover a instituição
            {' '}
            {state.delete_name}
            {' '}
            ?
          </p>
        </Modal>
      </Content>
    </>
  );
}
