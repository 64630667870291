import Api from "./api";

export async function findInstitutionConfig(settingId) {
  const config = await Api.get(`/institutions/settings/${settingId}`).catch(
    (e) => {
      console.error(e);
      return false;
    }
  );
  return config ? config.value : false;
}
