import React from 'react';
import {Avatar} from 'antd';
import {stringsHelp} from '../helpers';

export default function InstitutionCard (props) {
  const {logo, name} = props;

  return (
      <div
        className="ant-list-item-meta ant-group"
        style={{ marginTop: 10 }}
      >
        <div className="ant-list-item-meta-avatar">
          <Avatar
            shape="square"
            src={
              logo
                ? logo
                : null
            }
            className="institution-logo"
          >
            Logo
          </Avatar>
        </div>
        <div className="ant-list-item-meta-content">
          <h4
            className="ant-list-item-meta-title"
            style={{ marginBottom: -5 }}
          >
            {stringsHelp.firstLetterUpper(
              name,
              true
            )}
          </h4>
        </div>
      </div>
  )
}
