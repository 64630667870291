/* eslint-disable no-console */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import {
  Checkbox, Radio, Input, notification,
} from 'antd';
import { CancelToken } from 'axios';
import InfiniteScroll from 'react-infinite-scroller';
import { List } from 'immutable';
import { connect } from 'react-redux';
import Modal from './Modal';
import { selectsActions, schedulingActions } from '../actions';

class SelectMedicalPlans extends Component {
  cancelSource = CancelToken.source();

  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      selected: new List(),
      tracks: new List(),
      hasMoreItems: true,
      medicalPlansInfo: [],
      notSave: false,
    };
  }

  componentDidMount() {
    if (this.props.value) {
      this.changeSelected(new List(this.props.value));
    }
    this.loadMedicalPlans(1);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.changeSelected(new List(this.props.value));
    }
  }

  setModalVisible(modalVisible) {
    this.setState({ modalVisible });
  }

  loadMedicalPlans = async (page) => {
    const value = this.filter ? this.filter.input.value : '';
    const { dispatch } = this.props;

    if (this.searchingMedicalPlansValue !== value || this.page !== page) {
      this.page = page;

      if (this.searchingMedicalPlans && this.searchingMedicalPlansValue !== value) {
        this.cancelSource.cancel();
        this.cancelSource = CancelToken.source();
      }

      this.searchingMedicalPlans = true;
      this.searchingMedicalPlansValue = value;

      dispatch(selectsActions.getMultipleMedicalPlans(page, value, this.cancelSource.token))
        .then((medicalPlans) => {
          this.searchingMedicalPlans = false;

          if (medicalPlans.error) {
            return null;
          }
          let tracks;
          if (medicalPlans.page > 1) {
            tracks = this.state.tracks.concat(medicalPlans.result);
          } else {
            tracks = new List(medicalPlans.result);
          }
          return this.setState({
            tracks,
            hasMoreItems: medicalPlans.page < medicalPlans.pages,
          });
        });
    }
  };

  loadItems = () => {
    let { page } = this;
    page += page;
    this.loadMedicalPlans(page);
  };

  filterMedicalPlans = () => {
    this.setState({
      tracks: new List(),
      hasMoreItems: true,
    });

    this.loadMedicalPlans(1);
  };

  changeSelected = async (selected) => {
    const { dispatch } = this.props;
    if (this.props.onChange) { this.props.onChange(selected); }
    this.setState({ selected });

    this.setState({ notSave: false });

    if (this.state.selected) {
      const result = await this.state.selected
        .map((item) => (item.medical_plan
          ? item.medical_plan.medical_plan_table_id : item.medical_plan_table_id));
      await dispatch(schedulingActions.setMedicalPlanInfo(result.join()));
    }
  };

  select = (event) => {
    if (this.state.selected.size === 2) {
      notification.warning({
        message: 'Não é possível incluir mais de dois convênios.',
      });
      this.setState({ notSave: true });
    } else {
      this.setState({ sendValid: false });

      const { value } = event.target;
      value.laterality = 1;
      value.patient_medical_plan_code = '';
      value.password = '';
      this.changeSelected(this.state.selected.push(value));
    }
  };

  deselect = (event) => {
    if (this.state.selected.size > 2) {
      notification.warning({
        message: 'Não é possível incluir mais de dois convênios.',
      });
      this.setState({ notSave: true });
    }
    const { value } = event.target;
    const selected = this.state.selected.filter((v) => v.id !== value.id);

    if (value.medical_plan) {
      const removeMedicalPlan = this.state.medicalPlansInfo
        .filter((v) => v.medical_plan.id !== value.medical_plan.id);

      this.setState({ medicalPlansInfo: removeMedicalPlan });
    } else {
      const removeMedicalPlan = this.state.medicalPlansInfo.filter((v) => v.id !== value.id);

      this.setState({ medicalPlansInfo: removeMedicalPlan });
    }
    this.changeSelected(selected);
  };

  selectCode = async (event, index) => {
    this.setState({ sendValid: false });
    const { medicalPlansInfo, selected } = this.state;
    const { value } = event.target;

    medicalPlansInfo[index].patient_medical_plan_code = value;
    this.setState({ medicalPlansInfo });
    selected.patient_medical_plan_code = value;
    this.changeSelected(selected);
  };

  selectPassoword =async (event, index) => {
    this.setState({ sendValid: false });
    const { medicalPlansInfo, selected } = this.state;
    const { value } = event.target;

    medicalPlansInfo[index].password = value;
    selected.password = value;
    this.setState({ medicalPlansInfo });
    this.changeSelected(selected);
  };

  render() {
    return (
      <div className="form-list" style={{ width: '800px !important' }}>
        <div className="form-list-item-multiple-medical-plans-line" style={{ borderBottom: '0px !important' }}>
          <div className="form-list-description-multiple-medical-plans">CONVÊNIO(S)</div>

        </div>
        <div className="form-list">
          <div className="ant-form-item-required ant-form-item-no-colon">
            <button type="button" className="form-add-link" onClick={() => this.setModalVisible(true)}>
              + Adicionar convênio(s)
            </button>
          </div>
        </div>
        {
          this.state.selected.size > 0
            ? (
              <div className="form-list-item-multiple-medical-plans-line" style={{ borderBottom: '0px !important' }}>

                <div style={{ width: '352px', marginLeft: '250px' }}>MATRÍCULA DO CONVÊNIO</div>
                <div style={{ width: '352px', marginLeft: '10px' }}>SENHA DE AUTORIZAÇÃO</div>
              </div>
            )
            : null
        }

        <Input hidden />
        <Radio.Group style={{ width: '100%', display: 'block' }}>
          {
          this.state.selected.size > 0
            ? this.state.selected.map((procedureMedicalPlans, i) => {
              const medicalPlan = procedureMedicalPlans.medical_plan;
              if (!this.state.medicalPlansInfo.find((v) => v.id === procedureMedicalPlans.id)) {
                this.state.medicalPlansInfo.push(procedureMedicalPlans);
              }
              return (

                <div
                  className="form-list-item-multiple-medical-plans"
                  key={medicalPlan ? medicalPlan.id : procedureMedicalPlans.id}
                >
                  <div>
                    <span className="form-list-description-multiple-medical-plans">
                      {' '}
                      {medicalPlan ? medicalPlan.name : procedureMedicalPlans.name}
                    </span>
                  </div>
                  <div style={{ width: '352px', marginRight: '10px' }}>
                    <Input type="text" value={this.state.medicalPlansInfo[i].patient_medical_plan_code} label="MATRÍCULA DO CONVÊNIO" onChange={(event) => this.selectCode(event, i)} />
                  </div>
                  <div style={{ width: '352px', marginRight: '10px' }}>
                    <Input value={this.state.medicalPlansInfo[i].password} label="SENHA DE AUTORIZAÇÃO" onChange={(event) => this.selectPassoword(event, i)} />
                  </div>
                  <span
                    className="form-list-del"
                    onClick={() => this
                      .deselect({ target: { value: procedureMedicalPlans } })}
                  >
                    <i className="icon-close" />
                  </span>
                </div>
              );
            })
            : null
        }
        </Radio.Group>
        <Modal
          title="Convênios"
          visible={this.state.modalVisible}
          onCancel={() => this.setModalVisible(false)}
          onOk={() => this.setModalVisible(false)}
          footer={false}
          buttonOkText="SALVAR"
          buttonOkType="primary"
        >
          <Input placeholder="Buscar" ref={(input) => this.filter = input} onKeyUp={this.filterMedicalPlans} />

          <div className={`form-list ${this.state.selected.size ? '' : 'hidden'}`} style={{ marginTop: '21px' }}>
            <div className="form-list-title">
              SELECIONADOS (
              {this.state.selected.size}
              )
            </div>
            <Radio.Group style={{ width: '100%' }}>
              {
                this.state.selected.size
                  ? this.state.selected.map((procedureMedicalPlans) => {
                    const medicalPlan = procedureMedicalPlans.medical_plan;
                    return (
                      <div className="form-list-item slide-in" key={medicalPlan ? medicalPlan.id : procedureMedicalPlans.id} style={{ width: '100%' }}>
                        <span className="form-list-description" title={medicalPlan ? `${medicalPlan.name}` : `${procedureMedicalPlans.name}`} style={{ width: '100%' }}>
                          <Checkbox
                            defaultChecked
                            value={procedureMedicalPlans}
                            onChange={this.deselect}
                          >
                            {' '}
                            {medicalPlan ? medicalPlan.name : procedureMedicalPlans.name}
                          </Checkbox>
                        </span>
                      </div>
                    );
                  })
                  : null
              }
            </Radio.Group>
          </div>
          <div className="form-list" style={{ marginTop: '21px', height: '500px', overflow: 'auto' }}>
            <div className="form-list-title">TODOS</div>

            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={this.loadItems}
              hasMore={this.state.hasMoreItems}
              useWindow={false}
            >
              {
                      this.state.tracks
                        ? this.state.tracks.map((medicalPlans) => {
                          if (this.state.selected.find((a) => a.id === medicalPlans.id)) {
                            return null;
                          }

                          return (
                            <div className="form-list-item slide-in" key={medicalPlans.id}>
                              <span className="form-list-description" title={`${medicalPlans.name}`}>
                                {
                                  this.state.notSave ? (
                                    <div onClick={this.select}>
                                      <Checkbox disabled value={medicalPlans} onChange={this.select}>
                                        {' '}
                                        {medicalPlans.name}
                                      </Checkbox>
                                    </div>
                                  ) : (
                                    <Checkbox value={medicalPlans} onChange={this.select}>
                                      {' '}
                                      {medicalPlans.name}
                                    </Checkbox>
                                  )
                                }

                              </span>
                            </div>
                          );
                        })
                        : null
                    }
            </InfiniteScroll>

          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { scheduling } = state;
  const { medical_plan_info } = scheduling;
  return {
    medical_plan_info,
  };
}

const connected = connect(mapStateToProps)(SelectMedicalPlans);
export default connected;
