import { surgicalTeamsConstants } from '../constants';
import {List} from "immutable";

export function surgicalTeams(state = {}, action) {
  switch (action.type) {
    case surgicalTeamsConstants.GETSUGICALTEAMS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        data: new List()
      });
    case surgicalTeamsConstants.GETSUGICALTEAMS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data.data
      });
    case surgicalTeamsConstants.GETSUGICALTEAMS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    default:
      return state
  }
}
