/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { schedulingConstants } from '../constants';
import { schedulingService } from '../services';

export const schedulingActions = {
  getScheduling,
  getProcedure,
  setDate,
  setMedicalPlanInfo,
  deleteMedicalPlanInfo,
};

function getScheduling(date, cancelToken, institution) {
  return (dispatch) => {
    dispatch(request());

    return schedulingService.getScheduling(date, cancelToken).then(
      (scheduling) => {
        const filteredScheduling = filterForInstitution(scheduling, institution);
        dispatch(success(filteredScheduling));
        return filteredScheduling;
      },
      (error) => dispatch(failure(error)),
    );
  };

  function filterForInstitution(
    scheduling,
    { id: institutionId, is_group, is_clinic },
  ) {
    if (is_group || is_clinic) {
      return scheduling;
    }

    if (scheduling.surgeries) {
      const filteredSurgeries = scheduling.surgeries.data
        .map((sugeriesScheduling) => {
          const data = sugeriesScheduling.data.filter(
            ({ institution_id }) => institution_id === institutionId,
          );

          return { ...sugeriesScheduling, data };
        })
        .filter(({ data }) => data.length > 0);

      const surgeries = { ...scheduling.surgeries, data: filteredSurgeries };
      return { ...scheduling, surgeries };
    }

    return scheduling.filter(((d) => d.institution_id === institutionId));
  }

  function request() {
    return { type: schedulingConstants.GETSCHEDULING_REQUEST };
  }

  function success(data) {
    return { type: schedulingConstants.GETSCHEDULING_SUCCESS, data };
  }

  function failure(error) {
    return { type: schedulingConstants.GETSCHEDULING_FAILURE, error };
  }
}

function getProcedure(id, cancelToken) {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(request());

    return schedulingService
      .getProcedure(id, cancelToken)
      .then((procedure) => {
        dispatch(success(procedure));
        resolve(procedure);
      }, (error) => {
        dispatch(failure(error));
        reject(error);
      });
  });

  function request() {
    return { type: schedulingConstants.GETPROCEDURE_REQUEST };
  }

  function success(data) {
    return { type: schedulingConstants.GETPROCEDURE_SUCCESS, data };
  }

  function failure(error) {
    return { type: schedulingConstants.GETPROCEDURE_FAILURE, error };
  }
}

function setMedicalPlanInfo(data) {
  localStorage.setItem('medical-plans-table-id', JSON.stringify(data));
  return (dispatch) => {
    dispatch({ type: schedulingConstants.CHANGE_MEDICAL_PLAN_INFO, data });
  };
}

function deleteMedicalPlanInfo(data) {
  localStorage.removeItem('medical-plans-table-id');
  return (dispatch) => {
    dispatch({ type: schedulingConstants.CHANGE_MEDICAL_PLAN_INFO, data });
  };
}

function setDate(date) {
  localStorage.setItem('filter-date', date);

  return (dispatch) => {
    dispatch({ type: schedulingConstants.SETDATE, date });
  };
}
