export const numbers = {
    numberToMoneyBR,
    numberToDoubleDecimal
};

function numberToMoneyBR(value) {
    let result = "";

    if (value) {
      try {
        
        let v = value.toString().replace(/\D/g, "");
        v = (v / 100).toFixed(2) + "";
        console.log('VALUE BEFORE',value);
        console.log('VALUE', v);
        v = v.replace(".", ",");
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
        result = v;
        
      } catch (error) {
        console.log('ERROR', error);
      }
    
    }

    return result;
}


function numberToDoubleDecimal(value) {
    let result = 0.00;

    if (value) {
      try {
        
        let v = value.toString().replace(/\D/g, "");
        v = (v / 100).toFixed(2) + "";
        v = v.replace(".", ",");
        v = v.replace(/(\d)(\d{3})(\d{3}),/g, "$1.$2.$3,");
        v = v.replace(/(\d)(\d{3}),/g, "$1.$2,");
        result = parseFloat(v.replace('.','').replace(',','.'));
        
      } catch (error) {
        console.log('ERROR', error);
      }
    
    }

    return result;
}