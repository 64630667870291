/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/no-cycle */
import React, { Component } from 'react';

import {
  Layout,
  Row,
  Col,
  Form,
  Icon,
  Table,
  Input,
  Tag,
  Modal,
  Button,
  notification,
} from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CancelToken } from 'axios';
import moment from 'moment';
import { pathRoutes } from '../../routes';
import { anesthetistInviteActions } from '../../actions';
import { stringsHelp } from '../../helpers';
import { surgeonsService } from '../../services';
import ImportExcel from '../ImportExcel';
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const { Search } = Input;

class AnesthetistsInviteQueue extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    confirmVisible: false,
    confirmLoading: true,
    delete_uuid: undefined,
    dataAnesthetistsInviteSent: [],
    dataAnesthetistsNotSent: [],
  };

  cancelSource = CancelToken.source();

  componentDidMount() {
    mixPanelService.tracking(mixPanelService.MENU_CIRUGIAO);
    this.loadMap();
  }

  loadMap = async () => {
    const { dispatch } = this.props;

    const data = await dispatch(anesthetistInviteActions.getAnesthetistsInvite());
    this.setState({
      dataAnesthetistsInviteSent: data.filter((i) => i.is_sent),
      dataAnesthetistsNotSent: data.filter((i) => !i.is_sent),
      confirmLoading: false,
    });
  };

  showConfirm = (uuid) => {
    this.setState({
      confirmVisible: true,
      confirmLoading: false,
      delete_uuid: uuid,
    });
  };

  handleDelete = () => {
    let promiseSave;

    if (this.state.delete_uuid) {
      this.setState({ confirmLoading: true });

      promiseSave = surgeonsService
        .deleteSurgeon(this.state.delete_uuid)
        .then(() => {
          notification.success({
            message: 'Cirurgião removido com sucesso!',
          });

          this.loadMap();

          this.setState({
            confirmLoading: false,
            confirmVisible: false,
          });
        });

      promiseSave.catch(() => {
        notification.error({
          message: 'Falha ao remover cirurgião, tente novamente mais tarde!',
        });
      });
    }
  };

  searchTableSent = (e) => {
    const { anesthetistsInvite } = this.props;
    const text = e.target.value;

    setTimeout(() => {
      if (text.length) {
        const newDataAnesthetistsInvite = anesthetistsInvite.data.filter((x) => (
          stringsHelp
            .removeAccent(x.name.toString().toLowerCase())
            .includes(stringsHelp.removeAccent(text.toLowerCase()))
          || (x.email
            && stringsHelp
              .removeAccent(x.email.toString().toLowerCase())
              .includes(stringsHelp.removeAccent(text.toLowerCase())))
        ));
        this.setState({ dataAnesthetistsInviteSent: newDataAnesthetistsInvite });
      } else {
        this.setState({ dataAnesthetistsInviteSent: anesthetistsInvite.data });
      }
    }, 300);
  };

  searchTableNotSent = (e) => {
    const { anesthetistsInvite } = this.props;
    const text = e.target.value;

    setTimeout(() => {
      if (text.length) {
        const anesthetistsInviteNotSent = anesthetistsInvite.data.filter((i) => !i.is_sent);
        const newDataAnesthetistsInvite = anesthetistsInviteNotSent.filter((x) => (
          stringsHelp
            .removeAccent(x.name.toString().toLowerCase())
            .includes(stringsHelp.removeAccent(text.toLowerCase()))
          || (x.email
            && stringsHelp
              .removeAccent(x.email.toString().toLowerCase())
              .includes(stringsHelp.removeAccent(text.toLowerCase())))
        ));
        this.setState({ dataAnesthetistsNotSent: newDataAnesthetistsInvite });
      } else {
        this.setState({ dataAnesthetistsNotSent: anesthetistsInvite.data });
      }
    }, 300);
  };

  retry = async () => {
    const { dispatch } = this.props;
    await dispatch(anesthetistInviteActions.retryAnesthetistsInvite());
    await this.loadMap();
  }

  render() {
    const { confirmLoading } = this.state;
    const { anesthetistsInvite } = this.props;
    const columnsTableAnesthetist = [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        render: (name) => stringsHelp.firstLetterUpper(
          name,
          true,
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Data',
        dataIndex: 'created_at',
        key: 'created_at',
        render: (date) => moment(date).format('DD/MM/YYYY HH:mm:ss'),
      },
      // {
      //   title: '',
      //   key: 'actions',
      //   align: 'right',
      //   dataIndex: 'id',
      //   width: 80,
      //   render: (id, record) => (
      //     <span>
      //       <Tooltip title="Editar">
      //         <Link to={`${pathRoutes.editSurgeon.replace('/:id', '')}/${id}`}>
      //           <Icon type="edit" />
      //         </Link>
      //       </Tooltip>
      //       <Divider type="vertical" />
      //       <Tooltip title="Remover">
      //         <Button type="link" onClick={() => this.showConfirm(record.uuid)}>
      //           <Icon type="delete" />
      //         </Button>
      //       </Tooltip>
      //     </span>
      //   ),
      // },
    ];

    return (
      <Content>
        <Row>

          <Col xs={24} sm={8}>
            <h1 className="title">Anestesistas - Emails enviados</h1>
          </Col>
          <Col xs={24} sm={0}>
            <Search
              disabled={confirmLoading}
              placeholder="Buscar"
              onChange={this.searchTableSent}
              style={{ width: '100%', marginRight: 10, marginBottom: 10 }}
            />
          </Col>
          <Col xs={0} sm={16}>
            <div style={{ display: 'flex' }}>
              <Search
                disabled={confirmLoading}
                placeholder="Buscar"
                onChange={this.searchTableSent}
                style={{ width: '100%', marginRight: 10 }}
              />
              <ImportExcel
                table="anesthetists-invite"
                title="Anestesistas"
                reload={this.loadMap}
                restrictGroup="true"
              />

            </div>
          </Col>
        </Row>
        <Row>
          <Table
            size="midle"
            loading={anesthetistsInvite.loading}
            dataSource={this.state.dataAnesthetistsInviteSent}
            columns={columnsTableAnesthetist}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '30', '50', '100'],
            }}
            footer={() => {
              const rowsDataSource = !this.state.dataAnesthetistsInviteSent
                ? 0
                : this.state.dataAnesthetistsInviteSent.length;
              return (
                <span>
                  Total de registros:
                  {' '}
                  <Tag>{rowsDataSource}</Tag>
                </span>
              );
            }}
          />
        </Row>

        <Row
          style={{ marginTop: '20px' }}
        >
          <Col xs={24} sm={8}>
            <h1 className="title">Anestesistas - Emails Não Enviados</h1>
          </Col>
          <Col xs={24} sm={0}>
            <Search
              placeholder="Buscar"
              disabled={confirmLoading}
              onChange={this.searchTableNotSent}
              style={{ width: '100%', marginRight: 10, marginBottom: 10 }}
            />
          </Col>
          <Col xs={0} sm={16}>
            <div style={{ display: 'flex' }}>
              <Search
                disabled={confirmLoading}
                placeholder="Buscar"
                onChange={this.searchTableNotSent}
                style={{ width: '100%', marginRight: 10 }}
              />
              <Button
                className="add-procedure"
                onClick={() => this.retry()}
              >
                <span>REENVIAR</span>
              </Button>

            </div>
          </Col>
        </Row>

        <Row>
          <Table
            size="midle"
            loading={anesthetistsInvite.loading}
            dataSource={this.state.dataAnesthetistsNotSent}
            columns={columnsTableAnesthetist}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '30', '50', '100'],
            }}
            footer={() => {
              const rowsDataSource = !this.state.dataAnesthetistsNotSent
                ? 0
                : this.state.dataAnesthetistsNotSent.length;
              return (
                <span>
                  Total de registros:
                  {' '}
                  <Tag>{rowsDataSource}</Tag>
                </span>
              );
            }}
          />
        </Row>

      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { anesthetistsInvite } = state;
  return {
    anesthetistsInvite,
  };
}

const connected = connect(mapStateToProps)(Form.create()(AnesthetistsInviteQueue));
export default connected;
