import React, { Component } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Icon,
  Input,
  Layout,
  Row,
  Tooltip,
  notification,
  Alert,
  Progress
} from "antd";
import { connect } from "react-redux";
import { usersService } from "../../services";
import iconHeader from "../../assets/imgs/axreg-logo.png";
import iconColor from "../../assets/imgs/icon_color.png";

const { Content, Footer } = Layout;
const FormItem = Form.Item;

class ResetPassword extends Component {
   state = {
    loading: false,
    confirmDirty: false,
    containerSuccess: false
  };

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("A confirmação de senha não confere!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  };

  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const passwordPattern = new RegExp(
          "(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,}"
        );
        let promiseSave;

        if (passwordPattern.test(values.password)) {
          this.setState({ loading: true });

          let params = {
            hash_validation: this.props.match.params.hash_validation,
            password_new: values.password
          };

          promiseSave = usersService.putPasswordUpdate(params).then(res => {
            this.setState({ loading: false });

            if (res === "hash.not.found") {
              notification.error({
                message: "Usuário não encontrado em nossa base de dados."
              });

              this.setState({ loading: false, containerSuccess: false });
            } else {
              this.setState({ loading: false, containerSuccess: true });
            }
          });

          promiseSave.catch(error => {
            console.log(error);
            this.setState({ loading: false });
            notification.error({
              message:
                "Falha ao resetar senha, entre em contato com suporte técnico!"
            });
          });
        } else {
          notification.error({
            message:
              "A senha deve conter no mínimo 8 caracteres contendo: Números, Letras Maiúsculas, Letras minúsculas e Caracteres especiais."
          });

          this.setState({ loading: false });
          return false;
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <Layout
          className="login-page"
          style={{ minHeight: "100vh", padding: 0 }}
        >
          <Content style={{ padding: "0 10px" }}>
            <Row type="flex" justify="center" align="middle">
              <Col xs={0} sm={2} md={3} lg={6} xl={7} xxl={8} />
              <Col
                xs={24}
                sm={20}
                md={18}
                lg={12}
                xl={10}
                xxl={8}
                style={{ paddingTop: "50px" }}
              >
                {this.state.containerSuccess ? (
                  <div style={{ padding: "30px" }} className="login-form">
                    <Card
                      title={
                        <div style={{ textAlign: "center" }} className="logo">
                          <img
                            src={iconHeader}
                            alt="Logo AxReg | Agendamento"
                            className="logo-axreg-invite"
                          />
                          <Divider style={{ color: "#343f5c" }}>
                            Resetar senha
                          </Divider>
                        </div>
                      }
                      bordered={false}
                      style={{ width: "100%" }}
                    >
                      <Col span={24} style={{ textAlign: "center", paddingBottom: 20 }}>
                        <Progress type="circle" percent={100} />
                        <h2 style={{ marginTop: 10, color: "#343f5c" }}>
                          Senha resetada com sucesso!
                        </h2>
                        <p>Faça seu login e volte utilizar nossa plataforma</p>
                      </Col>
                    </Card>
                  </div>
                ) : (
                  <div style={{ padding: "30px" }} className="login-form">
                    <Card
                      title={
                        <div style={{ textAlign: "center" }} className="logo">
                          <img
                            src={iconHeader}
                            alt="Logo AxReg | Agendamento"
                            className="logo-axreg-invite"
                          />
                          <Divider style={{ color: "#343f5c" }}>
                            Resetar senha
                          </Divider>

                          <Alert
                            style={{ fontWeight: 400 }}
                            message="A senha deve conter no mínimo 8 caracteres contendo: Números, Letras Maiúsculas, 
      Letras minúsculas e Caracteres especiais."
                            type="warning"
                          />
                        </div>
                      }
                      bordered={false}
                      style={{ width: "100%" }}
                    >
                      <Form onSubmit={this.handleSubmit} className="login-form">
                        <FormItem>
                          {getFieldDecorator("password", {
                            rules: [
                              {
                                required: true,
                                message: "Digite a nova senha!"
                              },
                              {
                                validator: this.validateToNextPassword
                              }
                            ]
                          })(
                            <Input
                              prefix={
                                <Icon
                                  type="lock"
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              type="password"
                              placeholder="Nova senha"
                            />
                          )}
                        </FormItem>
                        <FormItem>
                          {getFieldDecorator("confirm", {
                            rules: [
                              {
                                required: true,
                                message: "Digite a confirmação de senha!"
                              },
                              {
                                validator: this.compareToFirstPassword
                              }
                            ]
                          })(
                            <Input
                              prefix={
                                <Icon
                                  type="lock"
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              type="password"
                              placeholder="Confirmação de senha"
                              onBlur={this.handleConfirmBlur}
                            />
                          )}
                        </FormItem>
                        <FormItem>
                          <Button
                            block
                            type="primary"
                            loading={this.state.loading}
                            htmlType="submit"
                            className="btn-custom-primary"
                            style={{ float: "rigth" }}
                          >
                            Atualizar senha
                          </Button>
                        </FormItem>
                      </Form>
                    </Card>
                  </div>
                )}
              </Col>
              <Col xs={0} sm={2} md={3} lg={6} xl={7} xxl={8} />
            </Row>
          </Content>
          <Footer
            style={{ position: "relative", bottom: "0", textAlign: "center" }}
          >
            Powered by
            <Tooltip title="Anestech">
              {" "}
              <a
                href="http://www.anestech.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={iconColor} alt="Logo" />{" "}
              </a>
            </Tooltip>
          </Footer>
        </Layout>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { alert, institutions } = state;
  return {
    loggingIn,
    alert,
    institutions
  };
}

const connectedLoginPage = connect(mapStateToProps)(
  Form.create()(ResetPassword)
);
export default connectedLoginPage;
