import React from 'react';
import {Button, Icon, notification, Popover} from 'antd';
import {usersService} from '../services';

export default function JoinInstitutionPopOver (props) {
  const {institution, institutionUser} = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [isOnInstitution, setIsOnInstitution] = React.useState(false);

  React.useEffect(() => {
    return setIsOnInstitution(!!((institutionUser || {}).active));
  }, [institutionUser, setIsOnInstitution]);

  const addOrRemoveUser = () => {
    setIsLoading(true);
    let promise;
    let successMsg;
    let errorMsg;
    let user = JSON.parse(localStorage.getItem('user'));

    if (isOnInstitution) {
      successMsg = 'Usuário removido com sucesso!';
      errorMsg = 'Erro ao remover usuário.';
      promise = usersService.removeUser(user.id, institution.id);
    } else {
      successMsg = 'Usuário adicionado com sucesso!';
      errorMsg = 'Erro ao adicionar usuário.';
      promise = usersService.addToInstitution({
        institution_id: institution.id,
        is_anaesthetist: true,
        is_admin: true,
        is_dashboard: true
      });
    }

    promise.then(() => {
      setIsOnInstitution(!isOnInstitution);
      setIsLoading(false);
      notification.success({
        message: successMsg
      })
    }).catch((error) => {
      setIsLoading(false);
      console.error(error);
      notification.error({
        message: errorMsg
      });
    }).finally(() => {
      setTimeout(() =>window.location.reload(), 2000 )
    })
  };

  return (
    <Popover
      content={
        <Button
          type="primary"
          loading={isLoading}
          onClick={addOrRemoveUser}
          block
          size="small"
        >
          Confirmar
        </Button>
      }
      title={isOnInstitution ? "Deseja sair da instituição?" : "Deseja entrar na instituição?"}
      trigger="hover"
    >
      <Icon
        type={isOnInstitution ? 'user-delete' : 'user-add' }
        style={{color: '#1bbfbb'}}
      />
    </Popover>
  )
}
