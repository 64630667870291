import { List } from 'immutable';
import { eventsConstants } from '../constants';

export function events(state = {}, action) {
  switch (action.type) {
    case eventsConstants.GETEVENTS_REQUEST:
      return {
        ...state,
        loading: true,
        data: new List(),
      };
    case eventsConstants.GETEVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case eventsConstants.GETEVENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case eventsConstants.GETEVENT_PRESETS_FAILURE:
      return {
        ...state,
        presets: {
          loading: false,
          data: new List(),
          error: action.error,
        },
      };
    case eventsConstants.GETEVENT_PRESETS_REQUEST:
      return {
        ...state,
        presets: {
          loading: true,
          data: new List(),
        },
      };
    case eventsConstants.GETEVENT_PRESETS_SUCCESS:
      return {
        ...state,
        presets: {
          loading: false,
          data: action.data.result,
          count: action.data.count,
          page: action.data.page,
          perPage: action.data.perPage,
        },
      };
    default:
      return state;
  }
}
