import Api from '../helpers/api';

export const roomsService = {
  postRoom,
  putRoom,
  getRoom,
  deleteRoom
};


function postRoom(room){
  return Api.post(`/preset/surgery-center-rooms`, room)
}

function putRoom(room){
  return Api.put(`/preset/surgery-center-rooms/${room.uuid}`, room)
}

function getRoom(uuid, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/preset/surgery-center-rooms/uuid/${uuid}`, {cancelToken})
}

function deleteRoom(uuid){
  return Api.delete(`/preset/surgery-center-rooms/${uuid}`)
}