import React, { useState, useEffect } from 'react';
import {
  Modal,
  Typography,
  Divider,
  Spin,
} from 'antd';
import Api from '../../helpers/api';
import lgpd from '../../assets/imgs/lgpd.png';
import { authenticationService } from '../../services/authentication.service';

const styles = {
  bodyStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 16,
  },
  title: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000000',
    letterSpacing: 'normal',
    fontFamily: 'Montserrat',
    marginBottom: 16,
  },
  divider: {
    marginTop: 0,
    paddingTop: 0,
    height: 1,
    backgroundColor: '#C4C4C4',
    marginBottom: 34,

  },
  image: {
    marginBottom: '24px',
  },
  termsText: {
    fontFamily: 'Montserrat',
    fontSize: 12,
    marginBottom: '24px',
    color: '#000000',
  },
  termsLink: {
    fontWeight: 500,
  },
  acceptButton: {
    backgroundColor: '#1bbfbb',
    padding: 8,
    color: '#fff',
    border: 0,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 12,
    borderRadius: 5,
    cursor: 'pointer',
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    width: 168,
  },
  returnToLoginButton: {
    fontWeight: 500,
    marginTop: 16,
    fontFamily: 'Montserrat',
    fontSize: 12,
  },
  errorMessage: {
    color: 'red',
    marginBottom: 0,
    fontFamily: 'Montserrat',
  },
};

export const AcceptTerms = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const userId = user.id;

  useEffect(() => {
    Api.get(`/app/users/${userId}/terms/status`, {
      params: {
        platform: 'maneger',
      },
    })
      .then((response) => {
        if (response.status === 'PENDING') setVisible(true);
      });
  }, []);

  async function acceptTerms() {
    setLoading(true);
    await Api.put(`/app/users/${userId}/terms/accept`, {
      platform: 'maneger',
    }).then(() => {
      setVisible(false);
      setLoading(false);
    }).catch(() => {
      Modal.error({
        title: 'Erro ao aceitar os termos',
      });
      setLoading(false);
    });
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      bodyStyle={styles.bodyStyle}
    >

      <p style={styles.title}>
        TERMOS DE USO E POLÍTICA DE PRIVACIDADE
      </p>
      <Divider
        style={styles.divider}
      />
      <img
        src={lgpd}
        width="400px"
        height="260"
        alt="LGPD"
        style={styles.image}
      />

      <p
        style={styles.termsText}
        strong
      >
        Para prosseguir com seu acesso é necessário aceitar os novos
        {' '}
        <a
          href="https://anestech.com.br/politica-de-privacidade/"
          target="framename"
          style={styles.termsLink}
        >
          Termos de Uso e Política de Privacidade
        </a>
        .
      </p>
      {loading
        ? <Spin />
        : (
          <button
            type="submit"
            onClick={acceptTerms}
            style={styles.acceptButton}
          >
            Aceitar e continuar
          </button>
        )}
      <a
        style={styles.returnToLoginButton}
        onClick={() => { authenticationService.logout(); }}
      >
        Voltar à tela de login
      </a>
    </Modal>
  );
};
