import { List } from 'immutable';
import { surgeonsConstants } from '../constants';

export function drugs(state = {}, action) {
  switch (action.type) {
    case surgeonsConstants.GETSURGEONS_REQUEST:
      return {
        ...state,
        loading: true,
        data: new List(),
      };
    case surgeonsConstants.GETSURGEONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    case surgeonsConstants.GETSURGEONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
