import {surgicalTeamsConstants} from '../constants';
import {surgicalTeamsService} from "../services";

export const surgicalTeamsActions = {
  getSurgicalTeams,
};

function getSurgicalTeams(filter, cancelToken) {
  return dispatch => {
    dispatch(request());

    return surgicalTeamsService.getSurgicalTeams(filter, cancelToken)
      .then(surgicalTeams => {        
        dispatch(success(surgicalTeams));
        return surgicalTeams;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: surgicalTeamsConstants.GETSUGICALTEAMS_REQUEST}
  }

  function success(data) {
    return {type: surgicalTeamsConstants.GETSUGICALTEAMS_SUCCESS, data}
  }

  function failure(error) {
    return {type: surgicalTeamsConstants.GETSUGICALTEAMS_FAILURE, error}
  }
}
