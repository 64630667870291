import { anesthetistsInviteConstants } from '../constants';
import { anesthetistsInviteService } from '../services';

function getAnesthetistsRequest() {
  return { type: anesthetistsInviteConstants.GET_ANESTHETISTS_INVTE_REQUEST };
}

function getAnesthetistsSuccess(data) {
  return { type: anesthetistsInviteConstants.GET_ANESTHETISTS_INVTE_SUCCESS, data };
}

function getAnesthetistsError(error) {
  return { type: anesthetistsInviteConstants.GET_ANESTHETISTS_INVTE_FAIL, error };
}

function retryAnesthetistsRequest() {
  return { type: anesthetistsInviteConstants.RETRY_ANESTHETISTS_INVITE_REQUEST };
}

function retryAnesthetistsError(error) {
  return { type: anesthetistsInviteConstants.RETRY_ANESTHETISTS_INVITE_FAIL, error };
}

function getAnesthetistsInvite() {
  return (async (dispatch) => {
    try {
      await dispatch(getAnesthetistsRequest());

      const anesthetists = await anesthetistsInviteService.getAnesthetists();
      await dispatch(getAnesthetistsSuccess(anesthetists));
      return anesthetists;
    } catch (err) {
      await dispatch(getAnesthetistsError);
    }
  });
}

function retryAnesthetistsInvite() {
  return (async (dispatch) => {
    try {
      await dispatch(retryAnesthetistsRequest());
      await anesthetistsInviteService.retryInviteAnesthetists();
      await dispatch(getAnesthetistsInvite());
    } catch (err) {
      await dispatch(retryAnesthetistsError(err));
    }
  });
}

export const anesthetistInviteActions = {
  getAnesthetistsInvite,
  retryAnesthetistsInvite,
};
