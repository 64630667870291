/* eslint-disable camelcase */
/* eslint-disable max-len */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

import {
  Layout,
  Row,
  Col,
  Tooltip,
  Icon,
  Table,
  Button,
  Input,
  Tag,
  Modal,
  Form,
  notification,
  Divider,
  Checkbox,
  Switch,
  DatePicker,
  Card,
  Popover,
  Select,
} from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { CancelToken } from 'axios';
import MaskedInput from 'react-text-mask';
import { usersActions } from '../../actions';
import { logService, usersService } from '../../services';
import LogoLogBook from '../../assets/imgs/logbook.png';
import LogoLogSba from '../../assets/imgs/logsba.png';
import { stringsHelp } from '../../helpers';
import SelectMultipleInstitutions from '../SelectMultipleInstitutions';
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const FormItem = Form.Item;
const { Search } = Input;
const cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
const phoneMask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
const crmStates = [
  { label: 'Selecionar Estado', value: '' },
  { label: 'Acre', value: '1' },
  { label: 'Alagoas', value: '2' },
  { label: 'Amapá', value: '3' },
  { label: 'Amazonas', value: '4' },
  { label: 'Bahia', value: '5' },
  { label: 'Ceará', value: '6' },
  { label: 'Distrito Federal', value: '7' },
  { label: 'Espírito Santo', value: '8' },
  { label: 'Goiás', value: '9' },
  { label: 'Maranhão', value: '10' },
  { label: 'Mato Grosso', value: '11' },
  { label: 'Mato Grosso do Sul', value: '12' },
  { label: 'Minas Gerais', value: '13' },
  { label: 'Pará', value: '14' },
  { label: 'Paraíba', value: '15' },
  { label: 'Paraná', value: '16' },
  { label: 'Pernambuco', value: '17' },
  { label: 'Piauí', value: '18' },
  { label: 'Rio de Janeiro', value: '19' },
  { label: 'Rio Grande do Norte', value: '20' },
  { label: 'Rio Grande do Sul', value: '21' },
  { label: 'Rondônia', value: '22' },
  { label: 'Roraima', value: '23' },
  { label: 'Santa Catarina', value: '24' },
  { label: 'São Paulo', value: '25' },
  { label: 'Sergipe', value: '26' },
  { label: 'Tocantins', value: '27' },
  { label: 'Estrangeiro', value: '28' },
];

class Users extends Component {
  cancelSource = CancelToken.source();

  constructor() {
    super();
    this.state = {
      modalVisible: false,
      loadingSend: false,
      confirmVisible: false,
      confirmLoading: false,
      delete_id: null,
      is_resident: false,
      edit_institution_id: null,
      delete_institution_id: null,
      dataUsers: [],
      oldUser: null,
      usersCount: 0,
      userSeach: '',
      page: 1,
      limit: 10,
      isUserActive: false,
    };
  }

  componentDidMount() {
    mixPanelService.tracking(mixPanelService.MENU_USUARIO);
    this.load();
  }

  getStateNameById = (id) => {
    const state = crmStates.find((state) => state.value === id.toString());
    return state ? state.label : '';
  };

  getIdByStateName = (name) => {
    const state = crmStates.find((state) => state.label === name);
    return state ? state.value : '';
  };

  validatorCPF = (rule, value, callback) => {
    if (!value || value === '' || value === undefined) {
      callback();
      return null;
    }

    const cpf = value.replace(/[^\d]+/g, '');

    const isRepeatingChars = (str) => str.split('').every((elem) => elem === str[0]);

    if (cpf.length !== 11 || isRepeatingChars(cpf)) {
      callback(rule.message);
    }

    let add = 0;

    for (let i = 0; i < 9; i++) {
      add += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }
    if (rev !== parseInt(cpf.charAt(9))) {
      callback(rule.message);
    }

    add = 0;
    for (let i = 0; i < 10; i++) {
      add += parseInt(cpf.charAt(i)) * (11 - i);
    }
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11) {
      rev = 0;
    }
    if (rev !== parseInt(cpf.charAt(10))) {
      callback(rule.message);
    }

    callback();
    return null;
  };

  load = () => {
    const { dispatch, users } = this.props;

    if (users.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    dispatch(usersActions.getUsers(
      this.state.userSeach,
      this.cancelSource.token,
      this.state.page,
      this.state.limit,
    )).then((data) => {
      this.setState({
        dataUsers: data,
      });
    });

    usersService.getUsersCount('').then((data) => { this.setState({ usersCount: data }); }).catch((error) => {});
  };

  handleInviteResend = (obj) => {
    if (obj) {
      try {
        this.setState({ loadingSend: true });
        let promiseSave;
        const values = {};

        values.email = obj.email.trim();
        values.expiration_date = obj.institutions[0].institution_user.expiration_date;
        values.is_anaesthetist = obj.institutions[0].institution_user.is_anaesthetist;
        values.is_dashboard = obj.institutions[0].institution_user.is_dashboard;
        values.is_manager = obj.institutions[0].institution_user.is_manager;
        values.is_network = obj.institutions[0].institution_user.is_network;
        values.is_resident = obj.institutions[0].institution_user.is_resident;
        values.institution_ids = obj.institutions.map((i) => i.id);

        promiseSave = usersService.postInvite(values).then((result) => {
          if (result.id > 0) {
            notification.success({
              message: 'Convite reenviado com sucesso!',
            });

            this.setState({ loadingSend: false });
          }
        });

        promiseSave.catch((error) => {
          console.log(error);
          this.setState({ loadingSend: false });
          notification.error({
            message:
              'OPS! Falha ao reenviar convite, tente novamente mais tarde!',
          });
        });
      } catch (error) {
        console.log(error);
        this.setState({ loadingSend: false });
        notification.error({
          message:
            'OPS! Erro ao reenviar convite, envie um ticket para suporte técnico!',
        });
      }
    }
  };

  handleCrmStateChange = (value) => {
    this.props.form.setFieldsValue({
      crmStateId: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (isNaN(values.crmStateId)) {
          values.crmStateId = this.getIdByStateName(values.crmStateId);
        }

        if (!values.institution_ids || values.institution_ids.length === 0) {
          values.institution_ids = [JSON.parse(localStorage.getItem('selected-institution')).id];
        }

        values.expiration_date = values.expiration_date
          ? values.expiration_date.endOf('day')
          : null;
        let promiseSave;

        values.is_anaesthetist = values.type.find((x) => x === 'is_anaesthetist')
          ? 1
          : 0;
        values.is_dashboard = values.type.find((x) => x === 'is_dashboard')
          ? 1
          : 0;
        values.is_network = values.type.find((x) => x === 'is_network')
          ? 1
          : 0;
        values.is_manager = values.type.find((x) => x === 'is_manager') ? 1 : 0;
        values.is_admin = values.type.find((x) => x === 'is_admin') ? 1 : 0;

        // verify is resident
        values.is_resident = values.is_anaesthetist === 1 && this.state.is_resident ? 1 : 0;

        values.name = values.name.trim();
        values.cpf = values.cpf ? values.cpf.replace(/[^\d]+/g, '') : '';
        values.crm = values.crm;
        values.crmStateId = values.crmStateId;
        values.phone = values.phone ? values.phone.replace(/[^\d]+/g, '') : '';
        if (values.crmStateId === '') {
          values.crmStateId = null;
        }
        delete values.type;

        this.setState({ loadingSend: true });

        // new
        if (!values.id) {
          delete values.id;
          values.email = values.email.trim();

          promiseSave = usersService.postInvite(values).then((result) => {
            if (result.id > 0) {
              this.handleInviteCancel();

              notification.success({
                message: 'Convite enviado com sucesso!',
              });

              this.load();

              const recipient_email = values.email;

              // salvando após enviar o convite
              mixPanelService.tracking(mixPanelService.USUARIO_NOVO_CONVITE, null, recipient_email, null, null, null);
            }
          });
        // eslint-disable-next-line brace-style
        }
        // edit
        else {
          const user_id = values.id;
          const user_email = values.email;

          delete values.id;
          delete values.email;
          delete values.cpf;
          delete values.phone;
          const originalValues = this.state.oldUser;

          promiseSave = usersService.putInvite(user_id, values).then((result) => {
            this.handleInviteCancel();

            const recipient_email = user_email;
            const updatedFields = []; // Array para armazenar os campos editados

            // Verificar quais campos foram alterados
            if (result && Object.keys(result).length > 0) {
              Object.keys(result).forEach((key) => {
                const oldValue = originalValues[key]; // Usar os valores originais
                const newValue = result[key]; // Valor novo é o valor após a edição

                // Verifica se o valor antigo é diferente do novo antes de adicionar ao array
                if (newValue !== undefined && oldValue !== newValue) {
                  if (key === 'anaesthetist') {
                    const oldAnaesthetist = oldValue;
                    const newAnaesthetist = newValue;

                    Object.keys(oldAnaesthetist).forEach((subKey) => {
                      if (subKey !== 'updated_at' && oldAnaesthetist[subKey] && newAnaesthetist[subKey] && JSON.stringify(oldAnaesthetist[subKey]) !== JSON.stringify(newAnaesthetist[subKey])) {
                        updatedFields.push({
                          field: `anaesthetist.${subKey}`, // Nome do campo editado
                          values: { old_value: oldAnaesthetist[subKey], new_value: newAnaesthetist[subKey] },
                        });
                      }
                    });
                  }
                  if (key !== 'updated_at') {
                    if (key !== 'institutions' && key !== 'anaesthetist') {
                      updatedFields.push({
                        field: key, // Nome do campo editado
                        values: { old_value: oldValue, new_value: newValue },
                      });
                    } else if (key === 'institutions') {
                      // Verificar se 'institution_user' foi alterado dentro de 'institutions'
                      const oldInstitutionUser = oldValue[0].institution_user;
                      const newInstitutionUser = newValue[0].institution_user;

                      // Comparar cada chave dentro de institution_user
                      Object.keys(oldInstitutionUser).forEach((subKey) => {
                        if (subKey !== 'updated_at' && oldInstitutionUser[subKey] !== newInstitutionUser[subKey]) {
                          updatedFields.push({
                            field: `institution_user.${subKey}`, // Nome do campo editado
                            values: { old_value: oldInstitutionUser[subKey], new_value: newInstitutionUser[subKey] },
                          });
                        }
                      });
                    }
                  } else if (key === 'anaesthetist') {
                    const oldValues = {
                      crm_number: originalValues.anaesthetist.crm_number,
                      crm_state_id: originalValues.anaesthetist.crm_state_id,
                    };

                    const newValues = {
                      crm_number: result.anaesthetist.crm_number,
                      crm_state_id: result.anaesthetist.crm_state_id,
                    };

                    updatedFields.push({
                      field: 'anaesthetist',
                      values: { old_value: oldValues, new_value: newValues },
                    });
                  }
                }
              });
            }

            // Chamada para salvar os logs
            // eslint-disable-next-line max-len
            mixPanelService.tracking(mixPanelService.EVENTO_EDITAR, null, recipient_email, updatedFields, null);

            notification.success({
              message: 'Permissão ou usuario alterados com sucesso!',
            });

            this.load();
          });
        }
        promiseSave.catch((error) => {
          console.log(error);
          this.setState({ loadingSend: false });
          notification.error({
            message: 'OPS! Falha ao enviar convite, tente novamente mais tarde!',
          });
        });
      } else {
        console.log('console.log', err);
      }
    });
  };

  handleInviteCancel = () => {
    const clearInvite = {
      id: null,
      type: null,
      email: '',
      name: '',
      cpf: '',
      crm: '',
      crmStateId: '',
      phone: '',
      expiration_date: null,
    };

    this.setState({
      modalVisible: false,
      loadingSend: false,
      is_resident: false,
      edit_institution_id: null,
    });
    this.props.form.setFieldsValue(clearInvite);
  };

  openInviteModal = () => {
    mixPanelService.tracking(mixPanelService.USUARIO_NOVO_CONVITE);

    const clearInvite = {
      id: null,
      type: null,
      email: '',
      expiration_date: null,
      name: '',
      cpf: '',
      crm: '',
      crmStateId: '',
      phone: '',
    };

    this.setState({
      modalVisible: true,
      loadingSend: false,
      is_resident: false,
      isUserActive: true,
      edit_institution_id: null,
    });
    this.props.form.setFieldsValue(clearInvite);
  };

  openInviteEditModal = async (user_id) => {
    const arrayUser = this.state.dataUsers.find((user) => user.id === user_id) || this.props.users.data.find((user) => user.id === user_id);

    if (!arrayUser) {
      console.error('Usuário não encontrado com o ID:', user_id);
      return;
    }

    const arrayTypes = [];
    let edit_expiration_date = null;

    if (arrayUser.institutions[0].institution_user.is_admin) {
      arrayTypes.push('is_admin');
    }
    if (arrayUser.institutions[0].institution_user.is_anaesthetist) {
      arrayTypes.push('is_anaesthetist');
    }
    if (arrayUser.institutions[0].institution_user.is_dashboard) {
      arrayTypes.push('is_dashboard');
    }
    if (arrayUser.institutions[0].institution_user.is_manager) {
      arrayTypes.push('is_manager');
    }
    if (arrayUser.institutions[0].institution_user.is_network) {
      arrayTypes.push('is_network');
    }

    if (arrayUser.institutions[0].institution_user.is_anaesthetist && arrayUser.institutions[0].institution_user.is_resident) {
      this.setState({
        is_resident: true,
        edit_institution_id: !arrayUser.institutions[0].is_group ? arrayUser.institutions[0].id : null,
      });
    } else {
      this.setState({ is_resident: false, edit_institution_id: null });
    }

    if (arrayUser.institutions[0].institution_user.expiration_date) {
      edit_expiration_date = moment(arrayUser.institutions[0].institution_user.expiration_date);
    }

    const isActive = arrayUser.institutions[0].institution_user.active;
    try {
      const freshUserData = await usersService.getUserById(user_id);

      if (freshUserData) {
        const stateName = freshUserData.anaesthetist && freshUserData.anaesthetist.crm_state
          ? this.getStateNameById(freshUserData.anaesthetist.crm_state.id)
          : '';

        this.props.form.setFieldsValue({
          cpf: freshUserData.document ? freshUserData.document.replace(/[^\d]+/g, '') : '',
          crm: freshUserData.anaesthetist && freshUserData.anaesthetist.crm_number,
          crmStateId: stateName,
          phone: freshUserData.anaesthetist && freshUserData.anaesthetist.phone_number ? freshUserData.anaesthetist.phone_number.replace(/[^\d]+/g, '') : '',
        });
      }
    } catch (error) {
      console.error('Erro ao atualizar dados do usuário:', error);
    }
    this.props.form.setFieldsValue({
      id: arrayUser.id,
      email: arrayUser.email,
      type: arrayTypes,
      expiration_date: edit_expiration_date,
      name: arrayUser.name,
    });

    this.setState({
      modalVisible: true,
      loadingSend: false,
      isUserActive: isActive,
    });
  };

  getUserStatus = (user_id) => {
    // Obter o usuário com base no ID fornecido
    const user = this.state.dataUsers.find((user) => user.id === user_id) || this.props.users.data.find((user) => user.id === user_id);

    console.log('User:', user);

    // Verificar se o usuário foi encontrado e se ele tem instituições
    if (user && user.institutions && user.institutions.length > 0) {
      const { active } = user.institutions[0].institution_user;
      console.log('Active:', active);

      // Se o usuário for encontrado, retornar o status correspondente e o objeto user
      return { status: active ? 'ativo' : 'pendente', user };
    }
    // Se o usuário não for encontrado ou não tiver instituições, retornar um valor padrão
    return { status: 'pendente', user: null };
  };

  submitDelete = () => {
    let promiseSave;

    if (this.state.delete_id) {
      this.setState({ confirmLoading: true });

      promiseSave = usersService
        .deleteUser(this.state.delete_id, this.state.delete_institution_id)
        .then((result) => {
          // Verificar o status do usuário excluído
          const { status, user } = this.getUserStatus(this.state.delete_id);

          // Passar o ID correspondente para o saveUserLog
          const deletedId = this.state.delete_id;

          const recipient_email = user && user.email ? user.email : null;

          const deleted_invitation_id = status === 'pendente' ? deletedId : null; // Definir o deleted_invitation_id corretamente

          const deleted_user_id = status === 'ativo' ? deletedId : null;

          if (deleted_invitation_id) {
            // eslint-disable-next-line max-len
            mixPanelService.tracking(mixPanelService.EVENTO_EXCLUIR_CONVITE, recipient_email, undefined, deletedId, null);
          } else if (deleted_user_id) {
            // eslint-disable-next-line max-len
            mixPanelService.tracking(mixPanelService.EVENTO_EXCLUIR_USUARIO, recipient_email, undefined, null, deletedId);
          }

          notification.success({
            message: 'Usuário removido com sucesso!',
          });

          this.load();

          this.setState({
            confirmLoading: false,
            confirmVisible: false,
            delete_id: null,
          });
        });

      promiseSave.catch((error) => {
        console.log(error);

        notification.error({
          message: error,
        });
      });
    }
  };

  deleteUser = (user_id, institution_id) => {
    this.setState({
      confirmVisible: true,
      confirmLoading: false,
      delete_id: user_id,
      delete_institution_id: institution_id,
    });
  };

  changePage = async (pageProp) => {
    await this.setState({ page: pageProp });
    console.log('page', this.state.page);
    this.load();
  }

  searchTable = (e) => {
    const text = e.target.value;

    setTimeout(async () => {
      this.setState({ userSeach: text });
      if (text.length && text.length >= 3) {
        const newDataUsers = await usersService.getUsers(text,
          this.cancelSource.token,
          this.state.page,
          this.state.limit);
        this.setState({ dataUsers: newDataUsers });
        this.setState({ usersCount: newDataUsers.length });
      } else if (text.length === 0) {
        this.load();
      }
    }, 300);
  };

  render() {
    const { users } = this.props;
    const { getFieldDecorator } = this.props.form;
    const selectedInstitution = JSON.parse(localStorage.getItem('selected-institution'));

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
      },
      colon: false,
    };

    const columnsTableUsers = [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
        render: (name, record) => (
          <span>
            {stringsHelp.firstLetterUpper(name, true)}

            {record.institutions.map((institution, index) => {
              if (
                JSON.parse(localStorage.getItem('selected-institution'))
                && JSON.parse(localStorage.getItem('selected-institution')).id !== institution.id
                && institution.institution_user.active === true
              ) {
                return (
                  <span>
                    &nbsp;&nbsp;
                    <Tag
                      className="tag-custom-round"
                      color="rgb(221, 246, 246)"
                      style={{
                        marginBottom: 3,
                      }}
                    >
                      {' '}
                      <Icon type="home" />
                      &nbsp;&nbsp;
                      <span>
                        {' '}
                        {stringsHelp.maxLength(
                          stringsHelp.formatName(
                            institution.name,
                          ),
                          40,
                          true,
                        )}
                      </span>
                    </Tag>
                  </span>
                );
              }
            })}

          </span>
        ),
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Acessos',
        key: 'perfil',
        render: (text, record) => {
          const objskill = record.institutions[0].institution_user;

          const tagSkill = [];

          if (objskill.is_anaesthetist) {
            if (objskill.is_resident) {
              tagSkill.push(
                <Tag>
                  <Icon type="tablet" />
                  {' '}
                  Anestesista
                  {' '}
                  <Divider type="vertical" />
                  {' '}
                  <span style={{ color: 'orange' }}>Residente</span>
                </Tag>,
              );
            } else {
              tagSkill.push(
                <Tag>
                  <Icon type="tablet" />
                  {' '}
                  Anestesista
                </Tag>,
              );
            }
          }
          if (objskill.is_dashboard) {
            tagSkill.push(
              <Tag>
                <Icon type="bar-chart" />
                {' '}
                Analytics
              </Tag>,
            );
          }
          if (objskill.is_network) {
            tagSkill.push(
              <Tag>
                <Icon type="bar-chart" />
                {' '}
                Analytics - Rede
              </Tag>,
            );
          }
          if (objskill.is_manager) {
            tagSkill.push(
              <Tag>
                <Icon type="laptop" />
                {' '}
                Manager
              </Tag>,
            );
          }
          if (objskill.is_admin) {
            tagSkill.push(
              <Tag>
                <Icon type="laptop" />
                {' '}
                Administrador
              </Tag>,
            );
          }

          return (
            <div key={10} style={{ display: 'inline-flex', flexWrap: 'wrap', alignItems: 'flex-start' }}>
              {tagSkill.map((component, index) => (
                <div style={{ margin: '1em 0' }} key={index}>
                  {' '}
                  {component}
                  {' '}
                </div>
              ))}
            </div>
          );
        },
      },
      {
        title: 'Expiração',
        key: 'expiracao',
        render: (text, record) => {
          const { expiration_date } = record.institutions[0].institution_user;
          return expiration_date
            ? moment(expiration_date).format('DD/MM/YYYY')
            : '';
        },
      },
      {
        title: 'Status',
        dataIndex: 'active',
        key: 'active',
        render: (text, record) => {
          const { active } = record.institutions[0].institution_user;

          if (active) {
            return (
              <Tag className="tag-custom-round" color="rgb(221, 246, 246)">
                Ativo
              </Tag>
            );
          }
          return (
            <Tag className="tag-custom-round" color="red">
              Pendente
              {' '}
              <Divider type="vertical" />
              {' '}
              <Popover
                content={(
                  <Button
                    type="primary"
                    loading={this.state.loadingSend}
                    onClick={() => this.handleInviteResend(record)}
                    block
                    size="small"
                  >
                    Confirmar
                  </Button>
                )}
                title="Deseja reenviar convite?"
                trigger="hover"
              >
                <Icon type="mail" />
              </Popover>
            </Tag>
          );
        },
      },
      {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (id, record) => {
          const institution_id = record.institutions[0].id;
          return (
            <span style={{ display: 'flex' }}>
              <Tooltip title="Editar">
                <Button
                  type="link"
                  onClick={() => this.openInviteEditModal(id)}
                >
                  <Icon type="edit" />
                </Button>
              </Tooltip>
              <Divider type="vertical" />
              <Tooltip title="Remover">
                <Button
                  type="link"
                  onClick={() => this.deleteUser(id, institution_id)}
                >
                  <Icon type="delete" />
                </Button>
              </Tooltip>
            </span>
          );
        },
      },
    ];

    return (
      <Content>
        <Row>
          <Col xs={24} sm={0}>
            <button
              onClick={() => this.openInviteModal()}
              className="ant-btn ant-btn-primary ant-btn-lg ant-btn-circle add-procedure"
            >
              <Icon type="plus" fill="#ffffff" />
            </button>
          </Col>
          <Col xs={24} lg={8} sm={16}>
            <h1 className="title">Usuários</h1>
          </Col>
          <Col xs={0} lg={16} sm={8}>
            <div style={{ display: 'flex' }}>
              <Search
                placeholder="Buscar"
                disabled={users.loading}
                onChange={this.searchTable}
                style={{ width: '100%', marginRight: 10 }}
              />
              <Button
                type="primary"
                shape="round"
                className="add-procedure"
                onClick={() => this.openInviteModal()}
              >
                NOVO CONVITE
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Table
            size="midle"
            loading={users.loading}
            dataSource={this.state.dataUsers}
            columns={columnsTableUsers}
            scroll={{ x: '100%' }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ['10', '30', '50', '100'],
              current: this.state.page,
              defaultCurrent: 1,
              total: this.state.usersCount,
              onChange: (e) => this.changePage(e),
              onShowSizeChange: async (current, size) => {
                await this.setState({ limit: size });
                this.load();
              },

            }}
            footer={() => {
              const rowsDataSource = !this.state.dataUsers
                ? 0
                : this.state.usersCount;
              return (
                <span>
                  Total de registros:
                  {' '}
                  <Tag>{rowsDataSource}</Tag>
                </span>
              );
            }}
          />
        </Row>

        <Modal
          visible={this.state.modalVisible}
          onCancel={this.handleInviteCancel}
          title={(
            <div>
              <button
                className="modal-close"
                type="button"
                onClick={this.handleInviteCancel}
              >
                <i className="icon-close" />
              </button>
              <span>
                {this.props.form.getFieldValue('id')
                  ? 'Alterar usuário'
                  : 'Convite de usuário'}
              </span>
            </div>
          )}
          footer={[
            <Button
              key="submit"
              type="primary"
              form="formUser"
              htmlType="submit"
              loading={this.state.loadingSend}
            >
              {this.props.form.getFieldValue('id') ? 'Alterar' : 'Enviar'}
            </Button>,
          ]}
          bodyStyle={{ paddingTop: '21px' }}
          width="701px"
          zIndex={1024}
        >
          <Form onSubmit={this.handleSubmit} id="formUser">
            {getFieldDecorator('id', {})(<span />)}
            <Row gutter={28}>
              <Col md={24} lg={24} xl={24}>
                <FormItem {...formItemLayout} label="E-MAIL">
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o email.',
                      },
                    ],
                  })(
                    <Input
                      autoFocus
                      disabled={
                        !!this.props.form.getFieldValue('id')
                      }
                    />,
                  )}
                </FormItem>
              </Col>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Col span={12}>

                  <FormItem {...formItemLayout} label="Nome">
                    {getFieldDecorator('name', {
                      rules: [{ required: false, message: 'Por favor, insira o nome.' },
                        { pattern: /^[A-Za-z\s]*$/, message: 'Apenas letras são permitidas.' }],
                    })(<Input disabled={!this.state.isUserActive} />)}
                  </FormItem>
                </Col>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Col span={10}>
                  <FormItem {...formItemLayout} label="CPF">
                    {getFieldDecorator('cpf', {
                      rules: [{ validator: this.validatorCPF, message: 'CPF informado inválido.' }],
                    })(
                      <MaskedInput
                        mask={cpfMask}
                        guide={false}
                        className="ant-input"
                        disabled={!!this.props.form.getFieldValue('id')}
                      />,
                    )}
                  </FormItem>
                </Col>
                <Col span={7}>
                  <FormItem {...formItemLayout} label="CRM">
                    {getFieldDecorator('crm', {
                      rules: [
                        { required: false, message: 'Por favor, insira o CRM.' },
                        { pattern: /^\d*$/, message: 'Apenas números são permitidos.' },
                      ],
                    })(
                      <Input
                        disabled={!this.state.isUserActive}
                        maxlength="10"
                      />,
                    )}
                  </FormItem>
                </Col>
                <Col span={7}>
                  <FormItem {...formItemLayout} label="Estado (CRM)">
                    {getFieldDecorator('crmStateId', {
                      rules: [{ required: false, message: 'Por favor, insira o estado do CRM.' }],
                    })(
                      <Select
                        disabled={!this.state.isUserActive}
                        placeholder="Selecione o estado"
                        onChange={this.handleCrmStateChange}
                      >
                        {crmStates.map((estado) => (
                          <Select.Option key={estado.value} value={estado.value}>
                            {estado.label}
                          </Select.Option>
                        ))}
                      </Select>,
                    )}
                  </FormItem>
                </Col>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                <Col span={12}>

                  <FormItem {...formItemLayout} label="Telefone">
                    {getFieldDecorator('phone', {
                      rules: [{ required: false, message: 'Por favor, insira o telefone.' }],
                    })(<MaskedInput
                      mask={phoneMask}
                      guide={false}
                      className="ant-input"
                      input
                      disabled={!this.state.isUserActive || !!this.props.form.getFieldValue('id')}
                    />)}
                  </FormItem>
                </Col>
              </div>
              <Col md={24} lg={24} xl={24}>
                <FormItem {...formItemLayout} label="DATA DE EXPIRAÇÃO">
                  {getFieldDecorator('expiration_date', {})(
                    <DatePicker
                      format="DD/MM/YYYY"
                      placeholder=""
                      showAction={['click']}
                    />,
                  )}
                </FormItem>
              </Col>

              <Col md={24} lg={24} xl={24}>
                <FormItem
                  {...formItemLayout}
                  label={(
                    <span>
                      SELECIONE O TIPO DE ACESSO
                      {' '}
                      <small style={{ float: 'right' }}>
                        <Icon type="question-circle" />
                        {' '}
                        Passe o mouse para
                        detalhes
                      </small>
                    </span>
                  )}
                >
                  {getFieldDecorator('type', {
                    rules: [
                      {
                        required: true,
                        message: 'Selecione o tipo de acesso do usuário.',
                      },
                    ],
                  })(
                    <Checkbox.Group style={{ width: '100%' }}>
                      <Row style={{ marginTop: 15 }}>
                        { !selectedInstitution.is_network ? (
                          <Col className="col-margin-check" span={24}>
                            <Checkbox value="is_anaesthetist">
                              <Tooltip
                                placement="right"
                                title="Acesso ao aplicativo IOS para registro digital anestésico"
                              >
                                AxReg -
                                {' '}
                                <Icon type="tablet" />
                                {' '}
                                Aplicativo
                                {' '}
                                <Tag
                                  className="tag-custom-round"
                                  color="rgb(221, 246, 246)"
                                >
                                  ANESTESISTA
                                </Tag>
                              </Tooltip>
                            </Checkbox>
                            {this.props.form.getFieldValue('type')
                            && this.props.form
                              .getFieldValue('type')
                              .includes('is_anaesthetist') && (
                              <Card className="card-add-residence">
                                <span
                                  style={{
                                    fontSize: 15,
                                    fontWeight: 400,
                                    color: '#343f5c',
                                    marginLeft: 35,
                                  }}
                                >
                                  Médico residente ?
                                  {' '}
                                  <Switch
                                    defaultChecked={this.state.is_resident}
                                    onChange={(checked) => this.setState({ is_resident: checked })}
                                    checkedChildren="Sim"
                                    unCheckedChildren="Não"
                                  />
                                </span>
                                <Tooltip
                                  placement="right"
                                  title="Integração de procedimentos anestésicos com SBA Logbook (exclusivo para médico residente)"
                                >
                                  <span
                                    style={{
                                      color: '#7c8595',
                                      fontSize: 15,
                                      float: 'right',
                                      marginRight: 25,
                                      padding: 5,
                                    }}
                                  >
                                    <small style={{ marginRight: 5 }}>
                                      <Icon type="api" />
                                      {' '}
                                      Integração
                                    </small>
                                    {' '}
                                    <img
                                      src={LogoLogBook}
                                      alt="Logo Logbook"
                                      height={25}
                                    />
                                    jn
                                    <Divider type="vertical" />
                                    <img
                                      src={LogoLogSba}
                                      alt="Logo SBA "
                                      height={20}
                                    />
                                  </span>
                                </Tooltip>

                                {this.state.is_resident
                                  && JSON.parse(
                                    localStorage.getItem('selected-institution'),
                                  )
                                  && JSON.parse(
                                    localStorage.getItem('selected-institution'),
                                  ).is_group ? (
                                    <div
                                      style={{
                                        paddingLeft: 35,
                                        paddingRight: 35,
                                        paddingTop: 20,
                                      }}
                                    >
                                      <FormItem
                                        {...formItemLayout}
                                        label="Instituição"
                                      >
                                        {getFieldDecorator('institution_ids', {
                                          initialValue: this.state
                                            .edit_institution_id,
                                        })(<SelectMultipleInstitutions />)}
                                      </FormItem>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                              </Card>
                            )}
                          </Col>
                        ) : null }
                        { !selectedInstitution.is_network ? (
                          <Col className="col-margin-check" span={24}>
                            <Checkbox value="is_dashboard">
                              <Tooltip
                                placement="right"
                                title="Acesso ao sistema Web para visualizar dasboards de performance institucional do sitio cirúrgico"
                              >
                                AxReg -
                                {' '}
                                <Icon type="bar-chart" />
                                {' '}
                                Analytics
                                {' '}
                                <Tag
                                  className="tag-custom-round"
                                  color="rgb(221, 246, 246)"
                                >
                                  INSTITUCIONAL
                                </Tag>
                              </Tooltip>
                            </Checkbox>
                          </Col>
                        ) : null }
                        { selectedInstitution.is_network ? (
                          <Col className="col-margin-check" span={24}>
                            <Checkbox value="is_network">
                              <Tooltip
                                placement="right"
                                title="Acesso ao sistema Web para visualizar dasboards de performance institucional do sitio cirúrgico"
                              >
                                AxReg -
                                {' '}
                                <Icon type="bar-chart" />
                                {' '}
                                Analytics
                                {' '}
                                <Tag
                                  className="tag-custom-round"
                                  color="rgb(221, 246, 246)"
                                >
                                  REDE
                                </Tag>
                              </Tooltip>
                            </Checkbox>
                          </Col>
                        ) : null }
                        { !selectedInstitution.is_network ? (
                          <Col className="col-margin-check" span={24}>
                            <Checkbox value="is_manager">
                              <Tooltip
                                placement="right"
                                title="Acesso limitado ao sistema Web para Manager administrativos e visualização de dados de paciente"
                              >
                                AxReg -
                                {' '}
                                <Icon type="laptop" />
                                {' '}
                                Manager
                                {' '}
                                <Tag
                                  className="tag-custom-round"
                                  color="rgb(221, 246, 246)"
                                >
                                  AUXILIAR
                                </Tag>
                              </Tooltip>
                            </Checkbox>
                          </Col>
                        ) : null }
                        { !selectedInstitution.is_network ? (
                          <Col span={24}>
                            <Checkbox value="is_admin">
                              <Tooltip
                                placement="right"
                                title="Acesso total ao sistema Web para Manager administrativos e visualização de dados de paciente incluindo o gerenciamento de usuários"
                              >
                                AxReg -
                                {' '}
                                <Icon type="laptop" />
                                {' '}
                                Manager
                                {' '}
                                <Tag
                                  className="tag-custom-round"
                                  color="rgb(221, 246, 246)"
                                >
                                  ADMINISTRADOR
                                  {' '}
                                </Tag>
                              </Tooltip>
                            </Checkbox>
                          </Col>
                        ) : null }
                      </Row>
                    </Checkbox.Group>,
                  )}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title=""
          visible={this.state.confirmVisible}
          onOk={() => this.submitDelete()}
          okText="Sim"
          okType="primary"
          cancelText="Não"
          confirmLoading={this.state.confirmLoading}
          onCancel={() => this.setState({ confirmVisible: false })}
        >
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            <span style={{ fontSize: 24, color: '#ffcd56', marginRight: 10 }}>
              <Icon type="question-circle" />
            </span>
            {' '}
            Confirma remover o usuário ?
          </p>
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

const connected = connect(mapStateToProps)(Form.create()(Users));
export default connected;
