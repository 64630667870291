// eslint-disable-next-line import/prefer-default-export
export const anesthetistsInviteConstants = {
  GET_ANESTHETISTS_INVTE_REQUEST: 'GET_ANESTHETISTS_INVITE_REQUEST',
  IMPORT_ANESTEHTISTS_INVITE_REQUEST: 'IMPORT_ANESTEHTISTS_INVITE_REQUEST',
  RETRY_ANESTHETISTS_INVITE_REQUEST: 'RETRY_ANESTHETISTS_INVITE_REQUEST',
  GET_ANESTHETISTS_INVTE_SUCCESS: 'GET_ANESTHETISTS_INVTE_SUCCESS',
  IMPORT_ANESTEHTISTS_INVITE_SUCCESS: 'IMPORT_ANESTEHTISTS_INVITE_SUCCESS',
  RETRY_ANESTHETISTS_INVITE_SUCCESS: 'RETRY_ANESTHETISTS_INVITE_SUCCESS',
  GET_ANESTHETISTS_INVTE_FAIL: 'GET_ANESTHETISTS_INVTE_FAIL',
  IMPORT_ANESTEHTISTS_INVITE_FAIL: 'IMPORT_ANESTEHTISTS_INVITE_FAIL',
  RETRY_ANESTHETISTS_INVITE_FAIL: 'RETRY_ANESTHETISTS_INVITE_FAIL',
};
