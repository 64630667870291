import React, { useState, useEffect } from 'react';
import {
  Row,
  Spin,
  Col,
  Input,
  Table,

} from 'antd';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { logService } from '../../services/log.service';

const { Search } = Input;

export default function Log() {
  const [logs, setLogs] = useState([]);
  const [columnDefinitions, setColumnDefinitions] = useState([]);
  const [tableLogs, setTableLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasPermissions, setHasPermissions] = useState(true);

  function formatTableLogs() {
    const columns = [
      {
        title: 'Usuário',
        dataIndex: 'user_name',
        key: 'user_name',
      },
      {
        title: 'Evento',
        dataIndex: 'event',
        key: 'event',
      },
      {
        title: 'Data',
        dataIndex: 'created_at',
        key: 'created_at',
      },
    ];

    setColumnDefinitions(columns);
  }

  useEffect(() => {
    function checkPermission() {
      const userPermissions = JSON.parse(localStorage.getItem('user-permissions'));
      if (!userPermissions.is_admin) {
        setHasPermissions(false);
      }
    }

    async function fetchData() {
      const result = await logService.getUserLog();

      // Deixa o name no mesmo nível das outras keys para poder ser usado na tabela,
      // formata a data e organiza o array em ordem decrescente por data
      const resultFlattened = result
        .map((i) => {
          const { user, ...rest } = i;
          return { ...rest, user_name: i.user.name, created_at: moment(i.created_at).format('DD/MM/YYYY HH:mm:ss') };
        })
        .sort((i) => moment().diff(moment(i.created_at, 'DD/MM/YYYY HH:mm:ss')));

      setLogs(resultFlattened);
      setTableLogs(resultFlattened);
      formatTableLogs();
      setLoading(false);
    }

    checkPermission();
    fetchData();
  }, []);

  function filter(e) {
    const { value } = e.target;
    if (value.length === 0) {
      setTableLogs(logs);
    } else {
      const filteredLogs = logs
        .filter((i) => i.event.toUpperCase().includes(value.toUpperCase()));
      setTableLogs(filteredLogs);
    }
  }

  return hasPermissions ? (
    <div>
      <>
        <Row>
          <Row>
            <Col xs={24} lg={24} sm={24} x={24}>
              <div style={{ display: 'flex' }}>
                <Search
                  placeholder="Buscar por evento"
                  style={{ width: '100%', marginRight: 10, marginBottom: 40 }}
                  onChange={filter}
                />
              </div>
            </Col>
          </Row>
          {!loading ? (
            <Table
              size="midle"
              dataSource={tableLogs}
              columns={columnDefinitions}
              pagination={{
                defaultPageSize: 20,
                showSizeChanger: true,
                pageSizeOptions: ['10', '30', '50', '100'],
              }}
            />
          ) : (
            <Row style={{ textAlign: 'center', padding: 20 }}>
              <Spin />
            </Row>
          )}
        </Row>
      </>
    </div>
  ) : (<Redirect to="/" />);
}
