/* eslint-disable react/sort-comp */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

import {
  Layout,
  Row,
  Col,
  Form,
  Table,
  Tag,
  Input,
  Select,
  Icon,
  Tooltip,
  DatePicker,
  Button,
  Popover,
  Empty,
  Tabs,
  Spin,
  notification,
  Modal,
  Checkbox,
} from 'antd';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import locale from 'antd/lib/date-picker/locale/pt_BR';
import { CancelToken } from 'axios';
import moment from 'moment';
import debounce from 'lodash.debounce';
import { proceduresActions, selectsActions } from '../../actions';
import { proceduresService } from '../../services';
import { stringsHelp } from '../../helpers';
import { IconTabPreSvg, IconTabProcedureSvg } from '../../helpers/icons';
import {
  getFilterProcedures,
  setFilterProcedures,
} from '../../helpers/filters';
import Api from '../../helpers/api';
import { mixPanelService } from '../../services/mixpanel.service';
import PdfViewerModal from '../PdfViewer';

const { Content } = Layout;
const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const { TabPane } = Tabs;

const ESTATUS = {
  NOT_SIGNED: 'NOT_SIGNED',
  WAITING_PROCESSING: 'WAITING_PROCESSING',
  SIGNED: 'SIGNED',
  ERROR: 'ERROR',
};

class Procedures extends Component {
  cancelSource = CancelToken.source();

  constructor() {
    super();
    this.state = {
      currentTab: '1',
      page: 1,
      count: 0,
      limit: 10,
      dataProcedures: [],
      start_date: getFilterProcedures()
        ? moment(getFilterProcedures().start_date, 'YYYY-MM-DD')
        : moment().subtract(parseInt(1, 10), 'months'),
      end_date: getFilterProcedures()
        ? moment(getFilterProcedures().end_date, 'YYYY-MM-DD')
        : moment(),
      anaesthetist_id: null,
      filterAll: '',
      loadingPDF: false,
      modalDeletePdf: {
        institution_name: '',
        patient_name: '',
        register_date: null,
        confirmVisible: false,
        pdf_id: null,
        type: '',
        pdf_type: '',
        modalVisible: false,
        loadingSend: false,
      },
      groups: null,
      selectedPdfs: [],
      selectedPdf: null,
      selectedPdfProcedimento: null,
      selectedPdfPos: null,
      selectedTerm: null,
      isPdfViewerModalVisible: false,
      search: '',
      digitalSignatureStatus: { enabled: false, notFound: false },
    };
    this.debounceFn = debounce(this.searchTable, 1000);
  }

  async componentDidMount() {
    this.loadSelects();
    this.loadMap();
    mixPanelService.tracking(mixPanelService.MENU_ATENDIMENTO);
    this.isDigitalSignatureEnabled();
  }

  loadSelects = () => {
    const { dispatch } = this.props;
    dispatch(selectsActions.getAnaesthetists(1, '', this.cancelSource.token));
  };

  isDigitalSignatureEnabled = async () => {
    const userPermissions = JSON.parse(localStorage.getItem('user-permissions'));
    const institutionId = userPermissions.institution_id;

    try {
      const response = await Api.get('/institutions/settings/34', {
        params: { institution_id: institutionId },
      });

      if (response) {
        const isEnabled = parseInt(response.value, 10) === 1;
        this.setState({ digitalSignatureStatus: { enabled: isEnabled, notFound: false } });
      } else {
        this.setState({ digitalSignatureStatus: { enabled: false, notFound: false } });
      }
    } catch (error) {
      console.error('Erro ao carregar dados de configuração:', error);
      if (error.response && error.response.status === 404) {
        this.setState({ digitalSignatureStatus: { enabled: false, notFound: true } });
      } else {
        this.setState({ digitalSignatureStatus: { enabled: false, notFound: false } });
      }
    }
  }

  loadMap = () => {
    const { dispatch, procedures } = this.props;

    if (procedures.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    const objFilter = {
      start_date: this.state.start_date.format('YYYY-MM-DD'),
      end_date: this.state.end_date.format('YYYY-MM-DD'),
      anaesthetist_id: this.state.anaesthetist_id,
      page: this.state.page,
      limit: this.state.limit,
      key: this.state.currentTab,
      search: this.state.search,
    };

    dispatch(
      proceduresActions.getProceduresForAttendance(
        { params: objFilter },
        this.cancelSource.token,
      ),
    ).then((response) => {
      this.setState({
        dataProcedures: response.procedures,
        count: response.count,
      });
    });
  };

  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  searchTable = async () => {
    const text = this.state.search;
    if (text && text.length >= 3) {
      this.loadMap();
    } else if (!text) {
      this.loadMap();
    } else if (text.length === 0) {
      this.loadMap();
    }
  };

  setFilter = (type, value) => {
    if (type === 'range_date') {
      this.setState({ start_date: value[0], end_date: value[1] }, () => {
        setFilterProcedures({ start_date: value[0], end_date: value[1] });
        this.loadMap();
      });
    }

    if (type === 'anaesthetist_id') {
      this.setState({ anaesthetist_id: value }, () => {
        this.setMixPanelSelect();
        this.loadMap();
      });
    }
  };

  handleViewPdf(pdf, term) {
    try {
      this.setState({ loadingPDF: true });

      mixPanelService.tracking(mixPanelService.EVENTO_VISUALIZAR, pdf);

      proceduresService
        .getViewPdf(pdf)
        .then((pdfData) => {
          this.setState({
            selectedPdf: pdfData,
            isPdfViewerModalVisible: true,
            loadingPDF: false,
          });
        })
        .then(() => {
          if (term) {
            proceduresService
              .getViewPdf(term)
              .then((pdfData) => {
                this.setState({
                  selectedTerm: pdfData,

                });
              });
          }
        })
        .catch(() => {
          notification.error({
            message: 'OPS! Falha ao abrir o PDF, tente novamente mais tarde!',
          });
          this.setState({ loadingPDF: false });
        });
    } catch (error) {
      console.error(error.message);
    }
  }

  handleViewPdfProcedimento(pdf) {
    try {
      this.setState({ loadingPDF: true });

      mixPanelService.tracking(mixPanelService.EVENTO_VISUALIZAR, pdf);

      proceduresService
        .getViewPdf(pdf)
        .then((pdfData) => {
          this.setState({
            selectedPdfProcedimento: pdfData,
            isPdfViewerModalVisible: true,
            loadingPDF: false,
          });
        })
        .catch(() => {
          notification.error({
            message: 'OPS! Falha ao abrir o PDF, tente novamente mais tarde!',
          });
          this.setState({ loadingPDF: false });
        });
    } catch (error) {
      console.error(error.message);
    }
  }

  handleViewPdfPos(pdf) {
    try {
      this.setState({ loadingPDF: true });

      mixPanelService.tracking(mixPanelService.EVENTO_VISUALIZAR, pdf);

      proceduresService
        .getViewPdf(pdf)
        .then((pdfData) => {
          this.setState({
            selectedPdfPos: pdfData,
            isPdfViewerModalVisible: true,
            loadingPDF: false,
          });
        })
        .catch(() => {
          notification.error({
            message: 'OPS! Falha ao abrir o PDF, tente novamente mais tarde!',
          });
          this.setState({ loadingPDF: false });
        });
    } catch (error) {
      console.error(error.message);
    }
  }

  closePdfViewerModal = () => {
    this.setState({
      // eslint-disable-next-line max-len
      selectedPdf: null, selectedPdfProcedimento: null, selectedPdfPos: null, isPdfViewerModalVisible: false, selectedTerm: null,
    });
  };

  downloadPdf = (pdf) => {
    this.setState({ loadingPDF: true });

    mixPanelService.tracking(mixPanelService.ATENDIMENTO_BAIXAR_PDF, pdf);

    proceduresService
      .getDownload(pdf)
      .then(() => {
        this.setState({ loadingPDF: false });
      })
      .catch(() => {
        notification.error({
          message:
            'OPS! Falha ao realizar download, tente novamente mais tarde!',
        });
        this.setState({ loadingPDF: false });
      });
  };

  downloadSelectedPdfs = async () => {
    this.setState({ loadingPDF: true });

    const pdfsNames = this.state.selectedPdfs.join(',');
    mixPanelService.tracking(mixPanelService.EVENTO_BAIXAR_PDFS, pdfsNames);

    if (this.state.selectedPdfs.length > 0) {
      await proceduresService.downloadPdfs(this.state.selectedPdfs);
    }

    this.setState({ loadingPDF: false });
  }

  onChangeText = (text) => {
    this.setState({ search: text });
    this.debounceFn();
  }

  onChangeLimit = async (limit) => {
    await this.setState({ limit });
    this.loadMap();
  }

  changePage = async (page) => {
    await this.setState({ ...this.state, page });
    this.loadMap();
  }

  deletePdf = () => {
    this.setState({
      loadingPDF: true,
      modalDeletePdf: {
        ...this.state.modalDeletePdf,
        confirmVisible: false,
      },
    });

    Api.delete(`/app/procedure-pdf/${this.state.modalDeletePdf.pdf_id}/${this.state.modalDeletePdf.pdf_type}`, {
      crossDomain: true,
    })
      .then((response) => {
        mixPanelService.tracking(mixPanelService.EVENTO_EXCLUIR_PDF, response.name);

        this.loadMap();

        notification.success({
          message: 'PDF Excluído com sucesso',
        });

        this.setState({
          loadingPDF: false,
          modalDeletePdf: {
            institution_name: '',
            patient_name: '',
            register_date: null,
            type: '',
            pdf_type: '',
            pdf_id: null,
          },
        });
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: 'Falha na exclusão do PDF. Tente novamente mais tarde.',
        });
      });
  };

  showConfirm = (all, tipo) => {
    const {
      institution_name: institutionName,
      patient_name: patientName,
      register_date: registerDate,
      id,
    } = all;

    this.setState({
      modalDeletePdf: {
        institution_name: institutionName,
        patient_name: patientName,
        register_date: registerDate,
        confirmVisible: true,
        pdf_id: id,
        type: tipo,
        type_id: all.type_id,
        pdf_type: all.pdf_type,
      },
    });
  };

  setPanelData = async (currentTab) => {
    await this.setState({ ...this.state, currentTab });
    this.loadMap();
  }

  setMixPanelSelect = () => {
    mixPanelService.tracking(mixPanelService.ATENDIMENTO_FILTRAR_ANESTESISTAS);
  };

  render() {
    const { procedures } = this.props;
    const { anaesthetists } = this.props.selects;
    const { modalDeletePdf } = this.state;
    const user = JSON.parse(localStorage.getItem('user'));
    const isPdfSelected = this.state.selectedPdfs.length;

    const columnsTableProceduresPre = [
      {
        title: '',
        dataIndex: 'pdf',
        key: 'pdf',
        render: (pdf, all) => (
          <Checkbox
            disabled={!pdf || all.disabled_pdf}
            onChange={(e) => {
              if (e.target.checked) {
                if (all.term && all.term !== '') {
                  this.setState((prevState) => ({
                    selectedPdfs: [...prevState.selectedPdfs, pdf],
                  }));
                  this.setState((prevState) => ({
                    selectedPdfs: [...prevState.selectedPdfs, all.term],
                  }));
                } else {
                  this.setState((prevState) => ({
                    selectedPdfs: [...prevState.selectedPdfs, pdf],
                  }));
                }
              } else if (all.term && all.term !== '') {
                this.setState((prevState) => ({
                  selectedPdfs: prevState.selectedPdfs.filter((i) => i !== pdf),
                }));
                this.setState((prevState) => ({
                  selectedPdfs: prevState.selectedPdfs.filter((i) => i !== all.term),
                }));
              } else {
                this.setState((prevState) => ({
                  selectedPdfs: prevState.selectedPdfs.filter((i) => i !== pdf),
                }));
              }
            }}
          />
        ),
      },
      {
        title: 'Paciente',
        dataIndex: 'patient_name',
        key: 'patient_name',
        render: (patientName) => <div style={{ whiteSpace: 'pre-line' }}>{patientName}</div>,
      },
      JSON.parse(localStorage.getItem('selected-institution')).is_group
        ? {
          title: 'Instituição',
          dataIndex: 'institution_name',
          key: 'institution_name',
          render: (institutionName) => {
            if (
              JSON.parse(localStorage.getItem('selected-institution'))
                .is_group
            ) {
              return (
                <span>
                  <Tag
                    className="tag-custom-round"
                    color="rgb(221, 246, 246)"
                    style={{ marginBottom: 3 }}
                  >
                    {' '}
                    <Icon type="home" />
                    {' '}
                    {stringsHelp.maxLength(
                      stringsHelp.formatName(institutionName),
                      40,
                      true,
                    )}
                  </Tag>
                </span>
              );
            }
            return '';
          },
        }
        : {},
      {
        title: 'Convênio',
        dataIndex: 'medical_plan_name',
        key: 'medical_plan_name',
        render: (medicalPlanNames) => {
          if (!medicalPlanNames) return '';

          const listMedicalPlanNames = medicalPlanNames
            .split(',')
            .map((medicalPlanName) => (
              <span style={{ lineHeight: '28px' }}>
                <Tag>{medicalPlanName.trim()}</Tag>
              </span>
            ));

          return listMedicalPlanNames;
        },
      },
      {
        title: 'Procedimento(s)',
        dataIndex: 'name',
        key: 'name',
        render: (name) => stringsHelp.firstLetterUpper(name, true),
      },
      {
        title: 'Anestesiologista',
        dataIndex: 'anaesthetists',
        key: 'anaesthetists',
        render: (anaesthetists) => {
          if (!anaesthetists) return '';

          const listAnaesthetists = anaesthetists
            .split(',')
            .map((anaesthetist, index) => (
              <span style={{ lineHeight: '28px' }} key={index}>
                <Tag>{anaesthetist.trim()}</Tag>
              </span>
            ));

          return listAnaesthetists;
        },
      },
      {
        title: 'Criado em',
        dataIndex: 'register_date',
        key: 'register_date',
        width: 160,
        render: (registerDate) => moment(registerDate).format('DD/MM/YYYY HH:mm'),
      },
      {
        title: 'Editado em',
        dataIndex: 'updated_date',
        key: 'updated_date',
        width: 160,
        render: (updatedDate) => (updatedDate ? moment(updatedDate).format('DD/MM/YYYY HH:mm') : ''),
      },
      !this.state.digitalSignatureStatus.notFound && this.state.digitalSignatureStatus.enabled ? {
        title: 'Assinado',
        dataIndex: 'signature_status',
        key: 'signature_status',
        render: (signatureStatus) => {
          const isSigned = signatureStatus === ESTATUS.SIGNED;
          return (
            <Tooltip title={isSigned ? 'Documento assinado' : 'Documento ainda não assinado'}>
              <div style={{ padding: 10 }}>
                <img src={`/ribbon_${isSigned ? 'signed' : 'unsigned'}.png`} alt="" style={{ width: 25, height: 25 }} />
              </div>
            </Tooltip>
          );
        },
      } : {},
      {
        title: 'PDF',
        dataIndex: 'pdf',
        key: 'pdfActions',
        width: 150,
        render: (pdf, all) => {
          if (!pdf || all.disabled_pdf) return '';
          return (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Tooltip title="Visualizar PDF">
                <Button
                  type="link"
                  style={{
                    color: '#1BBFBB',
                    fontSize: '18px',
                    opacity: isPdfSelected ? 0.5 : 1,
                  }}
                  onClick={() => this.handleViewPdf(pdf, all.term)}
                  disabled={isPdfSelected}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Download PDF">
                <Button
                  type="link"
                  style={{
                    color: '#1BBFBB',
                    fontSize: '18px',
                    opacity: isPdfSelected ? 0.5 : 1,
                  }}
                  onClick={() => this.downloadPdf(pdf)}
                  disabled={isPdfSelected}
                >
                  <DownloadOutlined />
                </Button>
              </Tooltip>

              {all.term && all.term !== '' ? (
                <Tooltip title="Termo de Consentimento">
                  <Button
                    type="link"
                    style={{
                      color: '#1BBFBB',
                      fontSize: '18px',
                      opacity: isPdfSelected ? 0.5 : 1,
                    }}
                    onClick={() => this.downloadPdf(all.term)}
                    disabled={isPdfSelected}
                  >
                    <Icon type="file-pdf" />
                  </Button>
                </Tooltip>
              ) : null}

              {user.type === 'AD' ? (
                <Tooltip title="Excluir PDF">
                  <Button
                    type="link"
                    style={{
                      color: '#1BBFBB',
                      fontSize: '18px',
                      opacity: isPdfSelected ? 0.5 : 1,
                    }}
                    onClick={() => this.showConfirm(all, 'Pré-anestésico')}
                    disabled={isPdfSelected}
                  >
                    <Icon type="delete" />
                  </Button>
                </Tooltip>
              ) : null}
            </div>
          );
        },
      },
    ];

    const columnsTableProcedures = [
      {
        title: '',
        dataIndex: 'pdf',
        key: 'pdf',
        render: (pdf, all) => (
          <Checkbox
            disabled={!pdf || all.disabled_pdf}
            onChange={(e) => {
              if (e.target.checked) {
                this.setState((prevState) => ({
                  selectedPdfs: [...prevState.selectedPdfs, pdf],
                }));
              } else {
                this.setState((prevState) => ({
                  selectedPdfs: prevState.selectedPdfs.filter((i) => i !== pdf),
                }));
              }
            }}
          />
        ),
      },
      {
        title: 'Paciente',
        dataIndex: 'patient_name',
        key: 'patient_name',
        render: (patientName) => <div style={{ whiteSpace: 'pre-line' }}>{patientName}</div>,
      },
      JSON.parse(localStorage.getItem('selected-institution')).is_group
        ? {
          title: 'Instituição',
          dataIndex: 'institution_name',
          key: 'institution_name',
          render: (institutionName) => {
            if (
              JSON.parse(localStorage.getItem('selected-institution'))
                .is_group
            ) {
              return (
                <span>
                  <Tag
                    className="tag-custom-round"
                    color="rgb(221, 246, 246)"
                    style={{ marginBottom: 3 }}
                  >
                    {' '}
                    <Icon type="home" />
                    {' '}
                    {stringsHelp.maxLength(
                      stringsHelp.formatName(institutionName),
                      40,
                      true,
                    )}
                  </Tag>
                </span>
              );
            }
            return '';
          },
        }
        : {},
      {
        title: 'Convênio',
        dataIndex: 'medical_plan_name',
        key: 'medical_plan_name',
        render: (medicalPlanNames) => {
          if (!medicalPlanNames) return '';

          const listMedicalPlanNames = medicalPlanNames
            .split(',')
            .map((medicalPlanName) => (
              <span style={{ lineHeight: '28px' }}>
                <Tag>{medicalPlanName.trim()}</Tag>
              </span>
            ));

          return listMedicalPlanNames;
        },
      },
      {
        title: 'Procedimento(s)',
        dataIndex: 'name',
        key: 'name',
        render: (name) => stringsHelp.firstLetterUpper(name, true),
      },
      {
        title: 'Anestesiologista',
        dataIndex: 'anaesthetists',
        key: 'anaesthetists',
        render: (anaesthetists) => {
          if (!anaesthetists) return '';

          const listAnaesthetists = anaesthetists
            .split(',')
            .map((anaesthetist) => (
              <span style={{ lineHeight: '28px' }}>
                <Tag>{anaesthetist.trim()}</Tag>
              </span>
            ));

          return listAnaesthetists;
        },
      },
      {
        title: 'Equipe cirúrgica',
        dataIndex: 'surgeons',
        key: 'surgeons',
        width: 140,
        render: (surgeons, record) => {
          const content = (
            <div>
              <p>
                <small>
                  <b>Cirúrgiões</b>
                </small>
              </p>
              {surgeons ? (
                surgeons.split(',').map((surgeon) => <p>{surgeon}</p>)
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
              <p style={{ marginTop: 5 }}>
                <small>
                  <b>Equipe auxiliar</b>
                </small>
              </p>
              {record.surgical_teams ? (
                record.surgical_teams
                  .split(',')
                  .map((surgicalTeam) => <p>{surgicalTeam}</p>)
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}

            </div>
          );

          const contentNotFound = (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          );

          return (
            <div style={{ textAlign: 'center' }}>
              <Popover
                content={
                  surgeons || record.surgical_teams ? content : contentNotFound
                }
                title="Equipe cirúrgica"
                trigger="hover"
              >
                <Button type="dashed" shape="circle" icon="team" />
              </Popover>
            </div>
          );
        },
      },
      {
        title: 'Criado em',
        dataIndex: 'register_date',
        key: 'register_date',
        width: 160,
        render: (registerDate) => moment(registerDate).format('DD/MM/YYYY HH:mm'),
      },
      {
        title: 'Editado em',
        dataIndex: 'updated_date',
        key: 'updated_date',
        width: 160,
        render: (updatedDate) => (updatedDate ? moment(updatedDate).format('DD/MM/YYYY HH:mm') : ''),
      },
      !this.state.digitalSignatureStatus.notFound && this.state.digitalSignatureStatus.enabled ? {
        title: 'Assinado',
        dataIndex: 'signature_status',
        key: 'signature_status',

        // width: 50,
        render: (signatureStatus) => {
          const isSigned = signatureStatus === ESTATUS.SIGNED;
          return (
            <Tooltip title={isSigned ? 'Documento assinado' : 'Documento ainda não assinado'}><div style={{ padding: 10 }}><img src={`/ribbon_${isSigned ? 'signed' : 'unsigned'}.png`} alt="" style={{ width: 25, height: 25 }} /></div></Tooltip>
          );
        },
      } : {},
      {
        title: 'PDF',
        dataIndex: 'pdf',
        key: 'pdfActions',
        width: 150,
        render: (pdf, all) => {
          if (!pdf || all.disabled_pdf) return '';
          return (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Tooltip title="Visualizar PDF">
                <Button
                  type="link"
                  style={{
                    color: '#1BBFBB',
                    fontSize: '18px',
                    opacity: isPdfSelected ? 0.5 : 1,
                  }}
                  onClick={() => this.handleViewPdfProcedimento(pdf)}
                  disabled={isPdfSelected}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Download PDF">
                <Button
                  type="link"
                  style={{
                    color: '#1BBFBB',
                    fontSize: '18px',
                    opacity: isPdfSelected ? 0.5 : 1,
                  }}
                  onClick={() => this.downloadPdf(pdf)}
                  disabled={isPdfSelected}
                >
                  <DownloadOutlined style={{ marginRight: '50px' }} />
                  {' '}
                </Button>
              </Tooltip>

              {user.type === 'AD' && (
                <Tooltip title="Excluir PDF">
                  <Button
                    type="link"
                    style={{
                      color: '#1BBFBB',
                      fontSize: '18px',
                      opacity: isPdfSelected ? 0.5 : 1,
                    }}
                    onClick={() => this.showConfirm(all, 'Procedimento')}
                    disabled={isPdfSelected}
                  >
                    <Icon type="delete" />
                  </Button>
                </Tooltip>
              )}
            </div>
          );
        },
      },
    ];

    const columnsTableProceduresPos = [
      {
        title: '',
        dataIndex: 'pdf',
        key: 'pdf',
        render: (pdf, all) => (
          <Checkbox
            disabled={!pdf || all.disabled_pdf}
            onChange={(e) => {
              if (e.target.checked) {
                this.setState((prevState) => ({
                  selectedPdfs: [...prevState.selectedPdfs, pdf],
                }));
              } else {
                this.setState((prevState) => ({
                  selectedPdfs: prevState.selectedPdfs.filter((i) => i !== pdf),
                }));
              }
            }}
          />
        ),
      },
      {
        title: 'Paciente',
        dataIndex: 'patient_name',
        key: 'patient_name',
        render: (patientName) => <div style={{ whiteSpace: 'pre-line' }}>{patientName}</div>,
      },
      JSON.parse(localStorage.getItem('selected-institution')).is_group
        ? {
          title: 'Instituição',
          dataIndex: 'institution_name',
          key: 'institution_name',
          render: (institutionName) => {
            if (
              JSON.parse(localStorage.getItem('selected-institution'))
                .is_group
            ) {
              return (
                <span>
                  <Tag
                    className="tag-custom-round"
                    color="rgb(221, 246, 246)"
                    style={{ marginBottom: 3 }}
                  >
                    {' '}
                    <Icon type="home" />
                    {' '}
                    {stringsHelp.maxLength(
                      stringsHelp.formatName(institutionName),
                      40,
                      true,
                    )}
                  </Tag>
                </span>
              );
            }
            return '';
          },
        }
        : {},
      {
        title: 'Convênio',
        dataIndex: 'medical_plan_name',
        key: 'medical_plan_name',
        render: (medicalPlanNames) => {
          if (!medicalPlanNames) return '';

          const listMedicalPlanNames = medicalPlanNames
            .split(',')
            .map((medicalPlanName) => (
              <span style={{ lineHeight: '28px' }}>
                <Tag>{medicalPlanName.trim()}</Tag>
              </span>
            ));

          return listMedicalPlanNames;
        },
      },
      {
        title: 'Procedimento(s)',
        dataIndex: 'name',
        key: 'name',
        render: (name) => stringsHelp.firstLetterUpper(name, true),
      },
      {
        title: 'Anestesiologista',
        dataIndex: 'anaesthetists',
        key: 'anaesthetists',
        render: (anaesthetists) => {
          if (!anaesthetists) return '';

          const listAnaesthetists = anaesthetists
            .split(',')
            .map((anaesthetist) => (
              <span style={{ lineHeight: '28px' }}>
                <Tag>{anaesthetist.trim()}</Tag>
              </span>
            ));

          return listAnaesthetists;
        },
      },
      {
        title: 'Criado em',
        dataIndex: 'register_date',
        key: 'register_date',
        width: 160,
        render: (registerDate) => moment(registerDate).format('DD/MM/YYYY HH:mm'),
      },
      {
        title: 'Editado em',
        dataIndex: 'updated_date',
        key: 'updated_date',
        width: 160,
        render: (updatedDate) => (updatedDate ? moment(updatedDate).format('DD/MM/YYYY HH:mm') : ''),
      },
      !this.state.digitalSignatureStatus.notFound && this.state.digitalSignatureStatus.enabled ? {
        title: 'Assinado',
        dataIndex: 'signature_status',
        key: 'signature_status',

        // width: 50,
        render: (signatureStatus) => {
          const isSigned = signatureStatus === ESTATUS.SIGNED;
          return (
            <Tooltip title={isSigned ? 'Documento assinado' : 'Documento ainda não assinado'}><div style={{ padding: 10 }}><img src={`/ribbon_${isSigned ? 'signed' : 'unsigned'}.png`} alt="" style={{ width: 25, height: 25 }} /></div></Tooltip>
          );
        },
      } : {},
      {
        title: 'PDF',
        dataIndex: 'pdf',
        key: 'pdfActions',
        width: 150,
        render: (pdf, all) => {
          if (!pdf || all.disabled_pdf) return '';
          return (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Tooltip title="Visualizar PDF">
                <Button
                  type="link"
                  style={{
                    color: '#1BBFBB',
                    fontSize: '18px',
                    opacity: isPdfSelected ? 0.5 : 1,
                  }}
                  onClick={() => this.handleViewPdfPos(pdf)}
                  disabled={isPdfSelected}
                >
                  <EyeOutlined />
                </Button>
              </Tooltip>

              <Tooltip title="Download PDF">
                <Button
                  type="link"
                  style={{
                    color: '#1BBFBB',
                    fontSize: '18px',
                    opacity: isPdfSelected ? 0.5 : 1,
                  }}
                  onClick={() => this.downloadPdf(pdf)}
                  disabled={isPdfSelected}
                >
                  <DownloadOutlined />
                  {' '}
                </Button>
              </Tooltip>

              {user.type === 'AD' ? (
                <Tooltip title="Excluir PDF">
                  <Button
                    type="link"
                    style={{
                      color: '#1BBFBB', fontSize: '18px', marginLeft: '3px', opacity: isPdfSelected ? 0.5 : 1,
                    }}
                    onClick={() => this.showConfirm(all, 'Pós-anestésico')}
                    disabled={isPdfSelected}
                  >
                    <Icon type="delete" />
                  </Button>
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          );
        },
      },
    ];

    return (
      <Content>
        <Row>
          <Col xs={24} sm={0}>
            <p>&nbsp;</p>
          </Col>
          <Col lg={24} xl={8}>
            <h1 className="title">Atendimentos</h1>
          </Col>
          <Col lg={24} xl={16} className="containterFilterProcedures">
            <Col xs={24} xl={16} className="filterProcedures">
              <Select
                showSearch
                allowClear
                placeholder="Todos anestesistas"
                style={{ width: '100%' }}
                onChange={(e) => this.setFilter('anaesthetist_id', e)}
                loading={anaesthetists ? anaesthetists.loading : true}
                showAction={['click']}
                filterOption={(input, option) => option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0}
              >
                {!anaesthetists
                  ? null
                  : anaesthetists.data.map((anaesthetist) => (
                    <Option key={anaesthetist.id} value={anaesthetist.id}>
                      {stringsHelp.formatName(anaesthetist.name)}
                    </Option>
                  ))}
              </Select>
            </Col>

            <Col lg={24} xl={8} className="filterProcedures">
              <RangePicker
                allowClear={false}
                locale={locale}
                value={[
                  moment(this.state.start_date, 'DD/MM/YYYY'),
                  moment(this.state.end_date, 'DD/MM/YYYY'),
                ]}
                style={{ width: '100%' }}
                format={dateFormat}
                onChange={(e) => this.setFilter('range_date', e)}
              />
            </Col>
          </Col>
        </Row>

        <Row>
          <Search
            placeholder="Busca livre"
            onChange={(e) => this.onChangeText(e.target.value)}
            style={{ width: '100%', marginRight: 10, marginBottom: 10 }}
          />
        </Row>

        <Spin tip="Processando..." spinning={this.state.loadingPDF}>
          <Row>
            <Tabs defaultActiveKey="1" onChange={this.setPanelData}>
              <TabPane
                tab={(
                  <span>
                    <Icon component={IconTabPreSvg} />
                    &nbsp;&nbsp;PRÉ-ANESTÉSICO&nbsp;&nbsp;
                  </span>
                )}
                key="1"
              >
                <Table
                  size="midle"
                  scroll={{ x: true }}
                  loading={procedures.loading}
                  dataSource={this.state.dataProcedures}
                  columns={columnsTableProceduresPre}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '30', '50'],
                    current: this.state.page,
                    defaultCurrent: 1,
                    total: this.state.count,
                    onChange: (e) => this.changePage(e),
                    onShowSizeChange: async (current, size) => {
                      this.onChangeLimit(size);
                    },
                  }}
                  footer={() => (
                    <span>
                      Total de registros:
                      {' '}
                      <Tag>{this.state.count}</Tag>
                    </span>
                  )}
                />

                {
                  (this.state.selectedTerm || this.state.selectedPdf) && (
                    <PdfViewerModal key="1" pdfSource={this.state.selectedPdf.url} termSource={this.state.selectedTerm ? this.state.selectedTerm.url : null} visible onClose={this.closePdfViewerModal} />
                  )
                }

              </TabPane>

              <TabPane
                tab={(
                  <span>
                    <Icon component={IconTabProcedureSvg} />
                    &nbsp;&nbsp;PROCEDIMENTO&nbsp;&nbsp;
                  </span>
                )}
                key="2"
              >

                <Table
                  size="midle"
                  scroll={{ x: true }}
                  loading={procedures.loading}
                  dataSource={this.state.dataProcedures}
                  columns={columnsTableProcedures}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '30', '50'],
                    current: this.state.page,
                    defaultCurrent: 1,
                    total: this.state.count,
                    onChange: (e) => this.changePage(e),
                    onShowSizeChange: async (current, size) => {
                      this.onChangeLimit(size);
                    },
                  }}
                  footer={() => (
                    <span>
                      Total de registros:
                      {' '}
                      <Tag>{this.state.count}</Tag>
                    </span>
                  )}
                />

                {this.state.selectedPdfProcedimento && (
                // eslint-disable-next-line max-len
                <PdfViewerModal key="2" pdfSource={this.state.selectedPdfProcedimento.url} visible onClose={this.closePdfViewerModal} />
                )}

              </TabPane>

              <TabPane
                tab={(
                  <span>
                    <Icon component={IconTabPreSvg} />
      &nbsp;&nbsp;PÓS-ANESTÉSICO&nbsp;&nbsp;
                  </span>
  )}
                key="3"
              >
                <Table
                  size="midle"
                  scroll={{ x: true }}
                  loading={procedures.loading}
                  dataSource={this.state.dataProcedures}
                  columns={columnsTableProceduresPos}
                  pagination={{
                    defaultPageSize: 30,
                    showSizeChanger: true,
                    pageSizeOptions: ['30', '50', '100'],
                    current: this.state.page,
                    defaultCurrent: 1,
                    total: this.state.count,
                    onChange: (e) => this.changePage(e),
                    onShowSizeChange: async (current, size) => {
                      this.onChangeLimit(size);
                    },
                  }}
                  footer={() => (
                    <span>
                      Total de registros:
                      {' '}
                      <Tag>{this.state.count}</Tag>
                    </span>
                  )}
                />

                {this.state.selectedPdfPos && (
                // eslint-disable-next-line max-len
                <PdfViewerModal key="2" pdfSource={this.state.selectedPdfPos.url} visible onClose={this.closePdfViewerModal} />
                )}

              </TabPane>

            </Tabs>
          </Row>

          <Row>
            <Button onClick={() => this.downloadSelectedPdfs()} type="primary" shape="round" className="download-pdfs" disabled={this.state.selectedPdfs.length === 0}>
              Baixar PDFs selecionados
            </Button>
          </Row>

        </Spin>

        <Modal
          title=""
          visible={modalDeletePdf.confirmVisible}
          onOk={() => this.deletePdf()}
          okText="Excluir PDF"
          okType="danger"
          cancelText="Cancelar"
          confirmLoading={modalDeletePdf.loading}
          onCancel={() => this.setState({
            modalDeletePdf: {
              ...modalDeletePdf,
              confirmVisible: false,
            },
          })}
        >
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            <span style={{ fontSize: 24, color: '#ffcd56', marginRight: 10 }}>
              <Icon type="question-circle" />
            </span>
            Confirmar exclusão do PDF
            <br />
            Tipo do PDF:
            {' '}
            {this.state.modalDeletePdf.type}
            <br />
            Paciente:
            {' '}
            {this.state.modalDeletePdf.patient_name}
            <br />
            Hospital:
            {' '}
            {this.state.modalDeletePdf.institution_name}
            <br />
            Atendimento:
            {' '}
            {moment(this.state.modalDeletePdf.register_date).format(
              'DD/MM/YYYY HH:mm',
            )}
          </p>
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { procedures, selects } = state;
  return {
    procedures,
    selects,
  };
}

export default connect(mapStateToProps)(Form.create()(Procedures));
