import React, { useState } from 'react';
import {
  Typography, Input, Select, Icon, Row, Col, Button,
} from 'antd';

const { Option } = Select;

const { Text } = Typography;

let selectedValue = 'vazio';
const setSelectedValue = (input) => {
  selectedValue = input;
};

let selectedPrompt = 'vazio';
const setSelectedPrompt = (input) => {
  selectedPrompt = input;
};

const LayoutComponent = (type, extra) => {
  const customPlaceHolder = extra.placeHolder || '';
  if (type === 'text') {
    setSelectedValue(extra.selected);
  } else {
    setSelectedValue(extra.selected.value);
  }

  let component = '';
  if (type === 'select') {
    let options = [];
    if (extra.source) {
      options = extra.source.map((d) => (
        <Option key={d.value} value={d.value}>{d.text}</Option>
      ));
    }

    component = (
      <Select
        style={{ width: '95%' }}
        defaultValue={extra.selected.value}
        placeholder={customPlaceHolder}
        showSearch
        optionFilterProp="children"
        size="small"
        onChange={(e, option) => { setSelectedValue(e); setSelectedPrompt(option.props.children); }}
      >
        {options}
      </Select>
    );
  }
  if (type === 'text') {
    component = (
      <Input
        value={selectedValue}
        placeHolder={customPlaceHolder}
        style={{ maxWidth: '90%' }}
        onFocusOut={(e) => setSelectedValue(e)}
        onChange={(e) => setSelectedValue(e)}
      />
    );
  }

  return (
    <Col span={12}>
      {component}
    </Col>
  );
};

const EditingPlace = ({
  type, name, pk, selected, placeHolder, source, callback,
}) => {
  const [value, setValue] = useState(selected.text ? selected.text : selected);
  const [editing, setEditing] = useState(false);
  const [editingLoad, setEditingLoad] = useState(false);

  const handleClose = () => {
    setEditing(false);
    setEditingLoad(false);
  };

  const handleCallBack = () => {
    setValue(selectedPrompt);
    setEditingLoad(true);
    callback({
      pk, name, value: selectedValue, text: selectedPrompt,
    }, handleClose);
  };

  const component = LayoutComponent(type, { selected, placeHolder, source });

  if (editing) {
    return (
      <Row>
        {component}
        <Col span={12}>
          <Button type="primary" onClick={handleCallBack} loading={editingLoad} size="small" shape="circle" icon="save" />
          {' '}
          <Button type="danger" onClick={handleClose} size="small" shape="circle" icon="close" />
        </Col>
      </Row>
    );
  }
  return (
    <span onClick={() => setEditing(true)}>
      <Text style={{
        color: '#1bbfbb', borderBottom: 'dotted', borderWidth: '2px', borderColor: '#1bbfbb',
      }}
      >
        {value}
        {' '}
        <Icon type="edit" />
      </Text>
    </span>
  );
};

export default EditingPlace;
