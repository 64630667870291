import React from "react";

export const IconTabPreSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none" stroke="#343F5C" strokeWidth="1.5">
        <path
          d="M10 4h1.856M10 8h1.856M10 12h1.856M8 3L5.34 6 4 4.5M4.5 9l3 3M7.5 9l-3 3"
        />
        <rect width="14.5" height="14.5" x=".75" y=".75" rx="2" />
      </g>
    </svg>
  );
};

export const IconTabProcedureSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g fill="none">
        <path  d="M-468-228h768V796h-768z" />
        <path

          strokeWidth="2"
          d="M-83-10v36h333c9.941 0 18-8.059 18-18s-8.059-18-18-18H-83z"
        />
        <g stroke="#343F5C" strokeWidth="1.5">
          <path
            d="M8.2 1.836L14.564 8.2M11.735 1.129l3.536 3.535"
          />
          <path
            d="M13.15 7.493l-6.365 6.363a2 2 0 0 1-2.828 0l-1.414-1.414a2 2 0 0 1 0-2.828L8.907 3.25"
          />
          <path d="M13.503 2.896l-1.768 1.768" />
          <path
            d="M2.884 13.366l-1.768 1.768M8 12.54L6.26 10.8M10.74 9.74L9 8"
          />
        </g>
      </g>
    </svg>
  );
};
