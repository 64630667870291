import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Animated } from 'react-animated-css';
import routes, { pathRoutes } from '../../routes';

const getAcl = (components, filter) => {
  const filteredComponents = [];

  components.forEach((component) => {
    if (component.to === filter) {
      filteredComponents.push(component);
    }

    if (component.sub && Array.isArray(component.sub)) {
      const filteredSubComponents = getAcl(component.sub, filter);
      filteredComponents.push(...filteredSubComponents);
    }
  });

  return filteredComponents;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userPermissions = JSON.parse(localStorage.getItem('user-permissions'));
  const componentACL = getAcl(routes.app, rest.path);
  const roles = componentACL[0] ? componentACL[0].role : [];

  if (localStorage.getItem('user') && localStorage.getItem('selected-institution')) {
    if (!roles
           || roles.length === 0
           || roles.indexOf(userPermissions.type) !== -1
           || roles.indexOf('*') !== -1) {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Animated animationIn="fadeIn" style={{ flexGrow: 1 }}><Component {...rest} {...props} /></Animated>)}
        />
      );
    }
    return (
      <Route
        render={() => (<Redirect to="/pagina_restrita" />)}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect to={{ pathname: pathRoutes.login, state: { from: props.location } }} />
      )}
    />
  );
};

export { PrivateRoute };
