/* eslint-disable no-param-reassign */
export const stringsHelp = {
  firstLetterUpper,
  formatName,
  avatar,
  formatPhoneNumber,
  formatPhoneNumbers,
  removeAccent,
  removeSpecialCharacter,
  maxLength255,
  maxLength,
  wordLimiter,
};

function firstLetterUpper(string) {
  if (!string) return '';

  // Capitaliza a primeira letra de cada palavra
  string = string.replace(/(?:^|\s)\S/g, (word) => word.toUpperCase());

  return string;
}

function formatName(name) {
  try {
    if (!name) return '';

    let newRame = name.replace(/(?:^|\s)\S/g, (word) => word.toUpperCase());

    const PreposM = ['Da', 'De', 'Do', 'Das', 'Dos', 'A', 'E'];
    const prepos = ['da', 'de', 'do', 'das', 'dos', 'a', 'e'];

    for (let i = PreposM.length - 1; i >= 0; i--) {
      // eslint-disable-next-line
      newRame = newRame.replace(RegExp("\\b" + PreposM[i].replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + "\\b", "g"), prepos[i]);

    }

    return newRame;
  } catch (error) {
    return name.toLowerCase().replace(/(?:^|\s)\S/g, (X) => X.toUpperCase());
  }
}

function avatar(string) {
  if (!string) return '';

  string = string.toUpperCase().split(' ');

  const firstLetter = string[0].charAt(0);
  const secondLetter = string.length > 1 ? string.slice(-1).pop().charAt(0) : string[0].charAt(1);

  return firstLetter + secondLetter;
}

function removeAccent(text) {
  text = text.toLowerCase();
  text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
  text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
  text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
  text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
  text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
  text = text.replace(new RegExp('[Ç]', 'gi'), 'c');
  return text;
}

function formatPhoneNumbers(phone) {
  const phones = [];
  if (!phone) {
    return '';
  }

  phone = phone.replace(/\/\//g, '/').split('/');
  phone.forEach((v) => {
    v = formatPhoneNumber(v);
    if (v.length > 0) {
      phones.push(v);
    }
  });

  return phones;
}

function formatPhoneNumber(phone) {
  phone = phone.replace(/[^\d]+/g, '');

  if (phone.length === 10 || phone.length === 11) {
    return `(${phone.substr(0, 2)}) ${phone.substr(2, phone.length - 6)}-${phone.substr(phone.length - 4, 4)}`;
  } if (phone.length > 7) {
    return `${phone.substr(0, phone.length - 4)}-${phone.substr(phone.length - 4, 4)}`;
  } if (phone.length > 4) {
    return `${phone.substr(0, 4)}-${phone.substr(4)}`;
  }

  return phone;
}

function removeSpecialCharacter(text) {
  try {
    if (text.trim().length) {
      return text.trim().replace(/[|&;$%@"<>()+,/]/g, '');
    }

    return text;
  } catch (error) {
    return '';
  }
}

function maxLength255(text) {
  try {
    if (text.length > 255) {
      return text.substring(0, 255);
    }

    return text;
  } catch (error) {
    return '';
  }
}

function maxLength(text, length, ellipsis = false) {
  try {
    if (text.length > length) {
      return text.substring(0, length) + (ellipsis ? '...' : '');
    }

    return text;
  } catch (error) {
    return '';
  }
}

function wordLimiter(str, limit, ellipsis = false) {
  const spliting = str.replace('\n', ' ').split(' ');
  return spliting.slice(0, limit).join(' ') + (ellipsis ? '...' : '');
}
