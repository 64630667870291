import React from 'react';
import { Button, notification, Spin } from 'antd';
import Api from '../helpers/api';

class ButtonFixAnaesthetist extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      procedureId: props.procedureId,
      anestesistaId: props.anestesistaId,
      loading: false,
    };
  }

  handleInput = () => {
    this.setState({
      loading: true,
    });
    const { procedureId, anestesistaId } = this.state;

    Api.post('app/procedure/fix-anestesista', { procedureId, anestesistaId }).then(() => {
      this.setState({
        loading: false,
      });
      notification.success('Correção realizada com sucesso');
    }).catch((err) => {
      notification.error('Ocorreu um erro');
      this.setState({
        loading: false,
      });
      console.error(err);
    });
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user'));
    const { loading } = this.state;
    if (user.type === 'AD') {
      return (
        <>
          <Button type="primary" onClick={this.handleInput}>
            Corrigir anestesista
          </Button>
          <Spin spinning={loading} />
        </>
      );
    }
    return '';
  }
}
export default ButtonFixAnaesthetist;
