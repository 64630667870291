import React from 'react';
import { DatePicker } from "antd";

export default function   DateTime(props) {
  const { defaulValue, onChange, ...rest } = props;
  const [ value, setValue ] = React.useState(defaulValue);
  const [ mode, setMode ] = React.useState('date');

  const handleChange = React.useCallback((e) => {
    setValue(e);
    setMode((old) => old === 'date' ? 'time' : 'time');
    onChange(e);
  }, [setMode, setValue, onChange]);

  const handleModeChange = React.useCallback(() => {
    setMode((old) => old === 'date' ? 'time' : 'date');
  }, [setMode]);

  return (
    <DatePicker
      format={'DD/MM/YYYY HH:mm'}
      style={{ width: '100%' }}
      showAction={['focus', 'click']}
      value={value}
      onChange={handleChange}
      onPanelChange={handleModeChange}
      mode={mode}
      {...rest}
      showTime={{
        format: 'HH:mm',
        hideDisabledOptions: true
      }}
      showToday
    />
  )

}
