import {materialsConstants} from '../constants';
import {materialsService} from "../services";

export const materialsActions = {
  getMaterials,
};

function getMaterials(filter, cancelToken) {
  return dispatch => {
    dispatch(request());

    return materialsService.getMaterials(filter, cancelToken)
      .then(materials => {        
        dispatch(success(materials));
        return materials;
      }, error => dispatch(failure(error)));
  };

  function request() {
    return {type: materialsConstants.GETMATERIALS_REQUEST}
  }

  function success(data) {
    return {type: materialsConstants.GETMATERIALS_SUCCESS, data}
  }

  function failure(error) {
    return {type: materialsConstants.GETMATERIALS_FAILURE, error}
  }
}
