import { sectorsConstants } from '../constants';
import {List} from "immutable";

export function sectors(state = {}, action) {
  switch (action.type) {
    case sectorsConstants.GETSECTORS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        data: new List()
      });
    case sectorsConstants.GETSECTORS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data
      });
    case sectorsConstants.GETSECTORS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    default:
      return state
  }
}
