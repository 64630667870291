/* eslint-disable react/no-unused-state */
/* eslint-disable react/state-in-constructor */
/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable camelcase */
import React, { Component } from 'react';
import {
  Modal, Menu, Avatar, Button,
} from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { sectorsActions } from '../actions';
import { stringsHelp } from '../helpers';
import { pathRoutes } from '../routes';
import { institutionService } from '../services';

class ModalCheckInstitution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      institutions: [],
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(sectorsActions.getSectors());

    this.fetchInstitutionsByGroup();
  }

    // Função para buscar as instituições vinculadas ao grupo específico
    fetchInstitutionsByGroup = async () => {
      try {
        const selectedInstitution = JSON.parse(localStorage.getItem('selected-institution'));

        const response = await institutionService.getInstitutionsByGroup(selectedInstitution.id);
        this.setState({ institutions: response });
      } catch (error) {
        console.error('Erro ao buscar instituições:', error);
      }
    };

  routeProcedurePre = (obj_institution) => {
    if (!obj_institution) return false;

    if (this.props.route === 'pre') {
      this.props.history.push({
        pathname: pathRoutes.newSchedulePre,
        query: { institution: obj_institution },
      });
    }
    if (this.props.route === 'schedule') {
      this.props.history.push({
        pathname: pathRoutes.newSchedule,
        query: { institution: obj_institution },
      });
    }
    if (this.props.route === 'patient') {
      this.props.history.push({
        pathname: pathRoutes.newPatient,
        query: { institution: obj_institution },
      });
    }
  };

  render() {
    const { institutions } = this.state;

    return (
      <Modal
        visible={this.props.visible}
        title={<span>Selecione a instituição</span>}
        bodyStyle={{ paddingTop: '21px' }}
        zIndex={1024}
        footer={(
          <Button
            key="cancel"
            type="default"
            onClick={() => {
              this.props.setVisible(false);
            }}
            style={{ textAlign: 'left' }}

          >
            Cancelar
          </Button>
)}
      >
        <Menu>
          {institutions ? institutions.map((institution) => (
            <Menu.Item
              key={institution.id}
              onClick={(e) => this.routeProcedurePre(institution)}
            >
              <div className="ant-list-item-meta ant-group check-institution">
                <div className="ant-list-item-meta-avatar">
                  <Avatar
                    shape="square"
                    src={
                            institution.logo_url
                              ? institution.logo_url
                              : null
                          }
                    className="institution-logo"
                  >
                    Logo
                  </Avatar>
                </div>
                <div className="ant-list-item-meta-content">
                  <h4
                    className="ant-list-item-meta-title"
                    style={{ marginBottom: -5 }}
                  >
                    {stringsHelp.firstLetterUpper(institution.name, true)}
                  </h4>
                </div>
              </div>
            </Menu.Item>
          ))
            : ''}
        </Menu>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { institutions } = state;
  return {
    institutions,
  };
}

const connected = withRouter(connect(mapStateToProps)(ModalCheckInstitution));

export default connected;
