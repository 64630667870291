import { eventsConstants } from '../constants';
import { eventsService } from '../services';

function getTransEvents(filter, page, limit, cancelToken) {
  function request() {
    return { type: eventsConstants.GETEVENTS_REQUEST };
  }

  function success(data) {
    return { type: eventsConstants.GETEVENTS_SUCCESS, data };
  }

  function failure(error) {
    return { type: eventsConstants.GETEVENTS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    return eventsService.getTransEvents(filter, page, limit, cancelToken)
      .then((events) => {
        dispatch(success(events));
        return events;
      }, (error) => dispatch(failure(error)));
  };
}

function getTransEventPresets(filter, page, limit, cancelToken) {
  function request() {
    return { type: eventsConstants.GETEVENT_PRESETS_REQUEST };
  }

  function success(data) {
    return { type: eventsConstants.GETEVENT_PRESETS_SUCCESS, data };
  }

  function failure(error) {
    return { type: eventsConstants.GETEVENT_PRESETS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    return eventsService.getTransEventPresets(filter, page, limit, cancelToken)
      .then((presets) => {
        dispatch(success(presets));
        return presets;
      }, (error) => dispatch(failure(error)));
  };
}

// eslint-disable-next-line import/prefer-default-export
export const eventsActions = {
  getTransEvents,
  getTransEventPresets,
};
