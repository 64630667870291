import React, { Component } from 'react';
import { Checkbox, Input, Select } from 'antd';
import { CancelToken } from 'axios';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { List } from 'immutable';
import { selectsActions } from '../actions';
import Modal from './Modal';
import { stringsHelp } from '../helpers';

class SelectMaterials extends Component {
  cancelSource = CancelToken.source();

  state = {
    modalVisible: false,
    selected: new List(),
    tracks: new List(),
    hasMoreItems: true,
    searchInterval: null,
  };

  componentDidMount() {
    if (this.props.value) {
      this.changeSelected(new List(this.props.value));
    }
    this.loadMaterials(1);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.value !== prevProps.value) {
      this.changeSelected(new List(this.props.value));
    }
  }

  setModalVisible(modalVisible) {
    this.setState({ modalVisible });
  }

  loadMaterials = (page) => {
    const value = this.filter ? this.filter.input.value : '';
    const { dispatch } = this.props;

    if (this.searchingMaterialsValue !== value || this.page !== page) {
      this.page = page;

      if (this.searchingMaterials && this.searchingMaterialsValue !== value) {
        this.cancelSource.cancel();
        this.cancelSource = CancelToken.source();
      }

      this.searchingMaterials = true;
      this.searchingMaterialsValue = value;

      if (!this.state.searchInterval) { clearTimeout(this.state.searchInterval); }

      const objFilter = {
        search: value,
      };

      this.state.searchInterval = setTimeout(() => {
        dispatch(selectsActions.getTransEventMaterials(objFilter, page, 50, this.cancelSource.token))
          .then((materials) => {
            this.searchingMaterials = false;

            if (materials.error) {
              return null;
            }
            let tracks;
            if (materials.page > 1) {
              tracks = this.state.tracks.concat(materials.result);
            } else {
              tracks = new List(materials.result);
            }
            return this.setState({
              tracks,
              hasMoreItems: materials.page < materials.pages,
            });
          });
      }, 500);
    }
  };

  filterMaterials = () => {
    this.setState({
      tracks: new List(),
      hasMoreItems: true,
    });

    this.loadMaterials(1);
  };

  loadItems = () => {
    let { page } = this;
    page++;
    this.loadMaterials(page);
  };

  changeSelected = (selected) => {
    if (this.props.onChange) { this.props.onChange(selected); }
    this.setState({ selected });
  };

  select = (event) => {
    const { value } = event.target;
    value.laterality = 1;
    this.changeSelected(this.state.selected.push(value));
  };

  deselect = (event) => {
    const { value } = event.target;
    const selected = this.state.selected.filter((v) => v.id !== value.id);

    this.changeSelected(selected);
  };

  render() {
    const { materials } = this.props;
    return (
      <div className="form-list">
        <Input hidden />
        {
          this.state.selected.size
            ? this.state.selected.map((material) => {
              const name = stringsHelp.firstLetterUpper(material.name, true);
              return (
                <div className="form-list-item" title={name} key={material.id}>
                  <span className="form-list-description">
                    {name}
                  </span>
                  <span className="form-list-del" onClick={() => this.deselect({ target: { value: material } })}>
                    <i className="icon-close" />
                  </span>
                </div>
              );
            })
            : null
        }
        <div className="form-list-item">
          <button type="button" className="form-add-link" onClick={() => this.setModalVisible(true)}>
            + Adicionar materiais
          </button>
        </div>

        <Modal
          title="Materiais"
          visible={this.state.modalVisible}
          onCancel={() => this.setModalVisible(false)}
          onOk={() => this.setModalVisible(false)}
          footer={false}
          buttonOkText="SALVAR"
          buttonOkType="primary"
        >
          <Input placeholder="Buscar" ref={(input) => this.filter = input} onKeyUp={this.filterMaterials} />

          <div className={`form-list ${this.state.selected.size ? '' : 'hidden'}`} style={{ marginTop: '21px' }}>
            <div className="form-list-title">
              SELECIONADOS (
              {this.state.selected.size}
              )
            </div>
            {
              this.state.selected.size
                ? this.state.selected.map((material) => {
                  const name = stringsHelp.firstLetterUpper(material.name, true);
                  return (
                    <div className="form-list-item slide-in" key={material.id}>
                      <span className="form-list-description" title={`Dr(a). ${name}`}>
                        <Checkbox defaultChecked value={material} onChange={this.deselect}>
                          {name}
                        </Checkbox>
                      </span>
                    </div>
                  );
                })
                : null
            }
          </div>
          <div className="form-list" style={{ marginTop: '21px', height: '500px', overflow: 'auto' }}>
            <div className="form-list-title">TODOS</div>

            {
              materials
                ? (
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={this.loadItems}
                    hasMore={this.state.hasMoreItems}
                    useWindow={false}
                  >
                    {
                    this.state.tracks
                      ? this.state.tracks.map((material) => {
                        const name = stringsHelp.firstLetterUpper(material.name, true);
                        if (this.state.selected.find((a) => a.id === material.id)) {
                          return null;
                        }

                        return (
                          <div className="form-list-item slide-in" key={material.id}>
                            <span className="form-list-description" title={`${name}`}>
                              <Checkbox value={material} onChange={this.select}>
                                {name}
                              </Checkbox>
                            </span>
                          </div>
                        );
                      })
                      : null
                  }
                  </InfiniteScroll>
                ) : null
            }
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { selects } = state;
  const { materials } = selects;
  return {
    materials,
  };
}

const connected = connect(mapStateToProps)(SelectMaterials);
export default connected;
