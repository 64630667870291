import React, { Component } from 'react';
import { CancelToken } from 'axios';
import {
  Select, Divider, Button, Empty, Spin,
} from 'antd';
import { Link, Redirect } from 'react-router-dom';
import { patientService } from '../services';
import { stringsHelp } from '../helpers';
import { pathRoutes } from '../routes';

const { Option } = Select;

class AutocompleteSelectPatient extends Component {
  state = {
    dataPatients: [],
    value: undefined,
    textSearch: '',
    searchingPatients: false,
  };

  cancelSource = CancelToken.source();

  handleChange = (value) => {
    if (value) {
      this.setState({ value });
    } else {
      this.setState({
        searchingPatients: false, dataPatients: [], value: undefined, textSearch: '',
      });
    }

    if (typeof (this.props.onChange) === 'function') {
      this.props.onChange(value);
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.patient !== prevProps.patient) {
      this.setState({ searchingPatients: false, dataPatients: [{ value: this.props.patient.id, text: this.props.patient.name }] }, function () {
        this.setState({ value: this.props.patient.id });
        this.props.onChange(this.props.patient.id);
      });
    }
  }

  handleSearch = (value) => {
    if (value.trim()) {
      if (value.length >= 3) {
        this.Search(value);
      }
    } else {
      this.setState({
        searchingPatients: false, dataPatients: [], value: undefined, textSearch: '',
      });
    }
  };

  Search = (value) => {
    if (this.state.searchingPatients) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    this.setState({
      searchingPatients: true,
      dataPatients: [], // Limpe os dados ao iniciar a nova pesquisa
      value: undefined,
      textSearch: value,
    });
    const config = { params: {} };

    if (this.props.institution) {
      config.params.institution_id = this.props.institution.id;
    }

    let promiseSave;
    promiseSave = patientService
      .getPatients(1, 10, value, this.cancelSource.token, config)
      .then((d) => {
        const data = [];
        d.patients.forEach((r) => {
          const textCustom = (r.preferred_name
            ? `Nome Civil: ${r.name} Nome Social: ${r.preferred_name}`
            : stringsHelp.firstLetterUpper(r.name, true))
            + (r.cpf ? ` | ${r.cpf}` : '');

          data.push({
            value: r.id,
            text: textCustom,
          });
        });

        this.setState({ searchingPatients: false, dataPatients: data });
      });

    promiseSave.catch((error) => {
      this.setState({ searchingPatients: false });
    });
  };

  redirectNewPatient = () => <Redirect to={pathRoutes.newPatient} />;

  render() {
    const options = this.state.dataPatients.map((d) => (
      <Option key={d.value} value={d.value}>{d.text}</Option>
    ));

    return (
      <Select
        style={{ width: '100%' }}
        showSearch
        allowClear
        value={this.state.value}
        placeholder="Digite o nome ou CPF para pesquisar..."
        defaultActiveFirstOption={false}
        showArrow
        filterOption={false}
        onSearch={this.handleSearch}
        onChange={this.handleChange}
        loading={this.state.searchingPatients}
        notFoundContent={this.state.searchingPatients ? <div style={{ textAlign: 'center', padding: 10 }}><Spin /></div> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={' '} />}
        dropdownRender={(menu) => (
          <div>
            {menu}
            <Divider style={{ margin: '4px 0' }} />
            <div
              style={{ padding: '8px', cursor: 'pointer' }}
              onClick={() => {
                console.log('Add item onClick()...');
              }}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >

              <Link to={{
                pathname: pathRoutes.newPatient,
                query: {
                  name: this.state.textSearch,
                  institution: this.props.institution,
                  route: this.props.route || null,
                },
              }}
              >
                <Button block type="dashed" icon="plus" style={{ borderRadius: 0, color: '#1bbfbb', borderColor: '' }}>Adicionar novo paciente</Button>
              </Link>
            </div>
          </div>
        )}
      >
        {options}
      </Select>
    );
  }
}

export default AutocompleteSelectPatient;
