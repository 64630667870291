import Api from '../helpers/api';

function getInstitutionLogo(id) {
  return Api.get(`/app/institutions/${id}/logo`);
}

function getInstitutions() {
  return Api.get('/preset/institutions');
}
function getInstitutionGroups(institutionId) {
  return Api.get(`/preset/institution/${institutionId}/groups`);
}
function selectInstitution(id) {
  return Api.get(`/manager/refresh-token/institution/${id}`);
}

function saveInstitutionLogo(uuid, file) {
  const formData = new FormData();
  formData.append('institution_logo', file);

  return Api.post(`web/institution/${uuid}/logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

function associateToGroup(institutionId, groupId) {
  return Api.put('/preset/institution_group/associate', {
    institution_id: institutionId,
    group_id: groupId,
  });
}

function associateToNetwork(institutionId, networkId) {
  return Api.put('/preset/institution_network/associate', {
    network_id: institutionId,
    institution_id: networkId,
  });
}

function settingsPreTcle(value) {
  return Api.post('/pre-settings-tcle', {
    value,
  });
}

function getSettingsPreTcle() {
  return Api.get('/pre-settings-tcle');
}

function getQuestionAnswers() {
  return Api.get('/question-answer-configs');
}

function saveQuestionAnswers(data) {
  return Api.post('/question-answer-configs', data);
}

function putPreSettings(institutionId, data) {
  return Api.post(`/institution/${institutionId}/settings/pdf`, data);
}

// Função para buscar instituições vinculadas a um grupo específico
function getInstitutionsByGroup(groupId) {
  return Api.get(`/preset/institutions-group/${groupId}`);
}

export const institutionService = {
  getInstitutions,
  selectInstitution,
  getInstitutionLogo,
  saveInstitutionLogo,
  associateToGroup,
  associateToNetwork,
  getInstitutionGroups,
  settingsPreTcle,
  getSettingsPreTcle,
  getQuestionAnswers,
  saveQuestionAnswers,
  putPreSettings,
  getInstitutionsByGroup,
};
