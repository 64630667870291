function getColumns(tableName) {
  const tableColumns = [
    {
      table: 'materials',
      postUrl: '/preset/materials/import',
      postRevert: 'importations/revert',
      getUrl: '/importations/?type=materials',
      obj_data: 'materials',
      columns: [
        {
          name: 'name', label: 'NOME', required: true, type: 'string',
        },
        {
          name: 'description', label: 'DESCRIÇÃO', required: false, type: 'string',
        },
        {
          name: 'internal_code', label: 'CÓDIGO INTERNO', required: false, type: 'string',
        },
        {
          name: 'price', label: 'PREÇO', required: false, type: 'float',
        },
      ],
    },
    {
      table: 'surgeons',
      postUrl: '/preset/surgeons/import',
      postRevert: 'importations/revert',
      getUrl: '/importations/?type=surgeons',
      obj_data: 'surgeons',
      columns: [
        {
          name: 'cpf', label: 'CPF', required: false, type: 'string',
        },
        {
          name: 'crm_number', label: 'NÚMERO - CRM', required: false, type: 'string',
        },
        {
          name: 'name', label: 'NOME', required: true, type: 'string',
        },
        {
          name: 'speciality', label: 'ESPECIALIDADE', required: false, type: 'string',
        },
        {
          name: 'phone_number', label: 'TELEFONE', required: false, type: 'integer',
        },
      ],
    },
    {
      table: 'surgical-map',
      postUrl: '/surgical-map/v3/surgeries/import',
      postRevert: 'importations/revert',
      getUrl: '/importations/?type=surgical-map/surgeries',
      obj_data: 'surgical-maps',
      columns: [
        {
          name: 'procedure_surgery_probable_date', label: 'DATA DA CIRURGIA', required: true, type: 'datetime',
        },
        {
          name: 'procedure_medical_record_number', label: 'NÚMERO DO PRONTUÁRIO', required: false, type: 'string',
        },
        {
          name: 'procedure_name', label: 'NOME DO PROCEDIMENTO', required: true, type: 'string',
        },
        {
          name: 'surgical-procedure_code', label: 'CÓDIGO DO PROCEDIMENTO', required: false, type: 'string',
        },
        {
          name: 'patient_name', label: 'NOME DO PACIENTE', required: true, type: 'string',
        },
        {
          name: 'patient_birth_date', label: 'NASCIMENTO DO PACIENTE', required: false, type: 'date',
        },
        {
          name: 'patient_address', label: 'ENDEREÇO DO PACIENTE', required: false, type: 'string',
        },
        {
          name: 'patient_phone_number', label: 'TELEFONE DO PACIENTE', required: false, type: 'string',
        },
        {
          name: 'patient_cpf', label: 'CPF DO PACIENTE', required: false, type: 'string',
        },
        {
          name: 'medical-plan_name', label: 'NOME DO CONVÊNIO', required: false, type: 'string',
        },
        {
          name: 'surgeon_name', label: 'NOME DO CIRURGIÃO', required: false, type: 'string',
        },
        {
          name: 'surgeon_cpf', label: 'CPF DO CIRURGIÃO', required: false, type: 'string',
        },
        {
          name: 'crm_number', label: 'CRM - Número', required: false, type: 'string',
        },
        {
          name: 'crm_state', label: 'UF', required: false, type: 'string',
        },
      ],
    },
    {
      table: 'surgical-map-pre',
      postUrl: '/surgical-map/v2/pre-anesthetics/import',
      postRevert: 'importations/revert',
      getUrl: '/importations/?type=surgical-map/pre-anesthetics',
      obj_data: 'surgical-maps',
      columns: [
        {
          name: 'procedure_pre_probable_date', label: 'DATA DA CONSULTA', required: true, type: 'datetime',
        },
        {
          name: 'patient_name', label: 'NOME DO PACIENTE', required: true, type: 'string',
        },
        {
          name: 'patient_birth_date', label: 'NASCIMENTO DO PACIENTE', required: false, type: 'date',
        },
        {
          name: 'patient_address', label: 'ENDEREÇO DO PACIENTE', required: false, type: 'string',
        },
        {
          name: 'patient_phone_number', label: 'TELEFONE DO PACIENTE', required: false, type: 'string',
        },
        {
          name: 'patient_cpf', label: 'CPF DO PACIENTE', required: false, type: 'string',
        },
        {
          name: 'patient_gender', label: 'SEXO DO PACIENTE', required: false, type: 'string',
        },
      ],
    },
    {
      table: 'anesthetists-invite',
      postUrl: '/invite/import/anesthetist',
      postRevert: '',
      getUrl: '/invite/import/anesthetists',
      obj_data: 'anesthetist',
      columns: [
        {
          name: 'name', label: 'NOME DO ANESTESISTA', required: true, type: 'string',
        },
        {
          name: 'email', label: 'EMAIL DO ANESTESISTA', required: false, type: 'email',
        },
        {
          name: 'is_resident', label: 'RESIDENTE', required: false, type: 'string',
        },
      ],
    },
  ];

  return tableColumns.find((x) => x.table === tableName);
}

export const listColumnsImport = {
  getColumns,
};
