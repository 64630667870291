import Api from '../helpers/api';

export const importService = {
  postImport,
  getImports,
  postRevert
};

function postImport(url,objImport){
  return Api.post(url, objImport)
}

function getImports(url) {
  return Api.get(url)
}

function postRevert(url,id){
  return Api.get(url+`/${id}`)
}