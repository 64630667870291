import { List } from 'immutable';
import { anesthetistsInviteConstants } from '../constants';

// eslint-disable-next-line import/prefer-default-export
export function anesthetistsInvite(state = {}, action) {
  switch (action.type) {
    case anesthetistsInviteConstants.IMPORT_ANESTEHTISTS_INVITE_REQUEST:
      return {
        ...state,
        loading: true(),
        data: new List(),
      };

    case anesthetistsInviteConstants.GET_ANESTHETISTS_INVTE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      };

    case anesthetistsInviteConstants.GET_ANESTHETISTS_INVTE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case anesthetistsInviteConstants.RETRY_ANESTHETISTS_INVITE_REQUEST:
      return {
        ...state,
        loading: true,
        data: new List(),
      };

    case anesthetistsInviteConstants.RETRY_ANESTHETISTS_INVITE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
