import React, { Component } from 'react';
import {
  Button, Col, Icon, Modal,
} from 'antd';

export default class CustomModal extends Component {
  state = {
    modalStateVisible: false,
  }

  componentDidUpdate() {
    if (!this.state.modalStateVisible && this.props.visible) {
      this.state.modalStateVisible = this.props.visible;
      this.handleVisible();
    }

    if (this.state.modalStateVisible && !this.props.visible) {
      this.state.modalStateVisible = this.props.visible;
      this.handleHidden();
    }
  }

  handleCancel = (e) => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel(e);
    }
  };

  handleOk = (e) => {
    const { onOk } = this.props;
    if (onOk) {
      onOk(e);
    }
  };

  handleVisible = (e) => {
    const { onVisible } = this.props;
    if (onVisible) {
      onVisible(e);
    }
  };

  handleHidden = (e) => {
    const { onHidden } = this.props;
    if (onHidden) {
      onHidden(e);
    }
  };

  renderTitle = () => {
    var {
      title, buttonOkText, buttonOkType, loading, closable
    } = this.props;
    if (closable == null) {
      closable = true
    }
    return (
      <div>
        {closable ? <button className="modal-close" type="button" onClick={this.handleCancel}><i className="icon-close" /></button> : '' }
        <span>{title}</span>
        {buttonOkText ? <Col xs={0} sm={4} className="btn-ok-modal"><Button type={buttonOkType} htmlType="button" onClick={this.handleOk} loading={loading}>{buttonOkText}</Button></Col> : ''}
        {buttonOkText ? <Col xs={2} sm={0} className="btn-ok-modal"><Button type={buttonOkType} htmlType="button" shape="circle" size="large" onClick={this.handleOk} loading={loading}><Icon type="edit" /></Button></Col> : ''}
      </div>
    );
  };

  render() {
    return (
      <Modal
        {...this.props}
        title={this.renderTitle()}
        bodyStyle={{ paddingTop: '21px' }}
        width="701px"
        zIndex={1024}
        footer={false}
      >
        {this.props.children}
      </Modal>
    );
  }
}
