/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';

import {
  Layout,
  Row,
  Col,
  Icon,
  Collapse,
  List,
  Button,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Select,
  notification,
  Spin,
  Tooltip,
  Alert,
  Avatar,
  Pagination,
} from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CancelToken } from 'axios';
import { pathRoutes } from '../../routes';
import { sectorsActions } from '../../actions';
import { sectorsService, roomsService } from '../../services';
import { arrayHelp, stringsHelp } from '../../helpers';
import SelectInstitutionsGroup from '../SelectInstitutionsGroup';
import { mixPanelService } from '../../services/mixpanel.service';

const { Content } = Layout;
const { Panel } = Collapse;
const FormItem = Form.Item;
const { Option } = Select;

class Sectors extends Component {
  state = {
    modalRoomVisible: false,
    actionNewRoom: true,
    loadingSaveRoom: false,
    modalSectorVisible: false,
    actionNewSector: true,
    loadingSaveSector: false,
    confirmVisible: false,
    confirmLoading: false,
    delete_uuid: undefined,
    selectGroupInstitutionId: null,
    pagination: {
      currentPage: 1,
      pageSize: 10,
      total: 0,
      defaultPageSize: 10,
      pageSizeOptions: ['10', '30', '50'],
    },
  };

  cancelSource = CancelToken.source();

  handleSectorSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(['sector'], (err, values) => {
      if (!err) {
        values = values.sector;

        let promiseSave;

        if (values.id) {
          this.setState({ loadingSaveSector: true });

          if (this.state.selectGroupInstitutionId) {
            values.institution_id = this.state.selectGroupInstitutionId;
          }

          promiseSave = sectorsService.putSector(values).then((result) => {
            if (result.id > 0) {
              this.setState({
                loadingSaveSector: false,
                modalSectorVisible: false,
              });
              notification.success({
                message: 'Setor alterado com sucesso!',
              });

              this.loadMap();
            }
          });
        } else {
          this.setState({ loadingSaveSector: true });

          delete values.id;
          delete values.uuid; // remove before post

          if (this.state.selectGroupInstitutionId) {
            values.institution_id = this.state.selectGroupInstitutionId;
          }

          promiseSave = sectorsService.postSector(values).then((result) => {
            if (result.id > 0) {
              this.handleSectorCancel();

              notification.success({
                message: 'Setor cadastrado com sucesso!',
              });

              this.loadMap();
            }
          });
        }

        promiseSave.catch((error) => {
          console.log(error);
          this.setState({ loadingSaveRoom: false });
          notification.error({
            message: error,
          });
        });
      }
    });
  };

  handleSectorCancel = () => {
    const clearSector = {
      name: '',
      id: null,
      uuid: null,
    };

    this.setState({ modalSectorVisible: false, loadingSaveSector: false });
    this.props.form.setFieldsValue({ sector: clearSector });
  };

  openSectorModal = (p_surgery_center_id) => {
    if (p_surgery_center_id) {
      mixPanelService.tracking(mixPanelService.SETOR_EDITAR);

      const sectorObject = this.props.sectors.data.find(
        (s) => s.id === p_surgery_center_id,
      );

      const editSector = {
        name: sectorObject.name,
        id: sectorObject.id,
        uuid: sectorObject.uuid,
      };

      this.setState({ modalSectorVisible: true, actionNewSector: false });
      this.props.form.setFieldsValue({ sector: editSector });
    } else {
      mixPanelService.tracking(mixPanelService.SETOR_ADD);

      const newSector = {
        name: '',
        id: null,
        uuid: null,
      };

      this.setState({ modalSectorVisible: true, actionNewSector: true });
      this.props.form.setFieldsValue({ sector: newSector });
    }
  };

  handleRoomSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll(['room'], (err, values) => {
      if (!err) {
        values = values.room;

        let promiseSave;

        if (values.id) {
          this.setState({ loadingSaveRoom: true });

          if (this.state.selectGroupInstitutionId) {
            values.institution_id = this.state.selectGroupInstitutionId;
          }

          promiseSave = roomsService.putRoom(values).then((result) => {
            if (result.id > 0) {
              this.setState({
                loadingSaveRoom: false,
                modalRoomVisible: false,
              });
              notification.success({
                message: 'Sala alterada com sucesso!',
              });

              this.loadMap();
            }
          });
        } else {
          this.setState({ loadingSaveRoom: true });

          delete values.id;
          delete values.uuid; // remove before post

          if (this.state.selectGroupInstitutionId) {
            values.institution_id = this.state.selectGroupInstitutionId;
          }

          promiseSave = roomsService.postRoom(values).then((result) => {
            if (result.id > 0) {
              this.handleRoomCancel();

              notification.success({
                message: 'Sala cadastrada com sucesso!',
              });

              this.loadMap();
            }
          });
        }

        promiseSave.catch((error) => {
          console.log(error);
          this.setState({ loadingSaveRoom: false });
          notification.error({
            message: error,
          });
        });
      }
    });
  };

  handleRoomCancel = () => {
    const clearRoom = {
      surgery_center_id: null,
      name: '',
      id: null,
      uuid: null,
    };

    this.setState({ modalRoomVisible: false, loadingSaveRoom: false });
    this.props.form.setFieldsValue({ room: clearRoom });
  };

  openRoomModal = (p_surgery_center_id, p_room_uuid) => {
    if (p_room_uuid) {
      // new

      mixPanelService.tracking(mixPanelService.SETOR_SALA_EDITAR);

      const sectorObject = this.props.sectors.data.find(
        (s) => s.id === p_surgery_center_id,
      );

      const roomObject = sectorObject.surgery_center_rooms.find(
        (x) => x.uuid === p_room_uuid,
      );

      const editRoom = {
        surgery_center_id: p_surgery_center_id,
        name: roomObject.name,
        id: roomObject.id,
        uuid: roomObject.uuid,
      };

      this.setState({ modalRoomVisible: true, actionNewRoom: false });
      this.props.form.setFieldsValue({ room: editRoom });
    } else {
      mixPanelService.tracking(mixPanelService.SETOR_SALA_ADD);

      const newRoom = {
        surgery_center_id: p_surgery_center_id,
        name: '',
        id: null,
        uuid: null,
      };

      this.setState({ modalRoomVisible: true, actionNewRoom: true });
      this.props.form.setFieldsValue({ room: newRoom });
    }
  };

  loadTotal = () => {
    sectorsService.getSectorsTotal(
      this.state.selectGroupInstitutionId,
    ).then((total) => {
      this.setState((previousState) => ({
        pagination: {
          ...previousState.pagination,
          total,
        },
      }));
    });
  };

  changePage = (currentPage, pageSize) => {
    this.setState((previousState) => ({
      pagination: {
        ...previousState.pagination,
        currentPage,
        pageSize,
      },
    }), () => {
      this.loadMap();
    });
  };

  loadMap = () => {
    const { dispatch, sectors } = this.props;

    if (sectors.loading) {
      this.cancelSource.cancel();
      this.cancelSource = CancelToken.source();
    }

    let objFilter = {};
    if (this.state.selectGroupInstitutionId) {
      objFilter = {
        params: { institution_id: this.state.selectGroupInstitutionId },
      };
    }

    const { currentPage, pageSize } = this.state.pagination;
    dispatch(
      sectorsActions.getSectors(
        objFilter,
        currentPage,
        pageSize,
        this.cancelSource.token,
      ),
    );
    this.loadTotal();
  };

  componentDidMount() {
    mixPanelService.tracking(mixPanelService.MENU_SETOR_SALA);
    if (
      JSON.parse(localStorage.getItem('selected-institution'))
      && JSON.parse(localStorage.getItem('selected-institution')).is_group
    ) {
      return;
    }

    this.loadMap();
  }

  showConfirm = (uuid, type) => {
    if (type === 'sector') {
      mixPanelService.tracking(mixPanelService.SETOR_REMOVER);
    } else {
      mixPanelService.tracking(mixPanelService.SETOR_SALA_REMOVER);
    }

    this.setState({
      confirmVisible: true,
      confirmLoading: false,
      delete_uuid: uuid,
      delete_type: type,
    });
  };

  handleDelete = () => {
    let promiseSave;

    switch (this.state.delete_type) {
      case 'sector':
        if (this.state.delete_uuid) {
          this.setState({ confirmLoading: true });

          promiseSave = sectorsService
            .deleteSector(this.state.delete_uuid)
            .then((result) => {
              notification.success({
                message: 'Setor removido com sucesso!',
              });

              this.loadMap();

              this.setState({
                confirmLoading: false,
                confirmVisible: false,
                delete_id: null,
                delete_type: null,
              });
            });

          promiseSave.catch((error) => {
            console.log(error);
            notification.error({
              message: 'Falha ao remover setor, tente novamente mais tarde!',
            });
          });
        }
        break;

      case 'room':
        if (this.state.delete_uuid) {
          this.setState({ confirmLoading: true });

          promiseSave = roomsService
            .deleteRoom(this.state.delete_uuid)
            .then((result) => {
              notification.success({
                message: 'Sala removida com sucesso!',
              });

              this.loadMap();

              this.setState({
                confirmLoading: false,
                confirmVisible: false,
                delete_id: null,
                delete_type: null,
              });
            });

          promiseSave.catch((error) => {
            console.log(error);
            notification.error({
              message: 'Falha ao remover sala, tente novamente mais tarde!',
            });
          });
        }
        break;

      default:
        break;
    }
  };

  filterInstitutionGroup = (value) => {
    if (!value) return;
    this.setState({ selectGroupInstitutionId: value }, () => {
      this.loadMap();
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { institutions } = this.props;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
      },
      colon: false,
    };

    const CollapseSectorsRooms = () => {
      const lisSectors = this.props.sectors.data ? this.props.sectors.data : [];
      let sectorsView = '';
      const arrayActive = [];

      if (lisSectors.length) {
        // defaultActiveKey
        lisSectors.map((data, index) => arrayActive.push(index.toString()));

        const sectorsViewContent = lisSectors
          .sort(arrayHelp.sortArray('-id'))
          .map((data, index) => (
            <Col md={24} lg={24} xl={24} key={`col_${index}`}>
              <Collapse
                defaultActiveKey={arrayActive}
                bordered
                key={`collapse_${index}`}
              >
                <Panel
                  key={index}
                  header={(
                    <span>
                      {data.name}
                      <Divider type="vertical" />
                      <Tooltip title="Editar">
                        <Button
                          type="link"
                          className="edit"
                          onClick={() => this.openSectorModal(data.id)}
                        >
                          <Icon type="edit" />
                        </Button>
                      </Tooltip>
                      <Divider type="vertical" />
                      <Tooltip title="Remover">
                        <Button
                          type="link"
                          onClick={() => this.showConfirm(data.uuid, 'sector')}
                        >
                          <Icon type="delete" />
                        </Button>
                      </Tooltip>
                    </span>
                  )}
                  extra={genExtra(data.id)}
                  disabled
                  showArrow={false}
                >
                  <List
                    key={`list_${index}`}
                    itemLayout="horizontal"
                    header={<div className="header-list">Salas</div>}
                    dataSource={data.surgery_center_rooms}
                    renderItem={(item) => (
                      <List.Item
                        key={`list_item_${item.id}`}
                        actions={[
                          <span>
                            <Tooltip title="Editar">
                              <Button
                                type="link"
                                onClick={() => this.openRoomModal(data.id, item.uuid)}
                              >
                                <Icon type="edit" />
                              </Button>
                            </Tooltip>
                            <Divider type="vertical" />
                            <Tooltip title="Remover">
                              <Button
                                type="link"
                                onClick={() => this.showConfirm(item.uuid, 'room')}
                              >
                                <Icon type="delete" />
                              </Button>
                            </Tooltip>
                          </span>,
                        ]}
                      >
                        <List.Item.Meta title={item.name} />
                      </List.Item>
                    )}
                  />
                </Panel>
              </Collapse>
            </Col>
          ));

        sectorsView = (
          <Col>
            {sectorsViewContent}
            <Pagination
              showSizeChanger
              onShowSizeChange={(currentPage, pageSize) => {
                this.changePage(currentPage, pageSize);
              }}
              onChange={(currentPage, pageSize) => {
                this.changePage(currentPage, pageSize);
              }}
              current={this.state.pagination.currentPage}
              defaultCurrent={1}
              total={this.state.pagination.total}
              pageSize={this.state.pagination.pageSize}
              showTotal={(total) => `Total: ${total} itens`}
              defaultPageSize={this.state.pagination.defaultPageSize}
              pageSizeOptions={this.state.pagination.pageSizeOptions}
            />
          </Col>
        );
      } else if (!this.props.sectors.loading) {
        sectorsView = <Empty />;
      } else {
        sectorsView = '';
      }

      return sectorsView;
    };

    const genExtra = (id) => (
      <span>
        {' '}
        <Button
          type="dashed"
          shape="round"
          size="small"
          onClick={() => this.openRoomModal(id, null)}
        >
          Adicionar sala
        </Button>
        {' '}
      </span>
    );

    return (
      <Content>
        {JSON.parse(localStorage.getItem('selected-institution'))
        && JSON.parse(localStorage.getItem('selected-institution')).is_group ? (
          <div>
            <Row>
              <Col xs={24} sm={0}>
                {this.state.selectGroupInstitutionId ? (
                  <Button
                    type="primary"
                    shape="circle"
                    size="large"
                    className="add-procedure"
                    onClick={() => this.openSectorModal(null)}
                  >
                    <Icon type="plus" fill="#ffffff" />
                  </Button>
                ) : (
                  <p>&nbsp;</p>
                )}
              </Col>
              <Col xs={24} sm={16}>
                <h1 className="title">Setores e salas</h1>
              </Col>
              <Col xs={24} sm={0}>
                <div style={{ marginBottom: 10 }}>
                  <SelectInstitutionsGroup onChange={this.filterInstitutionGroup} />
                </div>
              </Col>
              <Col xs={0} sm={8} xxl={8}>
                <div style={{ display: 'flex' }}>
                  <SelectInstitutionsGroup onChange={this.filterInstitutionGroup} />
                </div>
              </Col>
            </Row>
            {!this.state.selectGroupInstitutionId ? (
              <Row>
                <Col span={24} style={{ paddingBottom: 10 }}>
                  <Alert
                    message="Selecione a instituição para listar os setores e salas"
                    type="info"
                    closeText={
                      <Icon style={{ color: '#1890ff' }} type="arrow-up" />
                    }
                    showIcon
                  />
                </Col>
              </Row>
            ) : (
              <div>
                <Row style={{ marginTop: 10 }}>
                  <Col xs={0} sm={24}>
                    <Button
                      type="primary"
                      shape="round"
                      className="add-procedure"
                      onClick={() => this.openSectorModal(null)}
                    >
                      ADICIONAR SETOR
                    </Button>
                  </Col>
                </Row>
                <Row style={{ marginTop: 20 }}>
                  <Spin spinning={this.props.sectors.loading}>
                    <CollapseSectorsRooms />
                  </Spin>
                </Row>
              </div>
            )}
          </div>
          ) : (
            <div>
              <Row>
                <Col xs={24} sm={0}>
                  <Link
                    to={pathRoutes.newSurgeon}
                    className="ant-btn ant-btn-primary ant-btn-lg ant-btn-circle add-procedure"
                  >
                    <Icon type="plus" fill="#ffffff" />
                  </Link>
                </Col>
                <Col xs={24} sm={18}>
                  <h1 className="title">Setores e salas</h1>
                </Col>
                <Col xs={0} sm={6}>
                  <button
                    className="add-procedure"
                    onClick={() => this.openSectorModal(null)}
                  >
                    ADICIONAR SETOR
                  </button>
                </Col>
              </Row>
              <Row>
                <Spin spinning={this.props.sectors.loading}>
                  <CollapseSectorsRooms />
                </Spin>
              </Row>
            </div>
          )}

        <Modal
          visible={this.state.modalRoomVisible}
          onCancel={this.handleRoomCancel}
          title={(
            <div>
              <button
                className="modal-close"
                type="button"
                onClick={this.handleRoomCancel}
              >
                <i className="icon-close" />
              </button>
              <span>
                {this.state.actionNewRoom ? 'Adicionar sala' : 'Editar sala'}
              </span>
            </div>
          )}
          footer={[
            <Button
              key="submit"
              type="primary"
              form="formRoom"
              htmlType="submit"
              loading={this.state.loadingSaveRoom}
            >
              Salvar
            </Button>,
          ]}
          bodyStyle={{ paddingTop: '21px' }}
          width="701px"
          zIndex={1024}
        >
          {this.state.selectGroupInstitutionId && (
            <div>
              {JSON.parse(localStorage.getItem('selected-institution'))
              && institutions.items
              && institutions.items.size > 1
                ? institutions.items
                  .filter((i) => i.id === this.state.selectGroupInstitutionId)
                  .map((institution) => (
                    <div
                      className="ant-row ant-form-item"
                      key={institution.id}
                    >
                      <div className="ant-col ant-form-item-label">
                        <label
                          className="ant-form-item-no-colon"
                          title="INSTITUIÇÃO"
                        >
                          INSTITUIÇÃO
                        </label>
                      </div>

                      <div
                        className="ant-list-item-meta ant-group"
                        style={{ marginTop: 10 }}
                      >
                        <div className="ant-list-item-meta-avatar">
                          <Avatar
                            shape="square"
                            src={
                                institution.logo_url
                                  ? institution.logo_url
                                  : null
                              }
                            className="institution-logo"
                          >
                            Logo
                          </Avatar>
                        </div>
                        <div className="ant-list-item-meta-content">
                          <h4
                            className="ant-list-item-meta-title"
                            style={{ marginBottom: -5 }}
                          >
                            {stringsHelp.formatName(institution.name)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))
                : ''}
            </div>
          )}

          <Form onSubmit={this.handleRoomSubmit} id="formRoom">
            <Row gutter={28}>
              <Col md={24} lg={24} xl={24}>
                {getFieldDecorator('room.uuid', {})(<span />)}
                {getFieldDecorator('room.id', {})(<span />)}

                <FormItem {...formItemLayout} label="SETOR">
                  {getFieldDecorator('room.surgery_center_id', {
                    // initialValue: this.state.room.surgery_center_id
                  })(
                    <Select
                      className={this.state.actionNewRoom ? 'select-label' : ''}
                      disabled={!!this.state.actionNewRoom}
                    >
                      {!this.props.sectors.data
                        ? null
                        : this.props.sectors.data.map((sector) => (
                          <Option key={sector.id} value={sector.id}>
                            {sector.name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>

                <FormItem {...formItemLayout} label="NOME DA SALA">
                  {getFieldDecorator('room.name', {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o nome da sala.',
                      },
                    ],
                    // initialValue: this.state.room.name
                  })(<Input autoFocus />)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          visible={this.state.modalSectorVisible}
          onCancel={this.handleSectorCancel}
          title={(
            <div>
              <button
                className="modal-close"
                type="button"
                onClick={this.handleSectorCancel}
              >
                <i className="icon-close" />
              </button>
              <span>
                {this.state.actionNewSector
                  ? 'Adicionar setor'
                  : 'Editar setor'}
              </span>
            </div>
          )}
          footer={[
            <Button
              key="submit"
              type="primary"
              form="formSector"
              htmlType="submit"
              loading={this.state.loadingSaveSector}
            >
              Salvar
            </Button>,
          ]}
          bodyStyle={{ paddingTop: '21px' }}
          width="701px"
          zIndex={1024}
        >
          {this.state.selectGroupInstitutionId && (
            <div>
              {JSON.parse(localStorage.getItem('selected-institution'))
              && institutions.items
              && institutions.items.size > 1
                ? institutions.items
                  .filter((i) => i.id === this.state.selectGroupInstitutionId)
                  .map((institution) => (
                    <div
                      className="ant-row ant-form-item"
                      key={institution.id}
                    >
                      <div className="ant-col ant-form-item-label">
                        <label
                          className="ant-form-item-no-colon"
                          title="INSTITUIÇÃO"
                        >
                          INSTITUIÇÃO
                        </label>
                      </div>

                      <div
                        className="ant-list-item-meta ant-group"
                        style={{ marginTop: 10 }}
                      >
                        <div className="ant-list-item-meta-avatar">
                          <Avatar
                            shape="square"
                            src={
                                institution.logo_url
                                  ? institution.logo_url
                                  : null
                              }
                            className="institution-logo"
                          >
                            Logo
                          </Avatar>
                        </div>
                        <div className="ant-list-item-meta-content">
                          <h4
                            className="ant-list-item-meta-title"
                            style={{ marginBottom: -5 }}
                          >
                            {stringsHelp.formatName(institution.name)}
                          </h4>
                        </div>
                      </div>
                    </div>
                  ))
                : ''}
            </div>
          )}

          <Form onSubmit={this.handleSectorSubmit} id="formSector">
            <Row gutter={28}>
              <Col md={24} lg={24} xl={24}>
                {getFieldDecorator('sector.uuid', {})(<span />)}
                {getFieldDecorator('sector.id', {})(<span />)}

                <FormItem {...formItemLayout} label="NOME DO SETOR">
                  {getFieldDecorator('sector.name', {
                    rules: [
                      {
                        required: true,
                        message: 'Preencha o nome do setor.',
                      },
                    ],
                    // initialValue: this.state.room.name
                  })(<Input autoFocus />)}
                </FormItem>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title=""
          visible={this.state.confirmVisible}
          onOk={() => this.handleDelete()}
          okText="Sim"
          okType="primary"
          cancelText="Não"
          confirmLoading={this.state.confirmLoading}
          onCancel={() => this.setState({ confirmVisible: false })}
        >
          <p style={{ fontSize: 14, fontWeight: 700 }}>
            <span style={{ fontSize: 24, color: '#ffcd56', marginRight: 10 }}>
              <Icon type="question-circle" />
            </span>
            {' '}
            Confirma remover o
            {' '}
            {this.state.delete_type === 'sector' ? 'setor' : 'sala'}
            {' '}
            ?
          </p>
        </Modal>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { sectors, institutions } = state;
  return {
    sectors,
    institutions,
  };
}

const connected = withRouter(connect(mapStateToProps)(Form.create()(Sectors)));
export default connected;
