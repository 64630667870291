/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import { patientConstants } from '../constants';
import { patientService } from '../services';

function getPatients(page, filter, limit) {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch(request());

    return patientService.getPatientsByPage(page, filter, limit)
      .then((patients) => {
        if (patients && patients.result && patients.count) {
          dispatch(success(patients.result, patients.count, patients.perPage, page));
          resolve(patients);
        } else {
          reject(new Error('Resposta inválida da API'));
          return dispatch(failure(new Error('Resposta inválida da API', patients)));
        }
      })
      .catch((error) => {
        reject(error);
        return dispatch(failure(error));
      });
  });

  function request() {
    return { type: patientConstants.GETPATIENTS_REQUEST };
  }

  function success(patients, total, perPage, page) {
    return {
      type: patientConstants.GETPATIENTS_SUCCESS, patients, total, perPage, page,
    };
  }

  function failure(error) {
    return { type: patientConstants.GETPATIENTS_FAILURE, error };
  }
}

export const patientActions = {
  getPatients,
};
