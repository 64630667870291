import iconColor from "../../assets/imgs/icon_color.png";
import React, {Component} from "react";
import {Layout as Footer} from 'antd';

export default class AppFooter extends Component {
  render() {
    return (
      <div>
      <Footer style={{textAlign: 'center', paddingTop: 20, paddingBottom: 10, ...this.props.style}}>
        <span>
          Powered by <img src={iconColor} alt="Logo"/>
        </span>
      </Footer>
      </div>
    );
  }
}
