export {
  getFilterProcedures,
  setFilterProcedures,

};

function getFilterProcedures() {
  return JSON.parse(localStorage.getItem('filter-procedure'));
}

function setFilterProcedures(objFilter) {
  return localStorage.setItem('filter-procedure', JSON.stringify(objFilter));
}
