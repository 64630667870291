import { medicalPlansConstants } from '../constants';
import {List} from "immutable";

export function medicalPlans(state = {}, action) {
  switch (action.type) {
    case medicalPlansConstants.GETMEDICALPLANS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        data: new List()
      });
    case medicalPlansConstants.GETMEDICALPLANS_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.data
      });
    case medicalPlansConstants.GETMEDICALPLANS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        error: action.error
      });
    default:
      return state
  }
}
