import './device';
import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale-provider/pt_BR';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import App from './App';
import './index.scss';
import { store } from './helpers';
import { PrivateRoute } from './components/routes/PrivateRoute';
import Login from './components/pages/Login';
import Logout from './components/pages/Logout';
import RedirectApp from './components/pages/RedirectApp';
import NotFound from './components/routes/NotFound';
import AcceptInvite from './components/routes/AcceptInvite';
import ValidateEmail from './components/routes/ValidateEmail';
import ResetPassword from './components/routes/ResetPassword';
import { pathRoutes } from './routes';

ReactDOM.render((
  <Provider store={store}>
    <ConfigProvider locale={ptBR}>
      <BrowserRouter>
        <Switch>
          <Route path={pathRoutes.redirectToApp} component={RedirectApp} />
          <Route path={pathRoutes.logout} component={Logout} />
          <Route path={pathRoutes.login} component={Login} />
          <PrivateRoute path={`(${Object.values(pathRoutes).join('|')})`} component={App} />
          <Route path={pathRoutes.acceptInvite} component={AcceptInvite} />
          <Route path={pathRoutes.validateEmail} component={ValidateEmail} />
          <Route path={pathRoutes.forgotPassword} component={ResetPassword} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </ConfigProvider>
  </Provider>
), document.getElementById('root'));
