import React, { Component } from "react";

import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Layout,
  notification,
  Row,
  Select,
  Spin
} from "antd";

import { connect } from "react-redux";
import { selectsActions } from "../../actions";
import MaskedInput from "react-text-mask";
import moment from "moment";
import { surgeonsService } from "../../services";
import { withRouter } from "react-router-dom";
import { stringsHelp } from "../../helpers";
import validarCpf from "validar-cpf";
import { mixPanelService } from '../../services/mixpanel.service';
const FormItem = Form.Item;
const { Content } = Layout;
const Option = Select.Option;
const cpfMask = [
  /\d/, /\d/, /\d/, '.', 
  /\d/, /\d/, /\d/, '.', 
  /\d/, /\d/, /\d/, '-', 
  /\d/, /\d/
];


class Surgeon extends Component {
  state = {
    surgeon: {},
    loading: false,
    maskKey: 0  // Nova chave adicionada aqui
  };
  

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true }, () => {
          this.setState(prevState => ({
            surgeon: {
              ...prevState.surgeon,
              ...values
            }
          }), () => {
            let surgeon = this.state.surgeon;
            let promiseSave;
  
            if (surgeon.id > 0) {
              mixPanelService.tracking(mixPanelService.CIRUGIAO_EDITAR);
  
              promiseSave = surgeonsService.putSurgeon(surgeon).then(result => {
                if (result.id > 0) {
                  this.setState({ loading: false });
  
                  notification.success({
                    message: "Cirurgião alterado com sucesso"
                  });
  
                  this.props.history.goBack();
                }
              });
            } else {
              mixPanelService.tracking(mixPanelService.CIRUGIAO_SALVAR);
  
              promiseSave = surgeonsService.postSurgeon(surgeon).then(result => {
                if (result.id > 0) {
                  this.setState({ loading: false });
                  notification.success({
                    message: "Cirurgião cadastrado com sucesso"
                  });
  
                  this.props.history.goBack();
                }
              });
            }
  
            promiseSave.catch(error => {
              console.log(error);
              notification.error({
                message: error
              });
            });
          });
        });
      }
    });
  };
  

  componentDidMount() {
    const { dispatch, match } = this.props;
  
    if (match.params.id) {
      this.setState({ loading: true });
  
      surgeonsService
        .getSurgeon(match.params.id)
        .then(surgeon => {
          surgeon = surgeon ? surgeon : {};
  
          if (!surgeon.id) {
            return null;
          }
  
          let arraySpecialites = [];
          surgeon.specialities.map(specialites => {
            arraySpecialites.push(specialites.id);
            return specialites;
          });
  
          surgeon.specialities = arraySpecialites;
  
          // Atualize a chave maskKey aqui
          this.setState({ surgeon, loading: false, maskKey: this.state.maskKey + 1 });
        })
        .catch(error => {
          console.log(error);
          notification.error({
            message: "Não foi possível recuperar o cirurgião."
          });
        });
    }
  
    dispatch(selectsActions.getSpecialites());
    dispatch(selectsActions.getStates());
  }
  
  
  validatorBirthDate = (rule, value, callback) => {
    if (
      value &&
      moment()
        .startOf("day")
        .diff(value.startOf("day"), "days") < 0
    ) {
      callback(rule.message);
    }
    callback();
    return null;
  };

  validatorCPF = (rule, value, callback) => {
    if (value && !validarCpf(value)) {
      callback(rule.message);
      return null;
    } else {
      callback();
      return null;
    }
  };

  onChangePhoneNumber = a => {
    a.target.value = stringsHelp.formatPhoneNumber(a.target.value);
  };

  focusField(name) {
    let field = this.props.form.getFieldInstance(name);

    if (!field) {
      return;
    }

    if (field.picker) {
      field.picker.setState({ open: true });
    } else if (field.timePickerRef) {
      field.timePickerRef.setState({ open: true });
    } else if (field.inputElement) {
      field.inputElement.focus();
    } else {
      field.focus();
    }
  }

  blurField(name) {
    let field = this.props.form.getFieldInstance(name);

    if (!field) {
      return;
    }

    if (field.picker) {
      field.picker.setState({ open: false });
    } else if (field.timePickerRef) {
      field.timePickerRef.setState({ open: false });
    } else if (field.inputElement) {
      field.inputElement.blur();
    } else {
      field.blur();
    }
  }

  render() {
    const { specialites, statesUF, match } = this.props;
    const { getFieldDecorator } = this.props.form;

    let { surgeon, loading } = this.state;
    let isEdit = match.params.id;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 }
      },
      wrapperCol: {
        xs: { span: 24 }
      },
      colon: false
    };

    return (
      <Content>
        <Spin spinning={loading}>
          <Form onSubmit={this.handleSubmit}>
            <Row>
              <Col xs={24} sm={0}>
                <Button
                  type="primary"
                  className="add-procedure"
                  shape="circle"
                  size="large"
                  htmlType={loading ? "button" : "submit"}
                  loading={loading}
                >
                  {loading ? "" : <Icon type="check" fill="#ffffff" />}
                </Button>
              </Col>
              <Col xs={24} sm={20}>
                <h1 className="title">
                  {isEdit ? "Editar" : "Novo"} cirurgião
                </h1>
              </Col>
              <Col xs={0} sm={4}>
                <Button
                  type="primary"
                  className="add-procedure"
                  htmlType="submit"
                  loading={loading}
                >
                  SALVAR
                </Button>
              </Col>
            </Row>
            <Row gutter={28}>
              <Col md={24} lg={24} xl={12}>
                <FormItem {...formItemLayout} label="NOME COMPLETO">
                  {getFieldDecorator("name", {
                    rules: [
                      {
                        required: true,
                        message: "Preencha o nome do cirurgião."
                      }
                    ],
                    initialValue: surgeon.name
                  })(
                    <Input
                      onChange={e =>
                        (e.target.value = stringsHelp.firstLetterUpper(
                          e.target.value,
                          true
                        ))
                      }
                    />
                  )}
                </FormItem>
              </Col>

              <Col md={24} lg={24} xl={12}>
                <FormItem {...formItemLayout} label="ESPECIALIDADE">
                  {getFieldDecorator("specialities", {
                    initialValue: surgeon.specialities
                  })(
                    <Select
                      loading={specialites ? specialites.loading : false}
                      mode="multiple"
                      showSearch
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {!specialites
                        ? null
                        : specialites.data.map(specialites => {
                            return (
                              <Option
                                value={specialites.id}
                              >
                                {specialites.name}
                              </Option>
                            );
                          })}
                    </Select>
                  )}
                </FormItem>
              </Col>

              <Col md={24} lg={5} xl={5}>
                
              <FormItem {...formItemLayout} label="CPF">
              {getFieldDecorator("cpf", {
  initialValue: surgeon.cpf,
  rules: [
    {
      validator: this.validatorCPF,
      message: "CPF informado inválido."
    },
  ]
})(
  <MaskedInput
    key={this.state.maskKey}  // Use a chave aqui
    mask={cpfMask}
    guide={false}
    className="ant-input"
  />
)}
</FormItem>

              </Col>

              <Col xs={24} sm={16} md={16} lg={4} xl={4}>
                <FormItem {...formItemLayout} label="CRM">
                  {getFieldDecorator("crm_number", {
                    initialValue: surgeon.crm_number,
                    rules: [
                      {
                        required: true,
                        message: "Preencha o número do CRM."
                      }
                    ]
                  })(<Input />)}
                </FormItem>
              </Col>
              

              <Col xs={24} sm={8} md={8} lg={3} xl={3}>
                <FormItem {...formItemLayout} label="UF">
                  {getFieldDecorator("crm_state_id", {
                    initialValue: surgeon.crm_state_id,
                    rules: [
                      {
                        required: true,
                        message: "Selecione a UF."
                      }
                    ]
                  })(
                    <Select loading={statesUF ? statesUF.loading : false}>
                      {!statesUF
                        ? null
                        : statesUF.data.map(statesUF => {
                            return (
                              <Option key={statesUF.id} value={statesUF.id}>
                                {statesUF.acronym}
                              </Option>
                            );
                          })}
                    </Select>
                  )}
                </FormItem>
              </Col>

              <Col md={24} lg={5} xl={5}>
                <FormItem {...formItemLayout} label="TELEFONE">
                  {getFieldDecorator("phone_number", {
                    initialValue: surgeon.phone_number
                  })(
                    <Input onChange={this.onChangePhoneNumber} maxLength={15} />
                  )}
                </FormItem>
              </Col>

              <Col md={24} lg={7} xl={7}>
              <FormItem
                {...formItemLayout}
                label="EMAIL"
              >
                {getFieldDecorator('email', {initialValue: surgeon.email})(
                  <Input type={'email'}/>
                )}
              </FormItem>
            </Col>
            </Row>
          </Form>
        </Spin>
      </Content>
    );
  }
}

function mapStateToProps(state) {
  const { selects, institutions } = state;
  const { specialites, statesUF, tables } = selects;
  return {
    specialites,
    statesUF,
    institutions,
    tables
  };
}

const connected = withRouter(connect(mapStateToProps)(Form.create()(Surgeon)));
export default connected;
