/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import Api from '../helpers/api';

function getEthnicGroups() {
  return Api.get('/app/ethnic-groups');
}

function getSurgicalProceduresMostUsed(table_name) {
  return Api.get(`/surgical-map/surgical-procedures/most-used/${table_name}`);
}

function getSurgicalProcedures(table_name, page, filter, cancelToken) {
  filter = filter === undefined ? '' : filter;
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/surgical-map/surgical-procedures/${page}/${table_name}${filter !== '' ? `/${filter}` : ''}`, { cancelToken });
}

function getSurgeons(page, filter, cancelToken) {
  filter = filter === undefined ? '' : filter;
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/surgical-map/surgeons/${page}${filter !== '' ? `/${filter}` : ''}`, { cancelToken });
}

async function getAnaesthetists(page, filter, cancelToken) {
  filter = filter === undefined ? '' : filter;
  cancelToken = cancelToken === undefined ? null : cancelToken;

  const data = await Api.get(`/surgical-map/anaesthetists/${page}${filter !== '' ? `/${filter}` : ''}`, { cancelToken });

  if (data.pages > page) {
    const anaesthetists = { result: [] };

    for (let i = page; i <= data.pages; i++) {
      const apiResponse = await Api.get(`/surgical-map/anaesthetists/${i}${filter !== '' ? `/${filter}` : ''}`, { cancelToken });
      anaesthetists.result = [...anaesthetists.result, ...apiResponse.result];
    }

    anaesthetists.result = anaesthetists
      .result
      .sort((a, b) => a.name.localeCompare(b.name));

    anaesthetists.result = [...new Set(anaesthetists.result)];

    return anaesthetists;
  }

  return data;
}

function getSurgeryCenters(institutionId = null) {
  return Api.get(`/surgical-map/surgery_centers/${institutionId ? `?institution_id=${institutionId}` : ''}`)
    .then((centers) => {
      localStorage.setItem('surgery_centers', JSON.stringify(centers));
      return centers;
    });
}

function getSurgeryCenterRooms(surgery_center_id) {
  return new Promise((resolve, reject) => {
    try {
      const centers = JSON.parse(localStorage.getItem('surgery_centers'));

      const center = centers.filter((i) => i.id === surgery_center_id);

      if (center.length === 1) {
        resolve(center[0].surgery_center_rooms);
      } else {
        throw new Error('Não foi possível identificar o centro cirúrgico.');
      }
    } catch (e) {
      reject(e);
    }
  });
}

function getSurgeryTypes() {
  return Api.get('/app/surgery_types');
}

function getRooms() {
  return Api.get('/app/rooms');
}

function getMedicalPlans() {
  return Api.get('/surgical-map/medical-plans');
}
async function getMultipleMedicalPlans(page, filter, cancelToken) {
  filter = filter === undefined ? '' : filter;
  cancelToken = cancelToken === undefined ? null : cancelToken;

  const result = await Api.get(`/v2/surgical-map/medical-plans/${page}${filter !== '' ? `/${filter}` : ''}`, { cancelToken });
  return result;
}
function getSpecialites() {
  return Api.get('/v2/surgeons/specialities')
    .then((specialities) => {
      localStorage.setItem('specialites', JSON.stringify(specialities));
      return specialities;
    });
}

function getStates() {
  return Api.get('/v2/states')
    .then((states) => {
      localStorage.setItem('states', JSON.stringify(states));
      return states;
    });
}

function getTables() {
  return Api.get('/v2/medical-plan-tables')
    .then((states) => {
      localStorage.setItem('tables', JSON.stringify(states));
      return states;
    });
}

function getSurgicalTeamPositions() {
  return Api.get('/preset/surgical-team-positions')
    .then((states) => states);
}

function getCancellationReason() {
  return Api.get('/app/procedures/cancellation-reasons')
    .then((states) => states);
}

async function getTransEventMaterials(filter = undefined, page, limit, cancelToken) {
  return Api.get('/materials-trans-events', {
    cancelToken,
    params: {
      page,
      limit,
      ...filter,
    },
  });
}

export const selectsService = {
  getEthnicGroups,
  getSurgeryCenters,
  getSurgeryCenterRooms,
  getRooms,
  getMedicalPlans,
  getSurgicalProceduresMostUsed,
  getSurgicalProcedures,
  getSurgeryTypes,
  getSurgeons,
  getAnaesthetists,
  getSpecialites,
  getStates,
  getTables,
  getSurgicalTeamPositions,
  getCancellationReason,
  getMultipleMedicalPlans,
  getTransEventMaterials,
};
