import Api from '../helpers/api';

export const surgeonsService = {
  getSurgeons,
  getSurgeon,
  postSurgeon,
  putSurgeon,
  verifySurgeonCPF,
  deleteSurgeon
};

function getSurgeons(filter, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/preset/surgeons/?size=1000&offset=0`,{ crossDomain:true,cancelToken})
}

function getSurgeon(id, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  return Api.get(`/preset/surgeons/${id}`, {cancelToken})
}

function postSurgeon(surgeon){
  return Api.post(`/preset/surgeons`, surgeon)
}

function putSurgeon(surgeon){
  return Api.put(`/preset/surgeons/${surgeon.id}`, surgeon)
}

function deleteSurgeon(uuid){
  return Api.delete(`/web/surgeons/${uuid}`)
}

function verifySurgeonCPF(cpf){
  return Api.get(`/preset/surgeons/${cpf}`)
}