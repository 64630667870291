import axios from 'axios';
import {
  getToken, getValidSession, logout, setToken,
} from './auth';
import config from '../config';

const api = axios.create({
  baseURL: config.urlApi,
  responseType: 'json',
});

api.interceptors.request.use(async (config) => {
  const token = getToken();

  if (token) {
    config.headers.Authorization = token;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    if (getToken() !== response.headers.authorization) {
      setToken(response.headers.authorization); // edit new token expire 15 min
    }
    return response.data;
  },
  (error) => {
    let errorObj = null;

    if (typeof error === 'string') {
      errorObj = JSON.parse(JSON.stringify(error));
    } else {
      errorObj = error;
    }

    if (errorObj.hasOwnProperty('CancellationError')) return errorObj;

    if (!getValidSession()) {
      logout();
    }

    if (errorObj.hasOwnProperty('response')) {
      if (errorObj.response.status === 401) {
        // auto logout if 401 response returned from api
        if (errorObj.response.data === 'user.password.invalid') {
          errorObj.response.data = 'Usuário não autorizado, verifique suas credenciais.';
        } else if (errorObj.response.data === 'without.permission') {
          errorObj.response.data = 'Usuário com permissões insuficientes.';
        } else if (errorObj.response.config.url === '/manager/login') {
          errorObj.response.data = 'Falha na autenticação.';
        } else {
          logout();
        }
      }
    }

    return Promise.reject(errorObj.response);
  },
);

export default api;
