import { List } from 'immutable';
import { proceduresConstants } from '../constants';

export function procedures(state = {}, action) {
  switch (action.type) {
    case proceduresConstants.GETPROCEDRURES_REQUEST:
      return {
        ...state,
        loading: true,
        data: new List(),
      };
    case proceduresConstants.GETPROCEDRURES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data.procedures,
        count: action.data.count,
      };
    case proceduresConstants.GETPROCEDRURES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
