import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { institutions } from './institution.reducer';
import { patient } from './patient.reducer';
import { selects } from './selects.reducer';
import { scheduling } from './scheduling.reducer';
import { surgeons } from './surgeons.reducer';
import { sectors } from './sectors.reducer';
import { medicalPlans } from './medicalPlans.reducer';
import { materials } from './materials.reducer';
import { users } from './users.reducer';
import { surgicalTeams } from './surgicalTeams.reducer';
import { drugs } from './drugs.reducer';
import { monitorQrcodes } from './monitor-qrcodes.reducer';
import { procedures } from './procedures.reducer';
import { anesthetistsInvite } from './anesthetistsInvite.reducer';
import { events } from './events.reducer';

const rootReducer = combineReducers({
  authentication,
  alert,
  institutions,
  patient,
  selects,
  scheduling,
  surgeons,
  sectors,
  medicalPlans,
  materials,
  users,
  surgicalTeams,
  drugs,
  monitorQrcodes,
  procedures,
  anesthetistsInvite,
  events,
});

export default rootReducer;
