// eslint-disable-next-line import/no-cycle
import Api from '../helpers/api';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function getPatients(page, limit, filter, cancelToken, config) {
  await sleep(1000);
  filter = filter === undefined ? '' : filter;
  cancelToken = cancelToken === undefined ? null : cancelToken;
  const result = {};

  result.patients = await Api.get('/surgical-map/v2/patients/', {
    cancelToken,
    ...config,
    params: {
      page,
      limit,
      filter,
    },
  });
  return result;
}

function getPatient(id, cancelToken) {
  cancelToken = cancelToken === undefined ? null : cancelToken;
  return Api.get(`/surgical-map/v1/patient/${id}`, { cancelToken });
}

function postPatient(patient) {
  return Api.post('/app/patients?fullDeduplication', patient);
}

function putPatient(patient) {
  return Api.put(`/app/v2/patients/${patient.id}?withChangelog`, patient);
}

async function getPatientCount(cancelToken, config = {}) {
  cancelToken = cancelToken === undefined ? null : cancelToken;

  try {
    const response = await Api.get('/surgical-map/v2/patients/count', {
      cancelToken,
      ...config,
    });

    if (response.data && response.data.count !== undefined) {
      return response.data.count;
    }

    return response;
  } catch (error) {
    console.error('Erro ao buscar contagem de pacientes:', error);
    throw error;
  }
}

async function getPatientsByPage(page, filter, limit) {
  try {
    const response = await Api.get(`/surgical-map/patients/${page}`, {
      params: { limit, filter },
    });

    return response;
  } catch (error) {
    console.error('Erro ao obter pacientes:', error);
    throw error;
  }
}

export const patientService = {
  getPatients,
  getPatient,
  postPatient,
  putPatient,
  getPatientCount,
  getPatientsByPage,
};
