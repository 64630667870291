import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Button,
  Col,
  Form,
  Icon,
  Input,
  Layout,
  notification,
  Row,
  Select,
  Spin,
  Checkbox,
  Upload,
  Avatar,
  DatePicker,
  Divider,
  Tooltip,
} from 'antd';
import MaskedInput from 'react-text-mask';
import Api from '../../helpers/api';
import statesUF from '../../helpers/estatesBr.json';
import countries from '../../helpers/countries.json';
import { institutionService } from '../../services/institution.service';

const FormItem = Form.Item;
const { Content } = Layout;
const { Option } = Select;

const cpfMask = [
  /\d/, /\d/, /\d/, '.',
  /\d/, /\d/, /\d/, '.',
  /\d/, /\d/, /\d/, '-',
  /\d/, /\d/, /\d/,
];

const cnpjMask = [
  /\d/, /\d/, '.',
  /\d/, /\d/, /\d/, '.',
  /\d/, /\d/, /\d/, '/',
  /\d/, /\d/, /\d/, /\d/, '-',
  /\d/, /\d/,
];

class Institution extends Component {
  state = {
    loading: true,
    institution: {},
    group: {},
    network: {},
    id: this.props.match.params.id,
    institution_logo: [],
    groups: [],
    logo64: null,
    isGroup: true,
    isNetwork: false,
    searchTimeout: null,
    is_group: false,
    is_network: false,
    mask: cnpjMask,
  };

  componentDidMount() {
    if (!this.state.id) return this.setState({ loading: false });

    this.setState({ loadingGroups: true });

    Api.get(`/preset/institutions/${this.state.id}`).then((resp) => {
      this.setState({
        institution: resp,
        group: resp.group_institution.length > 0 ? resp.group_institution[0] : {},
        network: resp.network.length > 0 ? resp.network[0] : {},
        isGroup: resp.is_network ? false : !resp.is_group,
        isNetwork: resp.is_network,
        is_group: resp.is_group,
        is_network: resp.is_network,
      });

      const groupIds = resp.group_institution.map((x) => x.group_id);
      const networkIds = resp.network.map((x) => x.institution_id);

      if (groupIds.length) {
        Api.get(`/web/groups/${groupIds.length ? `?institutions=${JSON.stringify(groupIds)}` : ''}`).then((groups) => {
          this.setState({
            groups,
          });
          this.setState({ loadingGroups: false });
        }).catch((err) => {
          notification.error('Ocorreu um erro ao consultar os grupos');
          console.error(err);
        });
      } else {
        this.setState({ loadingGroups: false });
      }

      if (networkIds.length) {
        Api.get(`/web/networks/${networkIds.length ? `?institutions=${JSON.stringify(networkIds)}` : ''}`).then((networks) => {
          this.setState({
            networks,
          });
          this.setState({ loadingNetworks: false });
        }).catch((err) => {
          notification.error('Ocorreu um erro ao consultar os grupos');
          console.error(err);
        });
      } else {
        this.setState({ loadingNetworks: false });
      }

      this.setState({ loading: false });
    }).catch((err) => {
      console.error(err);
    });
  }

  handleChangePaymentStart = (e) => {
    this.setState({ ...this.state, institution: { ...this.state.institution, payment_start: e } });
  };

  handleChangePaymentEnd = (e) => {
    this.setState({ ...this.state, institution: { ...this.state.institution, payment_end: e } });
  };

  handleInputChange = (event) => {
    const auxValues = { ...this.state.institution };
    auxValues[event.target.value] = event.target.checked;
    this.setState({ institution: auxValues });
  };

  handleCnpjInputChange = (event) => {
    const { value } = event.target;
    const onlyDigits = value.replace(/\D/g, '');
    let newMask;

    if (onlyDigits.length <= 11) {
      newMask = cpfMask;
    } else {
      newMask = cnpjMask;
    }

    if (newMask !== this.state.mask) {
      this.setState({ mask: newMask });
      this.props.form.setFieldsValue({ document: value });
    }
  };

  handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;

    if (event.target.value === 'is_group') {
      this.state.isGroup = !event.target.checked;

      this.state.institution.is_group = checked;
      this.setState({
        [name]: checked,
      });
    }

    if (event.target.value === 'is_network') {
      if (event.target.checked) {
        this.state.isGroup = false;
      } else {
        this.state.isGroup = !this.state.is_group;
      }
      this.state.isNetwork = event.target.checked;

      this.state.institution.is_network = checked;
      this.setState({
        [name]: checked,
      });
    }
  };

  handleSearchNetworks = (event) => {
    const filter = event;
    clearTimeout(this.state.searchTimeout);
    if (filter.trim()) {
      this.state.searchTimeout = setTimeout(() => {
        this.setState({ loadingNetworks: true });
        Api.get(`/web/networks/?filter=${filter}`).then((networks) => {
          this.setState({
            networks,
          });
          this.setState({ loadingNetworks: false });
        });
      }, 800);
    } else {
      this.setState({
        loadingNetworks: false,
      });
    }
  };

  handleSearchGroups = (event) => {
    const filter = event;
    clearTimeout(this.state.searchTimeout);
    if (filter.trim()) {
      this.state.searchTimeout = setTimeout(() => {
        this.setState({ loadingGroups: true });
        Api.get(`/web/groups/?filter=${filter}`).then((groups) => {
          this.setState({
            groups,
          });
          this.setState({ loadingGroups: false });
        });
      }, 800);
    } else {
      this.setState({
        loadingGroups: false,
      });
    }
  };

  handleSelectChange = (value) => {
    this.setState({
      group: { group_id: value },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log(this.state.institution, values);
        this.setState({ loading: true });
        const institution = Object.assign(this.state.institution, values);
        let promiseSave;

        if (institution.id > 0) {
          promiseSave = Api.put(
            `/v1/institutions/${this.state.id}`,
            institution,
          );
        } else {
          promiseSave = Api.post('/preset/institutions', institution);
        }

        promiseSave.then(async (institutionCreated) => {
          const { institution_logo } = this.state;
          let groupIds = this.state.institution.group_id;
          let networkIds = this.state.institution.network_id;
          if (institution_logo.length) {
            await institutionService.saveInstitutionLogo(
              institution.uuid ? institution.uuid : institutionCreated.uuid,
              this.state.institution_logo[0],
            );
          }

          if (!this.state.isGroup) {
            groupIds = [];
          }

          await institutionService.associateToGroup(
            institution.id ? institution.id : institutionCreated.id,
            groupIds,
          );

          if (!this.state.isNetwork) {
            networkIds = [];
          }

          await institutionService.associateToNetwork(
            institution.id ? institution.id : institutionCreated.id,
            networkIds,
          );

          return true;
        }).then(() => {
          this.setState({ loading: false });
          const action = institution.id > 0 ? 'alterada' : 'cadastrada';

          notification.success({
            message: `Instituição ${action} com sucesso`,
          });

          this.props.history.goBack();
        });

        promiseSave.catch((error) => {
          console.error(error);
          notification.error({
            message: error,
          });
        });
      }
    });
  };

  handleSubmitEnableSignature = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ loading: true });
        const institution = Object.assign(this.state.institution, values);
        let promiseSave;

        if (institution.id > 0) {
          promiseSave = Api.put(
            `/preset/institutions/${this.state.id}`,
            institution,
          );
        } else {
          promiseSave = Api.post('/preset/institutions', institution);
        }

        promiseSave.then(async (institutionCreated) => {
          const { institution_logo } = this.state;
          let groupIds = this.state.institution.group_id;
          let networkIds = this.state.institution.network_id;
          if (institution_logo.length) {
            await institutionService.saveInstitutionLogo(
              institution.uuid ? institution.uuid : institutionCreated.uuid,
              this.state.institution_logo[0],
            );
          }

          if (!this.state.isGroup) {
            groupIds = [];
          }

          await institutionService.associateToGroup(
            institution.id ? institution.id : institutionCreated.id,
            groupIds,
          );

          if (!this.state.isNetwork) {
            networkIds = [];
          }

          await institutionService.associateToNetwork(
            institution.id ? institution.id : institutionCreated.id,
            networkIds,
          );

          return true;
        }).then(() => {
          this.setState({ loading: false });
          const action = institution.id > 0 ? 'alterada' : 'cadastrada';

          notification.success({
            message: `Instituição ${action} com sucesso`,
          });

          this.props.history.goBack();
        });

        promiseSave.catch((error) => {
          console.error(error);
          notification.error({
            message: error,
          });
        });
      }
    });
  };

  toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  viewLogo = async (file) => {
    const result = await this.toBase64(file).catch((e) => Error(e));
    if (result instanceof Error) {
      console.error('Error: ', result.message);
      return;
    }

    this.setState({ logo64: result });
  }

  checkValidateFormat = (values) => {
    if (values) {
      const { size, type } = values;
      const typePermissions = ['png', 'jpg', 'jpeg'];
      const sizePermission = 120000; // 120kb
      if (size > sizePermission || !typePermissions.includes(type.split('/')[1])) {
        notification.error({
          message: 'O arquivo selecionado excede o tamanho máximo ou formato permitido.',
        });
        this.setState({
          institution_logo: [],
        });
        return false;
      }
    }
    return true;
  }

  render() {
    const { institution, group, network } = this.state;
    const isEdit = !!this.state.id;
    const { isGroup } = this.state;
    const { isNetwork } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
      },
      colon: false,
    };
    const { getFieldDecorator } = this.props.form;
    if (this.state.loading) return <Spin spinning={this.loading} />;

    return (
      <Content>
        <Form {...formItemLayout} onSubmit={this.handleSubmitEnableSignature}>
          <Row>
            <Col xs={24} sm={0}>
              <Button
                type="primary"
                className="add-procedure"
                shape="circle"
                size="large"
                htmlType="submit"
              >
                <Icon type="check" fill="#ffffff" />
              </Button>
            </Col>
            <Col xs={24} sm={20}>
              <h1 className="title">
                {isEdit ? 'Editar' : 'Nova'}
                {' '}
                instituição
                {' '}
                { isEdit ? this.state.id : ''}
              </h1>
            </Col>
            <Col xs={0} sm={4}>
              <Button
                type="primary"
                className="add-procedure"
                htmlType="submit"
                loading={this.loading}
              >
                SALVAR
              </Button>
            </Col>
          </Row>

          <Row gutter={28}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="Nome da instituição">
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Preencha o nome da instituição.',
                    },
                  ],
                  initialValue: institution.name,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="Cidade">
                {getFieldDecorator('city', {
                  initialValue: institution.city,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="UF">
                {getFieldDecorator('state_id', { initialValue: institution.state_id })(
                  <Select
                    showSearch
                    showAction={['focus', 'click']}
                    filterOption={
                      (input, option) => option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {statesUF.map((statesUF) => (
                      <Option key={statesUF.id} value={statesUF.id}>
                        {statesUF.sigla}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="País">
                {getFieldDecorator('country_id', { initialValue: institution.country_id })(
                  <Select
                    showSearch
                    showAction={['focus', 'click']}
                    filterOption={
                      (input, option) => option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countries.map((countries) => (
                      <Option key={countries.id} value={countries.id}>
                        {countries.name}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="Endereço">
                {getFieldDecorator('address', {
                  initialValue: institution.address,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="Telefone">
                {getFieldDecorator('phone_number', {
                  initialValue: institution.phone_number,
                })(<Input />)}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="CPF/CNPJ">
                {getFieldDecorator('document', { initialValue: institution.document })(
                  <MaskedInput
                    mask={this.state.mask}
                    guide={false}
                    className="ant-input"
                    onChange={this.handleCnpjInputChange}
                  />,
                )}
              </FormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="CNES">
                {getFieldDecorator('cnes_code', {
                  initialValue: institution.cnes_code,
                })(<Input />)}
              </FormItem>
            </Col>
            {institution.is_healthchess
            && (
            <>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormItem label="Login do healthchess">
                  {getFieldDecorator('healthchess_login', {
                    initialValue: institution.healthchess_login,
                  })(<Input />)}
                </FormItem>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <FormItem
                  label="Senha do healthchess"
                >
                  <Tooltip title="Deixe em branco para não alterar.">
                    {getFieldDecorator('healthchess_password')(<Input />)}
                  </Tooltip>
                </FormItem>
              </Col>
            </>
            )}
          </Row>
          <Row gutter={28}>

            {isGroup && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item {...formItemLayout} label="Grupo associado">
                  {getFieldDecorator('group_id', {
                    initialValue: institution.group_institution
                      ? institution.group_institution.map((x) => x.group_id)
                      : [],
                  })(
                    <Select
                      showSearch
                      showArrow
                      filterOption={false}
                      mode="multiple"
                      placeholder="Nenhum"
                      onSelect={this.handleSelectChange}
                      onSearch={this.handleSearchGroups}
                      loading={this.state.loadingGroups}
                      showAction={['focus', 'click']}
                    >

                      {!this.state.groups
                        ? null
                        : this.state.groups.map((group) => (
                          <Option
                            key={group.id}
                            value={group.id}
                          >
                            {group.name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>

              </Col>
            )}

            {isNetwork && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Form.Item {...formItemLayout} label="Instituição associada">
                  {getFieldDecorator('network_id', {
                    initialValue: institution.network
                      ? institution.network.map((x) => x.institution_id)
                      : [],
                  })(
                    <Select
                      showSearch
                      showArrow
                      filterOption={false}
                      mode="multiple"
                      placeholder="Nenhum"
                      onSelect={this.handleSelectChange}
                      onSearch={this.handleSearchNetworks}
                      loading={this.state.loadingNetworks}
                      showAction={['focus', 'click']}
                    >
                      {!this.state.networks
                        ? null
                        : this.state.networks.map((x) => (
                          <Option
                            key={x.id}
                            value={x.id}
                          >
                            {x.name}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </Form.Item>

              </Col>
            )}

            <Col xs={12} sm={12} md={8} lg={6} xl={4}>
              <Form.Item label="Data de início do pagamento">

                {
                  getFieldDecorator('payment_start', {
                    initialValue: institution.payment_start ? moment(institution.payment_start) : '',
                  })(<DatePicker
                    format="DD/MM/YYYY"
                    style={{ width: '100%' }}
                    showAction={['focus', 'click']}
                    onChange={this.handleChangePaymentStart}
                    mode="date"
                    showToday
                  />)
                }
              </Form.Item>

            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <br />
              <Divider style={{

                background: '#bfbfbf',
              }}
              />
              <br />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <FormItem label="INSTITUTION-KEY">
                {getFieldDecorator('uuid', {
                  initialValue: institution.uuid,
                })(<Input disabled />)}
              </FormItem>
            </Col>

            <Col xs={12} sm={12} md={8} lg={6} xl={4}>
              <Form.Item label="Data de fim do pagamento">
                {
                  getFieldDecorator('payment_end', {
                    initialValue: institution.payment_end ? moment(institution.payment_end) : '',
                  })(<DatePicker
                    format="DD/MM/YYYY"
                    style={{ width: '100%' }}
                    showAction={['focus', 'click']}
                    onChange={this.handleChangePaymentEnd}
                    mode="date"
                    showToday
                  />)
                }
              </Form.Item>

            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item name="logo">
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <FormItem label="Logo">
                    <Upload
                      onRemove={() => this.setState({
                        institution_logo: [],
                      })}
                      beforeUpload={(file) => {
                        this.setState({
                          institution_logo: [file],
                        });
                        this.viewLogo(file);
                        this.checkValidateFormat(file);
                        return false;
                      }}
                      fileList={this.state.institution_logo}
                    >
                      <Button>
                        <Icon type="upload" />
                        {' '}
                        Selecionar arquivo
                      </Button>
                      <div className="ant-upload-list-item-card-actions" />
                    </Upload>

                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Avatar
                    shape="square"
                    alt="logo"
                    src={
                      this.state.logo64
                        ? this.state.logo64
                        : institution.logo_url
                          ? institution.logo_url
                          : null
                    }
                    className="institution-logo-intranet"
                  >
                    Sem logo
                  </Avatar>
                </Col>
              </Form.Item>
            </Col>
          </Row>
          <span style={{ fontSize: '13px', width: '100%' }}>
            O tamanho máximo permitido para o arquivo é de 120KB e
            apenas nos formatos PNG, JPG e JPEG
          </span>
          <Form.Item name="states" style={{ marginTop: '50px' }}>
            <Row gutter={[28, 28]}>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="active"
                  checked={institution.active}
                >
                  Ativo
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="homologated"
                  checked={institution.homologated}
                >
                  Homologado
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="is_clinic"
                  checked={institution.is_clinic}
                >
                  É consultório
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleCheckBoxChange}
                  name="is_group"
                  value="is_group"
                  checked={this.state.is_group}
                  disabled={this.state.is_network}
                >
                  É grupo
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleCheckBoxChange}
                  name="is_network"
                  value="is_network"
                  checked={this.state.is_network}
                  disabled={this.state.is_group}
                >
                  É rede
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="is_hospital_clinic"
                  checked={institution.is_hospital_clinic}
                >
                  É clínica
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="is_healthchess"
                  checked={institution.is_healthchess}
                >
                  É healthchess
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="enable_monitors"
                  checked={institution.enable_monitors}
                >
                  Habilitar monitores
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="enable_signature"
                  checked={institution.enable_signature}
                >
                  Habilitar assinatura digital
                </Checkbox>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={4}>
                <Checkbox
                  onChange={this.handleInputChange}
                  value="is_paying"
                  checked={institution.is_paying}
                >
                  Pagante
                </Checkbox>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Content>
    );
  }
}

function mapStateToProps(state) { return state; }

const connected = withRouter(connect(mapStateToProps)(Form.create()(Institution)));
export default connected;
