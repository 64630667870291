import { surgeonsConstants } from '../constants';
import { surgeonsService } from '../services';

function getSurgeons(filter, cancelToken) {
  function request() {
    return { type: surgeonsConstants.GETSURGEONS_REQUEST };
  }

  function success(data) {
    return { type: surgeonsConstants.GETSURGEONS_SUCCESS, data };
  }

  function failure(error) {
    return { type: surgeonsConstants.GETSURGEONS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());

    return surgeonsService.getSurgeons(filter, cancelToken)
      .then((surgeons) => {
        dispatch(success(surgeons));
        return surgeons;
      }, (error) => dispatch(failure(error)));
  };
}

// eslint-disable-next-line import/prefer-default-export
export const surgeonsActions = {
  getSurgeons,
};
